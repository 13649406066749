import React from "react";
/** Add Route Component */
//Home Section
const Layout1 =  React.lazy(() => import("../pages/Home/Layout1/Layout1"));
// const Layout2 =  React.lazy(() => import("../pages/Home/Layout2/Layout2"));
// const Layout3 =  React.lazy(() => import("../pages/Home/Layout1/Layout1"))

// Social Login
const KakaoCallBack = React.lazy(() => import('../pages/CallBack/Kakao'));
const NaverCallBack = React.lazy(() => import('../pages/CallBack/Naver'));
const GoogleCallBack = React.lazy(() => import('../pages/CallBack/Google'));

// KakaoProfile
const KakaoProfile = React.lazy(() => import('../pages/CallBack/KakaoProfile'));

//Company Section
const AboutUs =  React.lazy(() => import("../pages/Company/AboutUs/AboutUs"));
const Services =  React.lazy(() => import("../pages/Company/Services/Services"));

const Greetings =  React.lazy(() => import("../pages/Company/Greetings/GreetingsPage"));
const Business =  React.lazy(() => import("../pages/Company/Business/BusinessPage"));
const VvipCourse =  React.lazy(() => import("../pages/Company/VvipCourse/VvipCoursePage"));

const GolfClubs =  React.lazy(() => import("../pages/Company/GolfClubs/GolfClubs"));
const GolfClubDetail =  React.lazy(() => import("../pages/Company/GolfClubs/GolfClubDetail"));
const Hotels =  React.lazy(() => import("../pages/Company/Hotels/Hotels"));
const HotelDetail =  React.lazy(() => import("../pages/Company/Hotels/HotelDetail"));

const AiCaddy =  React.lazy(() => import("../pages/Company/AiCaddy/AiCaddy"));

const Team =  React.lazy(() => import("../pages/Company/Team/Team"));
const MembershipCard =  React.lazy(() => import("../pages/Company/MembershipCard/MembershipCard"));

const IndiMember =  React.lazy(() => import("../pages/Company/MembershipCard/IndiMember"));
const CorpMember =  React.lazy(() => import("../pages/Company/MembershipCard/CorpMember"));

const Pricing =  React.lazy(() => import("../pages/Company/Pricing/Pricing"));
const PrivacyAndPolicy =  React.lazy(() => import("../pages/Company/PrivacyAndPolicy/PrivacyAndPolicy"));
const Agreement =  React.lazy(() => import("../pages/Company/Agreement/Agreement"));
const LocationBased =  React.lazy(() => import("../pages/Company/LocationBased/LocationBased"));
const Faqs =  React.lazy(() => import("../pages/Company/Faqs/Faqs"));

//Jobs Section
const JobList2 =  React.lazy(() => import("../pages/Jobs/JobList2/JobList2"));
const JobGrid =  React.lazy(() => import("../pages/Jobs/JobGrid/JobGrid"));
const JobGrid2 =  React.lazy(() => import("../pages/Jobs/JobGrid2/JobGrid2"))

//Candidate and Company Section
const CandidateList =  React.lazy(() => import("../pages/CandidateAndCompany/CandidateList/CandidateList"));
const CandidateGrid =  React.lazy(() => import("../pages/CandidateAndCompany/CandidateGrid/CandidateGrid"));
const CandidateDetails =  React.lazy(() => import("../pages/CandidateAndCompany/CandidateDetails/CandidateDetails"));
const CompanyList =  React.lazy(() => import("../pages/CandidateAndCompany/CompanyList/CompanyList"));
const CompanyDetails =  React.lazy(() => import("../pages/CandidateAndCompany/CompanyDetails/CompanyDetails"));

const SuggestionList =  React.lazy(() => import("../pages/CandidateAndCompany/SuggestionList/SuggestionList"));
const SelfSuggestion =  React.lazy(() => import("../pages/CandidateAndCompany/SelfSuggestion/SelfSuggestion"));

// Call dibs
const CalldibsList =  React.lazy(() => import("../pages/CandidateAndCompany/CalldibsList/CalldibsList"));
const FollowerList =  React.lazy(() => import("../pages/CandidateAndCompany/FollowerList/FollowerList"));

// Employee
const EmployeeList =  React.lazy(() => import("../pages/CandidateAndCompany/EmployeeList/EmployeeList"));

const SchoolList = React.lazy(() => import("../pages/CandidateAndCompany/SchoolList/SchoolList") )


//Bbs Section
const Bbs =  React.lazy(() => import("../pages/Bbs/Bbs"));
const BbsDetails =  React.lazy(() => import("../pages/Bbs/BbsDetails"));

//Notice Section
const Notice =  React.lazy(() => import("../pages/Notice/Notice/Notice"));
const NoticeDetails =  React.lazy(() => import("../pages/Notice/NoticeDetails/NoticeDetails"));
const NoticeGrid =  React.lazy(() => import("../pages/Notice/NoticeGrid/NoticeGrid"));
const NoticeModern =  React.lazy(() => import("../pages/Notice/NoticeModern/NoticeModern"));
const NoticeMasonary =  React.lazy(() => import("../pages/Notice/NoticeMasonary/NoticeMasonary"));
const NoticeAuther =  React.lazy(() => import("../pages/Notice/NoticeAuther/NoticeAuther"));

// Message Section
const Messages =  React.lazy(() => import("../pages/Messages/Messages"));
const MessageDetails =  React.lazy(() => import("../pages/Messages/MessageDetails"));

const FullResumeMessages =  React.lazy(() => import("../pages/Messages/FullResumeMessages"));
const SuggestMessages =  React.lazy(() => import("../pages/Messages/SuggestMessages"));
const ResumeMessages =  React.lazy(() => import("../pages/Messages/ResumeMessages"));
const RecruitMessages =  React.lazy(() => import("../pages/Messages/RecruitMessages"));
const ApplyMessages =  React.lazy(() => import("../pages/Messages/ApplyMessages"));
const MemberMessages =  React.lazy(() => import("../pages/Messages/MemberMessages"));

const Settings =  React.lazy(() => import("../pages/Settings/Settings"));
const SettingDetails =  React.lazy(() => import("../pages/Settings/SettingDetails"));
const SettingCreate =  React.lazy(() => import("../pages/Settings/SettingCreate"));

//QnA Section
const Qna =  React.lazy(() => import("../pages/Qna/Qna/Qna"));
const QnaDetails =  React.lazy(() => import("../pages/Qna/QnaDetails/QnaDetails"));
const QnaPost =  React.lazy(() => import("../pages/Qna/QnaPost/QnaPost"));
const QnaEdit =  React.lazy(() => import("../pages/Qna/QnaEdit/QnaEdit"));
const QnaGrid =  React.lazy(() => import("../pages/Qna/QnaGrid/QnaGrid"));
const QnaModern =  React.lazy(() => import("../pages/Qna/QnaModern/QnaModern"));
const QnaMasonary =  React.lazy(() => import("../pages/Qna/QnaMasonary/QnaMasonary"));
const QnaAuther =  React.lazy(() => import("../pages/Qna/QnaAuther/QnaAuther"));

//Gallery Section
const Gallery =  React.lazy(() => import("../pages/Gallery/Gallery/Gallery"));
const GalleryDetails =  React.lazy(() => import("../pages/Gallery/GalleryDetails/GalleryDetails"));
const GalleryPost =  React.lazy(() => import("../pages/Gallery/GalleryPost/GalleryPost"));
const GalleryEdit =  React.lazy(() => import("../pages/Gallery/GalleryEdit/GalleryEdit"));
const GalleryGrid =  React.lazy(() => import("../pages/Gallery/GalleryGrid/GalleryGrid"));
const GalleryModern =  React.lazy(() => import("../pages/Gallery/GalleryModern/GalleryModern"));
const GalleryMasonary =  React.lazy(() => import("../pages/Gallery/GalleryMasonary/GalleryMasonary"));
const GalleryAuther =  React.lazy(() => import("../pages/Gallery/GalleryAuther/GalleryAuther"));

//Career Section
const Career =  React.lazy(() => import("../pages/Career/Career/Career"));
const CareerDetails =  React.lazy(() => import("../pages/Career/CareerDetails/CareerDetails"));
const CareerPost =  React.lazy(() => import("../pages/Career/CareerPost/CareerPost"));
const CareerEdit =  React.lazy(() => import("../pages/Career/CareerEdit/CareerEdit"));
const CareerGrid =  React.lazy(() => import("../pages/Career/CareerGrid/CareerGrid"));
const CareerModern =  React.lazy(() => import("../pages/Career/CareerModern/CareerModern"));
const CareerMasonary =  React.lazy(() => import("../pages/Career/CareerMasonary/CareerMasonary"));
const CareerAuther =  React.lazy(() => import("../pages/Career/CareerAuther/CareerAuther"));

//Daily Section
const Daily =  React.lazy(() => import("../pages/Daily/Daily/Daily"));
const DailyDetails =  React.lazy(() => import("../pages/Daily/DailyDetails/DailyDetails"));
const DailyPost =  React.lazy(() => import("../pages/Daily/DailyPost/DailyPost"));
const DailyEdit =  React.lazy(() => import("../pages/Daily/DailyEdit/DailyEdit"));
const DailyGrid =  React.lazy(() => import("../pages/Daily/DailyGrid/DailyGrid"));
const DailyModern =  React.lazy(() => import("../pages/Daily/DailyModern/DailyModern"));
const DailyMasonary =  React.lazy(() => import("../pages/Daily/DailyMasonary/DailyMasonary"));
const DailyAuther =  React.lazy(() => import("../pages/Daily/DailyAuther/DailyAuther"));

//Hobby Section
const Hobby =  React.lazy(() => import("../pages/Hobby/Hobby/Hobby"));
const HobbyDetails =  React.lazy(() => import("../pages/Hobby/HobbyDetails/HobbyDetails"));
const HobbyPost =  React.lazy(() => import("../pages/Hobby/HobbyPost/HobbyPost"));
const HobbyEdit =  React.lazy(() => import("../pages/Hobby/HobbyEdit/HobbyEdit"));
const HobbyGrid =  React.lazy(() => import("../pages/Hobby/HobbyGrid/HobbyGrid"));
const HobbyModern =  React.lazy(() => import("../pages/Hobby/HobbyModern/HobbyModern"));
const HobbyMasonary =  React.lazy(() => import("../pages/Hobby/HobbyMasonary/HobbyMasonary"));
const HobbyAuther =  React.lazy(() => import("../pages/Hobby/HobbyAuther/HobbyAuther"));

//Blog Section
// const Blog =  React.lazy(() => import("../pages/Blog/Blog/Blog"));
// const BlogGrid =  React.lazy(() => import("../pages/Blog/BlogGrid/BlogGrid"));
// const BlogModern =  React.lazy(() => import("../pages/Blog/BlogModern/BlogModern"));
// const BlogMasonary =  React.lazy(() => import("../pages/Blog/BlogMasonary/BlogMasonary"));
// const BlogDetails =  React.lazy(() => import("../pages/Blog/BlogDetails/BlogDetails"));
// const BlogAuther =  React.lazy(() => import("../pages/Blog/BlogAuther/BlogAuther"));

//const Contacts 
const Contact =  React.lazy(() => import("../pages/Contact/Contact"));

//const Request MembershipCard 
const Request =  React.lazy(() => import("../pages/Request/Request"));

//const AuthPages
const SignIn =  React.lazy(() => import("../pages/ExtraPages/SignIn"));
const CellPhoneCert =  React.lazy(() => import("../pages/ExtraPages/CellPhoneCert"));
const FindEmail =  React.lazy(() => import("../pages/ExtraPages/FindEmail"));
const Join =  React.lazy(() => import("../pages/ExtraPages/Join"));
const TermsofUse =  React.lazy(() => import("../pages/ExtraPages/TermsofUse"));
const SignUp =  React.lazy(() => import("../pages/ExtraPages/SignUp"));
const SnsSignIn =  React.lazy(() => import("../pages/ExtraPages/SnsSignIn"));
const SnsSignUp =  React.lazy(() => import("../pages/ExtraPages/SnsSignUp"));
const SignOut =  React.lazy(() => import("../pages/ExtraPages/SignOut"));
const ResetPassword =  React.lazy(() => import("../pages/ExtraPages/ResetPassword"));
const RePw =  React.lazy(() => import("../pages/ExtraPages/RePw"));
const ComingSoon =  React.lazy(() => import('../pages/ExtraPages/ComingSoon'));
const Error404 =  React.lazy(() => import("../pages/ExtraPages/Error404"));
const Userguide =  React.lazy(() => import("../pages/ExtraPages/Userguide/Userguide"));
const Components =  React.lazy(() => import("../pages/ExtraPages/Components/Components"));


// SelfSuggestion (For User) - V1.1
// 셀프추천목록
const WishCompanies =  React.lazy(() => import("../pages/SelfSuggestion/WishCompanies/WishCompanies"));
const WishHeadHunters =  React.lazy(() => import("../pages/SelfSuggestion/WishHeadHunters/WishHeadHunters"));
const SelectCompanies =  React.lazy(() => import("../pages/SelfSuggestion/CompanyList/CompanyList"));
const SelectHeadHunters =  React.lazy(() => import("../pages/SelfSuggestion/HeadHunterList/HeadHunterList"));

// 제안관리 - 기업, 헤드헌터로 부터 받은
const SuggestionCompanies =  React.lazy(() => import("../pages/SelfSuggestion/SuggestionCompanies/SuggestionCompanies"));
const SuggestionHeadHunters =  React.lazy(() => import("../pages/SelfSuggestion/SuggestionHeadHunters/SuggestionHeadHunters"));

// 이력서매칭 - 내이력서가 매칭된 기업, 헤드헌터 목록
const MatchCompanies =  React.lazy(() => import("../pages/SelfSuggestion/MatchCompanies/MatchCompanies"));
const MatchHeadHunters =  React.lazy(() => import("../pages/SelfSuggestion/MatchHeadHunters/MatchHeadHunters"));

// 이력서 열람목록
const RetrievedResume =  React.lazy(() => import("../pages/SelfSuggestion/RetrievedResume/RetrievedResume"));
const RetrievedResumeHeadHunter =  React.lazy(() => import("../pages/SelfSuggestion/RetrievedResumeHeadHunter/RetrievedResumeHeadHunter"));

// 기본 이력서 수정
const BasicResume =  React.lazy(() => import("../pages/SelfSuggestion/BasicResume/Resume"));
const BasicResumeExp =  React.lazy(() => import("../pages/SelfSuggestion/BasicResume/ResumeExp"));
const BasicResumeEdu =  React.lazy(() => import("../pages/SelfSuggestion/BasicResume/ResumeEdu"));
const BasicResumeCondition =  React.lazy(() => import("../pages/SelfSuggestion/BasicResume/ResumeCondition"));
const BasicResumeHopeCompany =  React.lazy(() => import("../pages/SelfSuggestion/BasicResume/ResumeHopeCompany"));

// 축약 이력서 수정
const BriefResume =  React.lazy(() => import("../pages/SelfSuggestion/BriefResume/Resume"));

// 영문 이력서 수정
const EnglishResume =  React.lazy(() => import("../pages/SelfSuggestion/EnglishResume/Resume"));
const EnglishResumeExp =  React.lazy(() => import("../pages/SelfSuggestion/EnglishResume/ResumeExp"));
const EnglishResumeEdu =  React.lazy(() => import("../pages/SelfSuggestion/EnglishResume/ResumeEdu"));
const EnglishResumeCondition =  React.lazy(() => import("../pages/SelfSuggestion/EnglishResume/ResumeCondition"));
const EnglishResumeHopeCompany =  React.lazy(() => import("../pages/SelfSuggestion/EnglishResume/ResumeHopeCompany"));


// Package List
const PackageList =  React.lazy(() => import("../pages/Packages/PackageList/PackageList"));
const PackageDetails =  React.lazy(() => import("../pages/Packages/PackageDetails/PackageDetails"));
const PackagesCategories =  React.lazy(() => import("../pages/Packages/PackagesCategories/PackagesCategories"));

// Package : 신청 모집리스트
const ApplyList =  React.lazy(() => import("../pages/Packages/ApplyList/ApplyRecruitList"));


// 채용공고 : 기업채용공고
const JobList =  React.lazy(() => import("../pages/Jobs/JobList/JobList"));
const JobDetails =  React.lazy(() => import("../pages/Jobs/JobDetails/JobDetails"));
const JobsCategories =  React.lazy(() => import("../pages/Jobs/JobsCategories/JobsCategories"));

// 채용공고 : 헤드헌터 추천공고
const SuggestionJobList =  React.lazy(() => import("../pages/Jobs/SuggestionJobList/JobList"));
const SuggestionJobDetails =  React.lazy(() => import("../pages/Jobs/SuggestionJobDetails/JobDetails"));
const SuggestionJobsCategories =  React.lazy(() => import("../pages/Jobs/SuggestionJobsCategories/JobsCategories"));

// 채용공고 : 지원리스트
// csonst ApplyList =  React.lazy(() => import("../pages/Jobs/ApplyList/ApplyRecruitList"));
const ApplyHeadHunterList =  React.lazy(() => import("../pages/Jobs/ApplyHeadHunterList/ApplyRecruitList"));

// 채용공고 : 관심목록
const WatchList =  React.lazy(() => import("../pages/Packages/WatchList/WatchRecruitList"));
const WatchHeadHunterList =  React.lazy(() => import("../pages/Packages/WatchHeadHunterList/WatchHeadHunterRecruitList"));

// 채용공고 : 최근본 공고목록
const RecentlyViewedList =  React.lazy(() => import("../pages/Packages/RecentlyViewedList/RecentlyViewedRecruitList"));
const RecentlyViewedHeadHunterList =  React.lazy(() => import("../pages/Packages/RecentlyViewedHeadHunterList/RecentlyViewedHeadHunterRecruitList"));

// 인재검색
const RecentlyResume =  React.lazy(() => import("../pages/CandidateAndCompany/RecentlyResume/RecentlyResume"));

// 채용공고 : 헤드헌터 채용공고
const HeadHunterJobList =  React.lazy(() => import("../pages/Jobs/HeadHunterJobList/HeadHunterJobList"));

// 채용공고 : 기업 채용공고등록
const JobPost =  React.lazy(() => import("../pages/Jobs/JobPost/JobPost"));
const JobPostExp =  React.lazy(() => import("../pages/Jobs/JobPost/JobPostExp"));
const JobPostType =  React.lazy(() => import("../pages/Jobs/JobPost/JobPostType"));
const JobPostLimit =  React.lazy(() => import("../pages/Jobs/JobPost/JobPostLimit"));
const JobPostCondition =  React.lazy(() => import("../pages/Jobs/JobPost/JobPostCondition"));
const JobPostApply =  React.lazy(() => import("../pages/Jobs/JobPost/JobPostApply"));
const JobPostHr =  React.lazy(() => import("../pages/Jobs/JobPost/JobPostHr"));
const JobPostCompany =  React.lazy(() => import("../pages/Jobs/JobPost/JobPostCompany"));

// 채용공고 : 기업 채용공고수정
const JobModifyList =  React.lazy(() => import("../pages/Jobs/JobModify/JobModifyList"));
const JobModify =  React.lazy(() => import("../pages/Jobs/JobModify/JobModify"));
const JobModifyExp =  React.lazy(() => import("../pages/Jobs/JobModify/JobModifyExp"));
const JobModifyType =  React.lazy(() => import("../pages/Jobs/JobModify/JobModifyType"));
const JobModifyLimit =  React.lazy(() => import("../pages/Jobs/JobModify/JobModifyLimit"));
const JobModifyCondition =  React.lazy(() => import("../pages/Jobs/JobModify/JobModifyCondition"));
const JobModifyApply =  React.lazy(() => import("../pages/Jobs/JobModify/JobModifyApply"));
const JobModifyHr =  React.lazy(() => import("../pages/Jobs/JobModify/JobModifyHr"));
const JobModifyCompany =  React.lazy(() => import("../pages/Jobs/JobModify/JobModifyCompany"));


// 채용공고 : 헤드헌터 채용공고등록
const HeadHunterJobPost =  React.lazy(() => import("../pages/Jobs/HeadHunterJobPost/JobPost"));
const HeadHunterJobPostExp =  React.lazy(() => import("../pages/Jobs/HeadHunterJobPost/JobPostExp"));
const HeadHunterJobPostType =  React.lazy(() => import("../pages/Jobs/HeadHunterJobPost/JobPostType"));
const HeadHunterJobPostLimit =  React.lazy(() => import("../pages/Jobs/HeadHunterJobPost/JobPostLimit"));
const HeadHunterJobPostCondition =  React.lazy(() => import("../pages/Jobs/HeadHunterJobPost/JobPostCondition"));
const HeadHunterJobPostApply =  React.lazy(() => import("../pages/Jobs/HeadHunterJobPost/JobPostApply"));
const HeadHunterJobPostHr =  React.lazy(() => import("../pages/Jobs/HeadHunterJobPost/JobPostHr"));
const HeadHunterJobPostCompany =  React.lazy(() => import("../pages/Jobs/HeadHunterJobPost/JobPostCompany"));


// 채용공고 : 지원자관리
const JobApplicant =  React.lazy(() => import("../pages/Jobs/JobApplicant/JobApplicant"));
const JobApplicantDetails =  React.lazy(() => import("../pages/Jobs/JobApplicant/JobApplicantDetails"));


const JobApplicantResume =  React.lazy(() => import("../pages/Jobs/JobApplicantResume/JobApplicantResume"));

// 채용공고 : 매칭리스트
const MatchResume =  React.lazy(() => import("../pages/Jobs/MatchResume/MatchResume"));

// 채용공고 : 헤드헌터 검색 리스트
const HeadHunterList =  React.lazy(() => import("../pages/Jobs/HeadHunterList/HeadHunterList"));
const HeadHunterDetails =  React.lazy(() => import("../pages/Jobs/HeadHunterDetails/HeadHunterDetails"));









//profile section(User Profile)
const Companies =  React.lazy(() => import("../pages/Profile/Companies/Companies"));
// const WishCompanies =  React.lazy(() => import("../pages/Profile/WishCompanies/WishCompanies"));
const ManageJobPost =  React.lazy(() => import("../pages/Profile/ManageJobPost/ManageJobPost"));
const ManageJobPostExp =  React.lazy(() => import("../pages/Profile/ManageJobPost/ManageJobPostExp"));
const ManageJobPostType =  React.lazy(() => import("../pages/Profile/ManageJobPost/ManageJobPostType"));
const ManageJobPostLimit =  React.lazy(() => import("../pages/Profile/ManageJobPost/ManageJobPostLimit"));
const ManageJobPostCondition =  React.lazy(() => import("../pages/Profile/ManageJobPost/ManageJobPostCondition"));
const ManageJobPostApply =  React.lazy(() => import("../pages/Profile/ManageJobPost/ManageJobPostApply"));
const ManageJobPostHr =  React.lazy(() => import("../pages/Profile/ManageJobPost/ManageJobPostHr"));
const ManageJobPostCompany =  React.lazy(() => import("../pages/Profile/ManageJobPost/ManageJobPostCompany"));

const ManageJobs =  React.lazy(() => import("../pages/Profile/ManageJobs/ManageJobs"));
const BookMarkJobs =  React.lazy(() => import("../pages/Profile/BookMarkJobs/BookMarkJobs"));
const ProposalRecieved =  React.lazy(() => import("../pages/Profile/ProposalRecieved/ProposalRecieved"));
const MyProfile =  React.lazy(() => import("../pages/Profile/MyProfile/MyProfile"));
const MyResume =  React.lazy(() => import("../pages/Profile/MyProfile/MyResume"));
const MyBasicResume =  React.lazy(() => import("../pages/Profile/MyProfile/MyBasicResume"));
const MyResumeeng =  React.lazy(() => import("../pages/Profile/MyProfile/MyResumeeng"));
const MyAccount =  React.lazy(() => import("../pages/Profile/MyProfile/MyAccount"));

const MyCompany =  React.lazy(() => import("../pages/Profile/MyCompany/MyCompany"));
const MyCompanyAccount =  React.lazy(() => import("../pages/Profile/MyCompany/MyCompanyAccount"));
const MyCompanyInfo =  React.lazy(() => import("../pages/Profile/MyCompany/MyCompanyInfo"));

const MySearchFirm =  React.lazy(() => import("../pages/Profile/MySearchFirm/MySearchFirm"));
const MySearchFirmAccount =  React.lazy(() => import("../pages/Profile/MySearchFirm/MySearchFirmAccount"));
const MySearchFirmInfo =  React.lazy(() => import("../pages/Profile/MySearchFirm/MySearchFirmInfo"));


// Manage Resume (User)
const ManageResume =  React.lazy(() => import("../pages/Profile/ManageResume/ManageResume"));
const OpenResume =  React.lazy(() => import("../pages/Profile/ManageResume/OpenResume"));
const HiddenResume =  React.lazy(() => import("../pages/Profile/ManageResume/HiddenResume"));
const WishCompany =  React.lazy(() => import("../pages/Profile/ManageResume/WishCompany"));

// Manage Recruit (User)
const ManageApply =  React.lazy(() => import("../pages/Profile/ManageRecruit/ManageApply"));
const ProposalResume =  React.lazy(() => import("../pages/Profile/ManageRecruit/ProposalResume"));
const ProposalScout =  React.lazy(() => import("../pages/Profile/ManageRecruit/ProposalScout"));
const ProposalInterview =  React.lazy(() => import("../pages/Profile/ManageRecruit/ProposalInterview"));
const ManageScrap =  React.lazy(() => import("../pages/Profile/ManageRecruit/ManageScrap"));
const ManageFollower =  React.lazy(() => import("../pages/Profile/ManageRecruit/ManageFollower"));

// 1:1 (User)
const Mtom =  React.lazy(() => import("../pages/Profile/Mtom/Mtom"));


// Manage Jobs - 채용공고관리 (Company)
const CManageJobs =  React.lazy(() => import("../pages/Company/ManageJobs/ManageJobs"));
const COpenRecruit =  React.lazy(() => import("../pages/Company/ManageJobs/OpenRecruit"));
const CStopRecruit =  React.lazy(() => import("../pages/Company/ManageJobs/StopRecruit"));
const CManageJobPost =  React.lazy(() => import("../pages/Company/ManageJobs/ManageJobPost"));

// Manage Recruit - 인재관리(Company)
const CManageWish =  React.lazy(() => import("../pages/Company/ManageRecruit/ManageWish"));
const CProposalResume =  React.lazy(() => import("../pages/Company/ManageRecruit/ProposalResume"));
const CProposalScout =  React.lazy(() => import("../pages/Company/ManageRecruit/ProposalScout"));
const CProposalInterview =  React.lazy(() => import("../pages/Company/ManageRecruit/ProposalInterview"));
const CManageScrap =  React.lazy(() => import("../pages/Company/ManageRecruit/ManageScrap"));
const CManageFollower =  React.lazy(() => import("../pages/Company/ManageRecruit/ManageFollower"));

// Manage Resume (Company)


//Resume
const Resume =  React.lazy(() => import("../pages/Profile/Resume/Resume"));
const ResumeExp =  React.lazy(() => import("../pages/Profile/Resume/ResumeExp"));
const ResumeEdu =  React.lazy(() => import("../pages/Profile/Resume/ResumeEdu"));
const ResumeCondition =  React.lazy(() => import("../pages/Profile/Resume/ResumeCondition"));
const ResumeHopeCompany =  React.lazy(() => import("../pages/Profile/Resume/ResumeHopeCompany"));


// Routes
const userRoutes = [

  // Social Login
  { path: "/oauth/callback/kakao", component: KakaoCallBack },
  { path: "/oauth/callback/naver", component: NaverCallBack },
  { path: "/oauth/callback/google", component: GoogleCallBack },

  { path: "/kakaoprofile", component: KakaoProfile },

  // 셀프추천 : SelfSuggestion (For user) - V1.1
  { path: "/wishcompanies", component: WishCompanies },
  { path: "/wishheadhunters", component: WishHeadHunters },
  { path: "/selectcompanies", component: SelectCompanies },
  { path: "/selectheadhunters", component: SelectHeadHunters }, // 셀프추천 > 추천관리 > 헤드헌터관리

  { path: "/suggestioncompanies", component: SuggestionCompanies }, //제안관리 - 기업에서 받으넺안
  { path: "/suggestionheadhunters", component: SuggestionHeadHunters }, //제안관리 - 헤드헌터에게 받은 제안

  { path: "/matchcompanies", component: MatchCompanies }, // 이력서매칭 - 기업
  { path: "/matchheadhunters", component: MatchHeadHunters }, // 이력서매칭 - 헤드헌터

  { path: "/retrievedresume", component: RetrievedResume }, // 이력서 열람한 기업
  { path: "/retrievedresumeheadhunter", component: RetrievedResumeHeadHunter }, // 이력서 열람한 헤드헌터

  { path: "/basicresume", component: BasicResume }, // 기본 이력서 수정 Step1
  { path: "/basicresumeexp", component: BasicResumeExp }, // 기본 이력서 수정 Step2
  { path: "/basicresumeedu", component: BasicResumeEdu }, // 기본 이력서 수정 Step3
  { path: "/basicresumecondition", component: BasicResumeCondition }, // 기본 이력서 수정 Step4
  { path: "/basicresumehopecompany", component: BasicResumeHopeCompany }, // 기본 이력서 수정 Step5

  { path: "/briefresume", component: BriefResume }, // 축약 이력서 수정

  { path: "/englishresume", component: EnglishResume }, // 기본 이력서 수정 Step1
  { path: "/englishresumeexp", component: EnglishResumeExp }, // 기본 이력서 수정 Step2
  { path: "/englishresumeedu", component: EnglishResumeEdu }, // 기본 이력서 수정 Step3
  { path: "/englishresumecondition", component: EnglishResumeCondition }, // 기본 이력서 수정 Step4
  { path: "/englishresumehopecompany", component: EnglishResumeHopeCompany }, // 기본 이력서 수정 Step5


  // 1. Package
  { path : "/packagecscategories", component: PackagesCategories },
  { path : "/packagedetails", component: PackageDetails },
  { path : "/packagedetails/:idx", component: PackageDetails },
  { path : "/packagedetails/:item/:idx", component: PackageDetails },
  { path : "/packagelist/:item/:keyword", component: PackageList },
  { path : "/packagelist/:search", component: PackageList },
  { path : "/packagelist", component: PackageList }, // Package

  // 2. Prepaidcard Membership Package
  { path : "/prepaidcategories", component: JobsCategories },
  { path : "/prepaiddetails", component: JobDetails },
  { path : "/prepaiddetails/:idx", component: JobDetails },
  { path : "/prepaid/:item/:keyword", component: JobList },
  { path : "/prepaid/:search", component: JobList },
  { path : "/prepaid", component: JobList }, // Prepaidcard Membership Package

  // 2. Pre Tour Package
  { path : "/pretourcategories", component: SuggestionJobsCategories },
  { path : "/pretourdetails", component: SuggestionJobDetails },
  { path : "/pretourdetails/:idx", component: SuggestionJobDetails },
  { path : "/pretour/:item/:keyword", component: SuggestionJobList },
  { path : "/pretour/:search", component: SuggestionJobList },
  { path : "/pretour", component: SuggestionJobList }, // 헤드헌터 채용공고



  // 채용공고 : JobList (For user) - V1.1
  // 1. 기업채용공고
  { path : "/jobscategories", component: JobsCategories },
  { path : "/jobdetails", component: JobDetails },
  { path : "/jobdetails/:idx", component: JobDetails },
  { path : "/joblist/:item/:keyword", component: JobList },
  { path : "/joblist/:search", component: JobList },
  { path : "/joblist", component: JobList }, // 기업 채용공고

  // 2. 헤드헌터 추천공고
  { path : "/suggestionjobscategories", component: SuggestionJobsCategories },
  { path : "/suggestionjobdetails", component: SuggestionJobDetails },
  { path : "/suggestionjobdetails/:idx", component: SuggestionJobDetails },
  { path : "/suggestionjoblist/:item/:keyword", component: SuggestionJobList },
  { path : "/suggestionjoblist/:search", component: SuggestionJobList },
  { path : "/suggestionjoblist", component: SuggestionJobList }, // 헤드헌터 채용공고

  // 3. 내 지원관리
  { path: "/applylist", component: ApplyList },
  { path: "/applyheadhunterlist", component: ApplyHeadHunterList },

  // 4. 관심목록
  { path: "/watchlist", component: WatchList },
  { path: "/watchheadhunterlist", component: WatchHeadHunterList },

  // 5. 최근본목록
  { path: "/recentlyviewedlist/:item/:keyword", component: RecentlyViewedList },
  { path: "/recentlyviewedlist", component: RecentlyViewedList },
  { path: "/recentlyviewedheadhunterlist/:item/:keyword", component: RecentlyViewedHeadHunterList },
  { path: "/recentlyviewedheadhunterlist", component: RecentlyViewedHeadHunterList },


  // 인재검색 : (For Company)
  // 1.
  // 2.
  // 3.
  // 4.
  // 5. 최근본인재
  { path: "/recentlyresume/:item/:keyword", component: RecentlyResume },
  { path: "/recentlyresume", component: RecentlyResume },

  // 채용공고 : JobList (For Company and Search Firm) - V1.1
  // 1. 헤드헌터 채용공고
  { path : "/headhunterjoblist/:item/:keyword", component: HeadHunterJobList },
  { path : "/headhunterjoblist/:search", component: HeadHunterJobList },
  { path : "/headhunterjoblist", component: HeadHunterJobList }, // 헤드헌터 채용공고

  // 2. 기업 채용공고등록
  { path : "/jobpost/:idx", component: JobPost }, // 기업 채용공고등록
  { path : "/jobpost", component: JobPost }, // 기업 채용공고등록
  { path : "/jobpostexp/:idx", component: JobPostExp },
  { path : "/jobpostexp", component: JobPostExp },
  { path : "/jobposttype/:idx", component: JobPostType },
  { path : "/jobposttype", component: JobPostType },
  { path : "/jobpostlimit", component: JobPostLimit },
  { path : "/jobpostlimit/:idx", component: JobPostLimit },
  { path : "/jobpostcondition/:idx", component: JobPostCondition },
  { path : "/jobpostcondition", component: JobPostCondition },
  { path : "/jobpostapply/:idx", component: JobPostApply },
  { path : "/jobpostapply", component: JobPostApply },
  { path : "/jobposthr/:idx", component: JobPostHr },
  { path : "/jobposthr", component: JobPostHr },
  { path : "/jobpostcompany/:idx", component: JobPostCompany },
  { path : "/jobpostcompany", component: JobPostCompany },

  // 2-1. 기업 채용공고수정
  { path : "/jobmodifylist", component: JobModifyList }, // 기업 채용공고목록
  { path : "/jobmodify/:idx", component: JobModify }, // 기업 채용공고등록
  { path : "/jobmodify", component: JobModify }, // 기업 채용공고등록
  { path : "/jobmodifyexp/:idx", component: JobModifyExp },
  { path : "/jobmodifyexp", component: JobModifyExp },
  { path : "/jobmodifytype/:idx", component: JobModifyType },
  { path : "/jobmodifytype", component: JobModifyType },
  { path : "/jobmodifylimit", component: JobModifyLimit },
  { path : "/jobmodifylimit/:idx", component: JobModifyLimit },
  { path : "/jobmodifycondition/:idx", component: JobModifyCondition },
  { path : "/jobmodifycondition", component: JobModifyCondition },
  { path : "/jobmodifyapply/:idx", component: JobModifyApply },
  { path : "/jobmodifyapply", component: JobModifyApply },
  { path : "/jobmodifyhr/:idx", component: JobModifyHr },
  { path : "/jobmodifyhr", component: JobModifyHr },
  { path : "/jobmodifycompany/:idx", component: JobModifyCompany },
  { path : "/jobmodifycompany", component: JobModifyCompany },

  // 2. 헤드헌터 채용공고등록
  { path : "/headhunterjobpost", component: HeadHunterJobPost }, // 헤드헌터 채용공고등록
  { path : "/headhunterjobpostexp/:idx", component: HeadHunterJobPostExp },
  { path : "/headhunterjobpostexp", component: HeadHunterJobPostExp },
  { path : "/headhunterjobposttype/:idx", component: HeadHunterJobPostType },
  { path : "/headhunterjobposttype", component: HeadHunterJobPostType },
  { path : "/headhunterjobpostlimit/:idx", component: HeadHunterJobPostLimit },
  { path : "/headhunterjobpostlimit", component: HeadHunterJobPostLimit },
  { path : "/headhunterjobpostcondition/:idx", component: HeadHunterJobPostCondition },
  { path : "/headhunterjobpostcondition", component: HeadHunterJobPostCondition },
  { path : "/headhunterjobpostapply/:idx", component: HeadHunterJobPostApply },
  { path : "/headhunterjobpostapply", component: HeadHunterJobPostApply },
  { path : "/headhunterjobposthr/:idx", component: HeadHunterJobPostHr },
  { path : "/headhunterjobposthr", component: HeadHunterJobPostHr },
  { path : "/headhunterjobpostcompany/:idx", component: HeadHunterJobPostCompany },
  { path : "/headhunterjobpostcompany", component: HeadHunterJobPostCompany },

  // 3. 지원자 관리 JobApplicant
  { path : "/jobapplicant", component: JobApplicant }, // 채용공고 지원자 관리
  { path : "/jobapplicant/:idx", component: JobApplicant }, // 해당 채용공고에  지원자 관리
  { path : "/jobapplicantdetail/:idx", component: JobApplicantDetails }, // 채용공고 지원자목록(삭제예정)
  { path : "/jobapplicantdetail", component: JobApplicantDetails }, // 채용공고 지원자목록

  { path : "/jobapplicantresume", component: JobApplicantResume }, // 지원자 이력서

  // 4. 매칭리스트 MatchResume
  { path : "/matchresume", component: MatchResume }, // 매칭리스트

  // 5. 헤드헌터검색 HeadHunterList
  { path : "/headhunterdetails", component: HeadHunterDetails }, // 헤드헌터상세보기
  { path : "/headhunterdetails/:idx", component: HeadHunterDetails }, // 헤드헌터상세보기
  { path : "/headhunterlist", component: HeadHunterList }, // 헤드헌터검색





  //profile Section(User Profile)
  { path: "/companies", component: Companies },
  // { path: "/wishcompanies", component: WishCompanies },
  { path: "/managejobpost", component: ManageJobPost },
  { path: "/managejobpostexp", component: ManageJobPostExp },
  { path: "/managejobposttype", component: ManageJobPostType },
  { path: "/managejobpostlimit", component: ManageJobPostLimit },
  { path: "/managejobpostcondition", component: ManageJobPostCondition },
  { path: "/managejobpostapply", component: ManageJobPostApply },
  { path: "/managejobposthr", component: ManageJobPostHr },
  { path: "/managejobpostcompany", component: ManageJobPostCompany },

  { path: "/myprofile", component: MyProfile   },
  { path: "/myresume", component: MyResume },
  { path: "/mybasicresume", component: MyBasicResume }, // 기본 이력서 수정 Step1
  { path: "/myresumeeng", component: MyResumeeng },

  { path: "/myaccount", component: MyAccount },

  { path: "/mycompany", component: MyCompany   },
  { path: "/mycompanyaccount", component: MyCompanyAccount   },
  { path: "/mycompanyinfo", component: MyCompanyInfo   },

  { path: "/mysearchfirm", component: MySearchFirm   },
  { path: "/mysearchfirmaccount", component: MySearchFirmAccount   },
  { path: "/mysearchfirminfo", component: MySearchFirmInfo   },

  { path: "/bookmarkjobs", component: BookMarkJobs },
  { path: "/proposalrecieved", component: ProposalRecieved },
  { path: "/managejobs", component: ManageJobs },

  // 이력서관리 (User)
  { path: "/manageresume", component: ManageResume },
  { path: "/openresume", component: OpenResume },
  { path: "/hiddenresume", component: HiddenResume },
  { path: "/wishcompany", component: WishCompany },

  // 채용공고지원 관리 (User)
  { path: "/manageapply", component: ManageApply },
  { path: "/proposalresume", component: ProposalResume },
  { path: "/proposalscout", component: ProposalScout },
  { path: "/proposalinterview", component: ProposalInterview },
  { path: "/managescrap", component: ManageScrap },
  { path: "/managefollower", component: ManageFollower },

  // 1:1, 메세지관리
  { path: "/mtom", component: Mtom },


  // 채용공고지원 채용관리 (Company)
  { path: "/cmanagejobs", component: CManageJobs },
  { path: "/copenrecruit", component: COpenRecruit },
  { path: "/cstoprecruit", component: CStopRecruit },
  { path: "/cmanagejobpost", component: CManageJobPost },

  // 채용공고지원 인재관리 (Company)
  { path: "/cmanagewish", component: CManageWish },
  { path: "/cproposalresume", component: CProposalResume },
  { path: "/cproposalscout", component: CProposalScout },
  { path: "/cproposalinterview", component: CProposalInterview },
  { path: "/cmanagescrap", component: CManageScrap },
  { path: "/cmanagefollower", component: CManageFollower },

  //resume
  { path : "/resume", component: Resume },
  { path : "/resumeexp", component: ResumeExp },
  { path : "/resumeedu", component: ResumeEdu },
  { path : "/resumecondition", component: ResumeCondition },
  { path : "/resumehopecompany", component: ResumeHopeCompany },


  //Userguide Section(Extra Pages)
  { path: "/userguide", component: Userguide },

  //Components Section(Extra Pages)
  { path: "/components", component: Components },

  //Contact
  { path : "/contact", component: Contact },

  //Request
  { path : "/request", component: Request },

  // Bbs Section
  { path : "/bbsdetails", component: BbsDetails },
  { path : "/bbsdetails/:idx", component: BbsDetails },
  { path : "/bbs", component: Bbs },

  // Message Section
  { path : "/messagedetails", component: MessageDetails },
  { path : "/messagedetails/:idx", component: MessageDetails },
  { path : "/messages", component: Messages },
  { path : "/messages/:type", component: Messages },

  { path : "/fullresumemessages", component: FullResumeMessages },
  { path : "/suggestmessages", component: SuggestMessages },
  { path : "/resumemessages", component: ResumeMessages },
  { path : "/recruitmessages", component: RecruitMessages },
  { path : "/applymessages", component: ApplyMessages },
  { path : "/membermessages", component: MemberMessages },

  // Setting Section
  { path : "/settingdetails", component: SettingDetails },
  { path : "/settingdetails/:idx", component: SettingDetails },
  { path : "/settings", component: Settings },
  { path : "/settingcreate", component: SettingCreate },

  // Blog Section
  // { path : "/blogauther", component: BlogAuther },
  // { path : "/blogdetails", component: BlogDetails },
  // { path : "/blogmodern", component: BlogModern },
  // { path : "/blogmasonary", component: BlogMasonary },
  // { path : "/bloggrid", component: BlogGrid },
  // { path : "/blog", component: Blog },

  // Notice Section
  { path : "/noticeauther", component: NoticeAuther },
  { path : "/noticeauther/:idx", component: NoticeAuther },
  { path : "/noticedetails", component: NoticeDetails },
  { path : "/noticedetails/:idx", component: NoticeDetails },
  { path : "/noticemodern", component: NoticeModern },
  { path : "/noticemasonary", component: NoticeMasonary },
  { path : "/noticegrid", component: NoticeGrid },
  { path : "/notice", component: Notice },
  { path : "/notice/:page", component: Notice },

  // Qna Section
  { path : "/qnaauther", component: QnaAuther },
  { path : "/qnaauther/:idx", component: QnaAuther },
  { path : "/qnadetails", component: QnaDetails },
  { path : "/qnadetails/:idx", component: QnaDetails },
  { path : "/qnamodern", component: QnaModern },
  { path : "/qnamasonary", component: QnaMasonary },
  { path : "/qnagrid", component: QnaGrid },
  { path : "/qna", component: Qna },
  { path : "/qna/:page", component: Qna },
  { path : "/qnapost", component: QnaPost },
  { path : "/qnapost/:idx", component: QnaPost },
  { path : "/qnaedit", component: QnaEdit },
  { path : "/qnaedit/:idx", component: QnaEdit },

  // Gallery Section
  { path : "/galleryauther", component: GalleryAuther },
  { path : "/galleryauther/:idx", component: GalleryAuther },
  { path : "/gallerydetails", component: GalleryDetails },
  { path : "/gallerydetails/:idx", component: GalleryDetails },
  { path : "/gallerymodern", component: GalleryModern },
  { path : "/gallerymasonary", component: GalleryMasonary },
  { path : "/gallerygrid", component: GalleryGrid },
  { path : "/gallery", component: Gallery },
  { path : "/gallery/:page", component: Gallery },
  { path : "/gallerypost", component: GalleryPost },
  { path : "/gallerypost/:idx", component: GalleryPost },
  { path : "/galleryedit", component: GalleryEdit },
  { path : "/galleryedit/:idx", component: GalleryEdit },

  // Career Section
  { path : "/careerauther", component: CareerAuther },
  { path : "/careerauther/:idx", component: CareerAuther },
  { path : "/careerdetails", component: CareerDetails },
  { path : "/careerdetails/:idx", component: CareerDetails },
  { path : "/careermodern", component: CareerModern },
  { path : "/careermasonary", component: CareerMasonary },
  { path : "/careergrid", component: CareerGrid },
  { path : "/career", component: Career },
  { path : "/career/:page", component: Career },
  { path : "/careerpost", component: CareerPost },
  { path : "/careerpost/:idx", component: CareerPost },
  { path : "/careeredit", component: CareerEdit },  
  { path : "/careeredit/:idx", component: CareerEdit },

  // Daily Section
  { path : "/dailyauther", component: DailyAuther },
  { path : "/dailyauther/:idx", component: DailyAuther },
  { path : "/dailydetails", component: DailyDetails },
  { path : "/dailydetails/:idx", component: DailyDetails },
  { path : "/dailymodern", component: DailyModern },
  { path : "/dailymasonary", component: DailyMasonary },
  { path : "/dailygrid", component: DailyGrid },
  { path : "/daily", component: Daily },
  { path : "/daily/:page", component: Daily },
  { path : "/dailypost", component: DailyPost },
  { path : "/dailypost/:idx", component: DailyPost },
  { path : "/dailyedit", component: DailyEdit },
  { path : "/dailyedit/:idx", component: DailyEdit },

  // Hobby Section
  { path : "/hobbyauther", component: HobbyAuther },
  { path : "/hobbyauther/:idx", component: HobbyAuther },
  { path : "/hobbydetails", component: HobbyDetails },
  { path : "/hobbydetails/:idx", component: HobbyDetails },
  { path : "/hobbymodern", component: HobbyModern },
  { path : "/hobbymasonary", component: HobbyMasonary },
  { path : "/hobbygrid", component: HobbyGrid },
  { path : "/hobby", component: Hobby },
  { path : "/hobby/:page", component: Hobby },
  { path : "/hobbypost", component: HobbyPost },
  { path : "/hobbypost/:idx", component: HobbyPost },
  { path : "/hobbyedit", component: HobbyEdit },
  { path : "/hobbyedit/:idx", component: HobbyEdit },

  //Candidate and Company Section
  { path : "/companydetails", component: CompanyDetails },
  { path : "/companydetails/:idx", component: CompanyDetails },
  { path : "/companylist", component: CompanyList },
  { path : "/candidatedetails", component: CandidateDetails },
  { path : "/candidatedetails/:idx", component: CandidateDetails },
  { path : "/candidategrid", component: CandidateGrid },

  { path : "/candidatelist", component: CandidateList }, // 기업용 인재목록
  { path : "/suggestionlist", component: SuggestionList }, //기업용

  { path : "/selfsuggestion", component: SelfSuggestion }, //기업용 셀프추천인재
  { path : "/selfsuggestion/:idx", component: SelfSuggestion }, //기업용 셀프추천인재
  

  // Call dibs
  { path : "/calldibslist", component: CalldibsList },
  { path : "/followerlist", component: FollowerList },

  // 
  { path : "/employeelist", component: EmployeeList },

  // School List
  { path : "/schoollist", component: SchoolList },

  //Jobs Section
  { path : "/jobgrid2", component: JobGrid2 },
  { path : "/jobgrid", component: JobGrid },
  { path : "/joblist2", component: JobList2 },

  //Company Section  
  { path : "/faqs", component: Faqs },
  { path : "/privacyandpolicy", component: PrivacyAndPolicy },
  { path : "/agreement", component: Agreement },
  { path : "/locationbased", component: LocationBased },
  { path : "/membershipcard", component: MembershipCard },
  { path : "/indimember", component: IndiMember },
  { path : "/indimember/:idx", component: IndiMember },
  { path : "/corpmember", component: CorpMember },
  { path : "/corpmember/:idx", component: CorpMember },

  { path : "/pricing", component: Pricing },
  { path : "/team", component: Team },
  { path : "/services", component: Services },
  { path : "/aboutus", component: AboutUs },

  { path : "/greetings", component: Greetings },
  { path : "/business", component: Business },
  { path : "/vvipcourse", component: VvipCourse },
  
  { path : "/golfclubs", component: GolfClubs },
  { path : "/golfclubs/:idx", component: GolfClubDetail },
  { path : "/hotels", component: Hotels },
  { path : "/hotels/:idx", component: HotelDetail },

  //Home Section
  { path : "/", component: Layout1 },

  // Ai Caddy
  { path : "/aicaddy", component: AiCaddy },

  // Auth
  { path: "/error404", component: Error404 },
  { path: "/comingsoon", component: ComingSoon }, 
  { path: "/resetpassword", component: ResetPassword }, 
  { path: "/signout", component: SignOut }, 
  { path: "/join", component: Join }, 
  { path: "/termsofuse", component: TermsofUse },  
  { path: "/termsofuse/:usertype", component: TermsofUse },   
  { path: "/signup", component: SignUp },  
  { path: "/signup/:usertype", component: SignUp },  
  { path: "/signin", component: SignIn }, 
  { path: "/snssignin", component: SnsSignIn }, 
  { path: "/snssignup", component: SnsSignUp }, 
  { path: "/cellphonecert", component: CellPhoneCert }, 
  { path: "/findemail", component: FindEmail },  
  { path: "/repw", component: RePw },  
  { path: "/repw/:code", component: RePw },  

  
  

  // Not Found
  // { path: "*", component: NotFound },

];

// For SinglePage
const authRoutes = [    
  // { path: "/error404", component: Error404 },
  // { path: "/comingsoon", component: ComingSoon }, 
  // { path: "/resetpassword", component: ResetPassword }, 
  // { path: "/signout", component: SignOut }, 
  // { path: "/signup", component: SignUp },  
];

export { userRoutes, authRoutes };
