import React, { memo }  from 'react';
import { Row,Col,Container} from 'reactstrap';
import { Link } from "react-router-dom";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';


// // import * as common from "../../utils/common";
// import * as API from "../../utils/api";
import { Model } from "../../data/Model";

import 'react-toastify/dist/ReactToastify.css';


import gcLogo from "../../assets/images/logo/gclogo.png";

const Footer = memo(({history}) => {

    // if(!Model.token) {
    //     history.push("/signin");
    // }
    // if(!Model.userid && !Model.userno) {
    //     history.push("/signin");
    // }   

    // const companyservice = () => {
    //     if(Model.usertype === 'c' && Model.userno) {
    //         history.push("/managejobpost");
    //     } else if(Model.usertype === 'u' && Model.userno) { 
    //         toast.warn('기업서비스 메뉴 입니다.', '기업서비스 메뉴');
    //         return false;
    //     } else {
    //         history.push("/signin");
    //     }
    // };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  

    
    return (
        <React.Fragment>
            <section className="bg-footer">
                <Container> 
                    <Row>
                        <Col lg={12}>
                            <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                                <ul className="footer-menu list-inline mb-0" >
                                    <li className="list-inline-item footer-logo"><Link to="/"><img src={gcLogo} height="26" alt="" className="footer-logo-dark" /> GC Golf Club</Link></li>  
                                    <li className="list-inline-item"><Link to="/aboutus">회사소개</Link> <i className="mdi mdi-dots-vertical"></i> </li>  
                                    <li className="list-inline-item">
                                        <Button
                                            id="fade-buton"
                                            aria-controls={open ? 'fade-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                            className="text-white fs-20"
                                        >
                                            이용안내
                                        </Button> 
                                        <i className="mdi mdi-dots-vertical"></i>
                                        <Menu
                                            id="fade-menu"
                                            MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            TransitionComponent={Fade}
                                        >
                                            <MenuItem onClick={handleClose}><Link to="/notice">공지사항</Link></MenuItem>
                                            <MenuItem onClick={handleClose}><Link to="/faqs">FAQ'S</Link></MenuItem>
                                            {/* <MenuItem onClick={handleClose}><Link to="/userguide">이용가이드</Link></MenuItem> */}
                                        </Menu> </li>  
                                    <li className="list-inline-item"><Link to="/agreement">이용약관</Link> <i className="mdi mdi-dots-vertical"></i> </li> 
                                    <li className="list-inline-item"><Link to="/locationbased">위치기반 서비스 이용약관</Link> <i className="mdi mdi-dots-vertical"></i> </li> 
                                    <li className="list-inline-item"><Link to="/privacyandpolicy">개인정보처리방침</Link></li>                            
                                </ul>                           
                            </div>
                        </Col> 
                        {/* 
                        <Col lg={2}>
                            <Link to={Model.usertype === 'c' && Model.userno ? ('/joblist'):('/signin')} ><h4 className="service mb-4">기업서비스</h4></Link>
                        </Col> 
                        */}
                    </Row>
                </Container>
            </section>
            <div className="footer-alt">
                <Container >
                    <Row >
                        <Col lg={12}>
                            <p className="copyright mb-0">GC Global Inc, co. Ltd (대표이사: 심성민) | 서울특별시 강남구 테헤란로33길 11, 9층(JS빌딩)</p>
                            <p className="copyright mb-1">사업자등록번호 : 778-86-03216 | 02-502-1137</p>
                            <p className="copyright mb-0">
                            Copyright &copy; {new Date().getFullYear()} GC Global Inc, co. Ltd All right reserved.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
});

export default Footer;
