class ImageUploader {
  async upload(file) {
    const data = new FormData();
    data.append('file', file);
    data.append('api_key', '238816846811129');
    data.append('upload_preset', 'yybruokr');
    const result = await fetch(
      'https://api.cloudinary.com/v1_1/yybruokr/upload', //CLOUDINARY_URL=cloudinary://238816846811129:9QA0KArL_i0Sg8HI5FGIQv_4Bn0@firstgleam
      {
        method: 'POST',
        body: data,
      }
    );
    return await result.json();
  }
}

export default ImageUploader;
