import axios from 'axios';
import { Model } from "../data/Model";

// const API_URL = "https://api-old.anyapply.com";

let items = [],
    callbacks = [],
    timeoutkeys = [],
    // runmode = window.location.host ? 'web' : process.versions['electron'] ? 'pc' : 'mobile',
    // key_request = '',
    // host = window.location.host ? '//' + (window.location.host.indexOf('localhost')>=0 ? 'api.loc.anyapply.com' : 'api.'+window.location.host) + '/v1.0' : API_URL,
    // host = host.replace(/\/api.(work|shop).anyapply.com\/v1.0/, '/api-old.anyapply.com/v1.0');
    host = 'https://api.gc-vip.com/v1.0';
    // host = '//api.'+window.location.host.replace(/www.|m./gi,'')+'/v1.0';
    // console.log("Host", host);
    Model.API_URL = host;

function request_api() {
    setTimeout(request_api, 300); //key_request = 
    // console.error('items: ', items);
    if (items && items.length > 0) {
        let _items = items, _callbacks = callbacks;
        items = []; callbacks = [];
        let form = new FormData();
        form.append('item', JSON.stringify(_items));
        if(Model.token) {form.append('token', Model.token);}
        axios
        .post(host + '/request/?', form)
        .then(function(r) {
            for (let i in r.data.payload) {
                const tr = r.data.payload[i]||{};
                const response = tr.data && tr.data.indexOf('{')===0 ? JSON.parse(tr.data) : tr.data;
                if (_callbacks[i]) { _callbacks[i](response, i); }
            }
        })
        .catch(function(error) {
            console.error('request_api error: ', error);
        });
    }
};
request_api();

export const add_request_item = (method_name, params, callback, repeat_time) => {
    
    if(Model.token) {params.token = Model.token;}
    if(Model.userno) {params.userno = Model.userno;}
    if(Model.resume_idx) {params.resume_idx = Model.resume_idx;}
    if(Model.company_idx) {params.company_idx = Model.company_idx;}
    if(Model.headhunter_idx) {params.headhunter_idx = Model.headhunter_idx;}

    let item = {"method": method_name,"params": params};
    for ( let i in items) {
      if( JSON.stringify(items[i]) === JSON.stringify(item) ) {
        return ; // 중복시 추가 종료.
      }
    }
    items.push(item);
    let indexno = items.length-1;
    callbacks[indexno] = callback;
    if(repeat_time>0) {
        let newtimeoutkey = setTimeout(() => {
            add_request_item (method_name, params, callback, repeat_time);
        }, repeat_time);
        timeoutkeys[indexno] = newtimeoutkey;
    }
};

export const stop_request = (i) => {
    // console.log('callbacks:', callbacks)
    // console.log('timeoutkeys:', timeoutkeys)
    clearTimeout(timeoutkeys[i]);
}

export const is_login = () => {
    return Model.token ? true : false;
}

// const check_duplicatd_request = (item) => {
//   for ( let i in items) {
//   }
// }