import React from "react";
import Routes from "./Routes/index";

//import Custom Style scss
import "./assets/scss/gcglobal.scss";

function App({ FileInput, authService, cardRepository }) {
  return (
    <React.Fragment>
      <Routes
        FileInput={FileInput}
        authService={authService}
        cardRepository={cardRepository} 
      />
    </React.Fragment>
  );
}

export default App;
