import PropTypes from "prop-types";
import React, { useState, useEffect, memo } from "react";
import {
    Container,
    Collapse,
    NavbarToggler,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";

import { Link, withRouter, useLocation } from "react-router-dom";
import classname from "classnames";

// // import * as common from "../../utils/common";
import * as API from "../../utils/api";
import { Model } from "../../data/Model";

import darkLogo from "../../assets/images/logo-dark.webp";

const NavBar = (props) => {

    const location = useLocation();
    const pathname = location.pathname;

    // console.log(props);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [membership, setMembership] = useState(false);
    const [packagelist, setPackagelist] = useState(false);
    const [cclist, setCclist] = useState(false);
    const [hotellist, setHotellist] = useState(false);
    const [aicaddy, setAicaddy] = useState(false);
    const [community, setCommunity] = useState(false);
    const [business, setBusiness] = useState(false);
    
    //Notification Dropdown
    const [notification, setNotification] = useState(false);
    const dropDownnotification = () => setNotification((prevState) => !prevState);

    //user Profile Dropdown
    const [userProfile, setUserProfile] = useState(false);
    const dropDownuserprofile = () => setUserProfile((prevState) => !prevState);
    

    //scroll navbar
    const [navClass, setnavClass] = useState(false);

    // 화면떨림 제거
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    }, []);

    function scrollNavigation() {
        var scrollup = window.pageYOffset;
        if (scrollup > 0) {
            setnavClass("nav-sticky");
        } else {
            setnavClass("");
        }
    }

    //menu activation
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        var matchingMenuItem = null;
        var ul = document.getElementById("navbarCollapse");
        var items = ul.getElementsByTagName("a");
        removeActivation(items);
        for (var i = 0; i < items.length; ++i) {
            if (props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }

        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [props.location.pathname]);

    const removeActivation = items => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;
            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                if (parent.classList.contains("active")) {
                    parent.classList.remove("active");
                }
            }
        }
    };

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement.parentElement.parentElement;

        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    }

    const logout = () => {
        API.add_request_item('logout', {'token': Model.token}, function(r) {
            if(r && r.success) {
                Model.token = '';
                Model.userid = '';
                Model.userno = '';
                
                if(Model.usertype === 'u') { // User 셀프추천
                    Model.resume_idx = '';
                } else if(Model.usertype === 'c') { // Company
                    Model.company_idx = '';
                } else if(Model.usertype === 's') { // Search Firm
                    Model.headhunter_idx = ''
                } else if(Model.usertype === 'h') { // Headhunter
                    Model.headhunter_idx = ''
                } else {
                    Model.resume_idx = '';
                }

                Model.usertype = '';
                Model.permission = '';
                Model.level_code = '';
                Model.my_info = {};
            }
        });
    };

    return (
        <React.Fragment>
            <nav className={"navbar navbar-expand-lg fixed-top sticky " + navClass} id="navigation">
                <Container fluid className="custom-container">
                    <Link className="navbar-brand text-dark fw-bold me-auto" to="/">
                        <img src={darkLogo} height="30" alt="" className="logo-dark" />
                    </Link>
                    <div>
                        <NavbarToggler className="me-3" type="button" onClick={() => toggle()}>
                            <i className="mdi mdi-menu"></i>
                        </NavbarToggler>
                    </div>
                    <Collapse isOpen={isOpen} className="navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav mx-5 navbar-center">
                            
                            <NavItem className="dropdown dropdown-hover">
                                <NavLink href="/membershipcard" id="joblistdropdown" role="button" data-bs-toggle="dropdown" onClick={() => setMembership(membership)} className={classname("", { show: membership })}>
                                    멤버쉽
                                    <div className="arrow-down"></div>
                                </NavLink>
                                <ul className={classname("dropdown-menu dropdown-menu-center", { show: membership })} aria-labelledby="joblistdropdown">
                                    <li><Link className="dropdown-item" to="/membershipcard">멤버쉽카드</Link></li>
                                </ul>
                            </NavItem>
                            
                            <NavItem className="dropdown dropdown-hover">
                                <NavLink href="/#" id="joblistdropdown" role="button" data-bs-toggle="dropdown" onClick={() => setPackagelist(packagelist)} className={classname("", { show: packagelist })}>
                                    골프 상품 패키지
                                    <div className="arrow-down"></div>
                                </NavLink>
                                <ul className={classname("dropdown-menu dropdown-menu-center", { show: packagelist })} aria-labelledby="joblistdropdown">
                                    { Model.usertype === 'c' ? (
                                        <>
                                            <li><Link className="dropdown-item" to="/packagelist">골프 상품 패키지</Link></li>
                                            <li><Link className="dropdown-item" to="/jobpost">골프 상품 패키지관리</Link></li>
                                            <li><Link className="dropdown-item menu-hide" to="/jobmodifylist">골프 상품 패키지수정</Link></li>
                                            <li><Link className="dropdown-item" to="/jobapplicant">신청관리</Link></li>
                                            <li><Link className="dropdown-item" to="/matchresume">매칭리스트</Link></li>
                                            <li><Link className="dropdown-item" to="/headhunterlist">호텔검색</Link></li>
                                        </>
                                    ) : Model.usertype === 's' || Model.usertype === 'h' ? (
                                        <>
                                            <li><Link className="dropdown-item" to="/headhunterjoblist">호텔룸타입</Link></li>
                                            <li><Link className="dropdown-item" to="/headhunterjobpost">룸관리</Link></li>
                                            <li><Link className="dropdown-item" to="/jobapplicant">예약관리</Link></li>
                                            <li><Link className="dropdown-item" to="/matchresume">매칭리스트</Link></li>
                                        </>
                                    ) : (
                                        <>
                                            <li><Link className="dropdown-item" to="/packagelist/prepaid">선불카드회원 패키지</Link></li>
                                            <li><Link className="dropdown-item" to="/packagelist/pretour">Pre Tour 패키지</Link></li>
                                        </>
                                    )}
                                </ul>
                            </NavItem>
                            
                            <NavItem className="dropdown dropdown-hover">
                                <NavLink href="/golfclubs" id="joblistdropdown" role="button" data-bs-toggle="dropdown" onClick={() => setCclist(cclist)} className={classname("", { show: cclist })}>
                                    골프장
                                    <div className="arrow-down"></div>
                                </NavLink>
                                <ul className={classname("dropdown-menu dropdown-menu-center", { show: cclist })} aria-labelledby="joblistdropdown">
                                    <li><Link className="dropdown-item" to="/golfclubs/1">나리타 노모리</Link></li>
                                    <li><Link className="dropdown-item" to="/golfclubs/2">나리타 히가시</Link></li>
                                    <li><Link className="dropdown-item" to="/golfclubs/3">나리타 쿠오코우</Link></li>
                                </ul>
                            </NavItem>
                            
                            <NavItem className="dropdown dropdown-hover">
                                <NavLink href="/hotels/1" id="joblistdropdown" role="button" data-bs-toggle="dropdown" onClick={() => setHotellist(hotellist)} className={classname("", { show: hotellist })}>
                                    호텔
                                    <div className="arrow-down"></div>
                                </NavLink>
                                <ul className={classname("dropdown-menu dropdown-menu-center", { show: hotellist })} aria-labelledby="joblistdropdown">
                                    <li><Link className="dropdown-item" to="/hotels/1">ANA 크라운 플라자 호텔</Link></li>
                                </ul>
                            </NavItem>
                            
                            <NavItem className="dropdown dropdown-hover">
                                <NavLink href="/aicaddy" id="aicaddydropdown" role="button" data-bs-toggle="dropdown" onClick={() => setAicaddy(aicaddy)} className={classname("", { show: aicaddy })}>
                                    AI 캐디
                                    <div className="arrow-down"></div>
                                </NavLink>
                                <ul className={classname("dropdown-menu dropdown-menu-center", { show: aicaddy })} aria-labelledby="aicaddydropdown">
                                    <li><Link className="dropdown-item" to="/aicaddy">AI 캐디 루시아</Link></li>
                                </ul>
                            </NavItem>

                            <NavItem className="dropdown dropdown-hover">
                                <NavLink href="/notice" id="communitydropdown" role="button" data-bs-toggle="dropdown" onClick={() => setCommunity(community)} className={classname("", { show: community })}>
                                    고객센터
                                    <div className="arrow-down"></div>
                                </NavLink>
                                <ul className={classname("dropdown-menu dropdown-menu-center", { show: community })} aria-labelledby="communitydropdown">
                                    <li><Link className="dropdown-item" to="/notice">공지사항</Link></li>
                                    <li><Link className="dropdown-item" to="/qna">질문과답변</Link></li>
                                    <li><Link className="dropdown-item" to="/daily">리뷰</Link></li>
                                    <li><Link className="dropdown-item" to="/faqs">FAQ's</Link></li>
                                    {/* <li><Link className="dropdown-item" to="/gallery">갤러리</Link></li> */}
                                    {/* <li><Link className="dropdown-item" to="/career">커리어</Link></li> */}
                                </ul>
                            </NavItem>
                            
                            <NavItem className="dropdown dropdown-hover">
                                <NavLink href="/greetings" id="businessdropdown" role="button" data-bs-toggle="dropdown" onClick={() => setBusiness(business)} className={classname("", { show: business })}>
                                    사업소개
                                    <div className="arrow-down"></div>
                                </NavLink>
                                <ul className={classname("dropdown-menu dropdown-menu-center", { show: business })} aria-labelledby="businessdropdown">
                                    <li><Link className="dropdown-item" to="/greetings">대표이사 인사</Link></li>
                                    <li><Link className="dropdown-item" to="/business">사업소개</Link></li>
                                    <li><Link className="dropdown-item" to="/vvipcourse">일본 VVIP 골프최고위 과정</Link></li>
                                </ul>
                            </NavItem>
                        </ul>
                    </Collapse>

                    {/* <ul className="header-menu list-inline d-flex align-items-center mb-0"> */}

                        <Dropdown isOpen={notification}
                            toggle={dropDownnotification} className="list-inline-item  me-4">
                            
                                { Model.token ? (
                                    <DropdownToggle href="/signout" className="header-item noti-icon position-relative fs-18" id="notification" type="button" tag="a" onClick={logout}>로그아웃</DropdownToggle>
                                ) : (
                                    <DropdownToggle href="/signin" className="header-item noti-icon position-relative fs-18" id="notification" type="button" tag="a">로그인</DropdownToggle>
                                )}

                        </Dropdown>

                        <Dropdown
                            onClick={() => setUserProfile(!userProfile)}
                            isOpen={userProfile}
                            toggle={dropDownuserprofile}
                            className="list-inline-item">

                            { Model.token && Model.userno && Model.usertype==='c' ? (
                                <DropdownToggle to="/myprofile" className="header-item fs-18" id="userdropdown" type="button" tag="a" aria-expanded="false">기업서비스</DropdownToggle>
                            ) : Model.token && Model.userno && Model.usertype==='s' ? (
                                <DropdownToggle to="/myprofile" className="header-item fs-18" id="userdropdown" type="button" tag="a" aria-expanded="false">서치펌서비스</DropdownToggle>
                            ) : Model.token && Model.userno && Model.usertype==='h' ? (
                                <DropdownToggle to="/myprofile" className="header-item fs-18" id="userdropdown" type="button" tag="a" aria-expanded="false">헤드헌터서비스</DropdownToggle>
                            ) : Model.token && Model.userno && Model.usertype==='u' ? (
                                <DropdownToggle to="/myaccount" className="header-item fs-18" id="userdropdown" type="button" tag="a" aria-expanded="false">마이페이지</DropdownToggle>
                            ) : Model.token && Model.usertype==='u' ? (
                                <DropdownToggle to="/myaccount" className="header-item fs-18" id="userdropdown" type="button" tag="a" aria-expanded="false">마이페이지</DropdownToggle>
                            ) : (
                                <DropdownToggle href="/membershipcard" className="header-item noti-icon position-relative fs-18" type="button" tag="a">회원가입</DropdownToggle>
                            )}
                            
                            <DropdownMenu className="dropdown-menu-end" aria-labelledby="userdropdown" end>

                            { Model.usertype === 'c' && Model.userno ? (
                                <>
                                    <li><Link className="dropdown-item" to="/mycompany">내정보관리</Link></li>
                                    <li><Link className="dropdown-item" to="/cproposalresume">제안관리</Link>
                                        {/* <Link className="dropdown-item" to="/cproposalresume"><span className="ms-3"> - 보낸제안</span></Link> */}
                                    </li>
                                    {/* <li><Link className="dropdown-item" to="/cselfsuggestion">지원자관리</Link> */}
                                        {/* <Link className="dropdown-item" to="/cselfsuggestion"><span className="ms-3"> - 셀프추천(필터링기능)</span></Link> */}
                                        {/* <Link className="dropdown-item" to="/cjoblist"><span className="ms-3"> - 공고지원</span></Link> */}
                                    {/* </li> */}
                                    <li><Link className="dropdown-item" to="/settings">추천인재검색설정</Link></li>
                                    <li><Link className="dropdown-item" to="/messages">알림메세지</Link></li>
                                    <li><Link className="dropdown-item" to="/signout">로그아웃</Link></li>
                                </>
                            ) : Model.usertype === 's' && Model.userno ? (
                                <>
                                    <li><Link className="dropdown-item" to="/mysearchfirm">내정보관리</Link></li>
                                    <li><Link className="dropdown-item" to="/cproposalresume">제안관리</Link>
                                        {/* <Link className="dropdown-item" to="/cmanagewish"><span className="ms-3"> - 보낸제안</span></Link> */}
                                    </li>
                                    {/* <li><Link className="dropdown-item" to="/cjoblist">인재관리</Link> */}
                                        {/* <Link className="dropdown-item" to="/cjoblist"><span className="ms-3"> - 공고지원</span></Link> */}
                                        {/* <Link className="dropdown-item" to="/suggestresume"><span className="ms-3"> - 추천인재</span></Link> */}
                                        {/* <Link className="dropdown-item" to="/recentlyresume"><span className="ms-3"> - 최근본인재</span></Link> */}
                                    {/* </li> */}
                                    <li><Link className="dropdown-item" to="/settings">추천인재검색설정</Link></li>
                                    <li><Link className="dropdown-item" to="/messages">알림메세지</Link></li>
                                    <li><Link className="dropdown-item" to="/signout">로그아웃</Link></li>
                                </>
                            ) : Model.usertype === 'h' && Model.userno ? (
                                <>
                                    <li><Link className="dropdown-item" to="/mysearchfirm">내정보관리</Link></li>
                                    <li><Link className="dropdown-item" to="/cproposalresume">제안관리</Link>
                                        {/* <Link className="dropdown-item" to="/cmanagewish"><span className="ms-3"> - 보낸제안</span></Link> */}
                                    </li>
                                    {/* <li><Link className="dropdown-item" to="/cjoblist">인재관리</Link> */}
                                        {/* <Link className="dropdown-item" to="/cjoblist"><span className="ms-3"> - 공고지원</span></Link> */}
                                        {/* <Link className="dropdown-item" to="/suggestresume"><span className="ms-3"> - 추천인재</span></Link> */}
                                        {/* <Link className="dropdown-item" to="/recentlyresume"><span className="ms-3"> - 최근본인재</span></Link> */}
                                    {/* </li> */}
                                    <li><Link className="dropdown-item" to="/settings">추천인재검색설정</Link></li>
                                    <li><Link className="dropdown-item" to="/messages">알림메세지</Link></li>
                                    <li><Link className="dropdown-item" to="/signout">로그아웃</Link></li>
                                </>
                            ) : Model.usertype === 'u' && Model.userno ? (
                                <>
                                    <li><Link className="dropdown-item" to="/myaccount">계정설정</Link></li>
                                    {/* <li><Link className="dropdown-item" to="/suggestioncompanies">제안관리</Link> */}
                                        {/* <Link className="dropdown-item" to="/proposalcompanies"><span className="ms-3"> - 기업제안</span></Link> */}
                                        {/* <Link className="dropdown-item" to="/proposalheadhunters"><span className="ms-3"> - 헤드헌터제안</span></Link> */}
                                    {/* </li> */}
                                    <li><Link className="dropdown-item" to="/applylist">예약목록</Link>
                                        {/* <Link className="dropdown-item" to="/wishcompany"><span className="ms-3"> - 셀프추천</span></Link> */}
                                        {/* <Link className="dropdown-item" to="/manageapply"><span className="ms-3"> - 채용관리</span></Link> */}
                                    </li>
                                    <li><Link className="dropdown-item" to="/watchlist">관심 골프 상품 패키지</Link></li>
                                    <li><Link className="dropdown-item" to="/recentlyviewedlist">최근본 골프 상품 패키지</Link></li>

                                    {/* <li><Link className="dropdown-item" to="/retrievedresume">이력서관리</Link> */}
                                        {/* <Link className="dropdown-item" to="/manageresume"><span className="ms-3"> - 이력서 열람리스트</span></Link> */}
                                        {/* <Link className="dropdown-item" to="/manageresume"><span className="ms-3"> - 기본이력서</span></Link> */}
                                        {/* <Link className="dropdown-item" to="/manageresume"><span className="ms-3"> - 축약이력서</span></Link> */}
                                        {/* <Link className="dropdown-item" to="/manageresume"><span className="ms-3"> - 영문이력서</span></Link> */}
                                    {/* </li> */}
                                    {/* <li><Link className="dropdown-item" to="/messages">알림메세지</Link></li> */}
                                    <li><Link className="dropdown-item" to="/signout">로그아웃</Link></li>
                                </>
                            ) : (
                                <></>
                            ) }  
                            
                            </DropdownMenu>

                        </Dropdown>
                    {/* </ul> */}
                    
                </Container>
            </nav>
        </React.Fragment>
    );
};


NavBar.propTypes = {
    history: PropTypes.object
  };

export default withRouter(memo(NavBar));
