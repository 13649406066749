// proxy 방식
// https://meetup.toast.com/posts/158
import moment from "moment";

const sha1 = require('sha1');

let __listener__ = {},
    __data__ = {}, // in memory storage (delete on reload, default)
    session_item = ['faq_data','faqcategory_data','message_data','company_data','headhunter_data','wishcompany_data','wishheadhunter_data','recruit_data','resume_data','recently_data','suggest_data','resume_brief','resume_full','apply_data','review_data','myprofile_data','career_data','banner_data','setting_data','setting_info','school_data','my_info','token','userid','userno','usertype','company_idx','headhunter_idx','resume_idx','selected_top_menu','signup','level_code','permission'], // item name using session storage ( delete on close )
    local_item = ['fcm_token']; // item name using local storage ( delete user action or cleaner programes )
    
const handler = {
    get(target, property) {
        let r = target[property];
        if(session_item.indexOf(property)>-1) {
            r = JSON.parse(sessionStorage.getItem(property));
        }
        if(local_item.indexOf(property)>-1) {
            r = JSON.parse(localStorage.getItem(property));
        }
        return r;
    },
    set(target, property, value) {
        const oldValue = target[property];
        try {
            if(session_item.indexOf(property)>-1) {
                sessionStorage.setItem(property, JSON.stringify(value));
            } else if(local_item.indexOf(property)>-1) {
                localStorage.setItem(property, JSON.stringify(value));
            } else {
                target[property] = value;
            }
            // Notify model changes
            // console.log('same?', JSON.stringify(oldValue) == JSON.stringify(value));
            if (__listener__[property] && JSON.stringify(oldValue) !== JSON.stringify(value)) {
                const cb = __listener__[property]
                for (var n in cb) {
                //   console.log('call callback function. property :', property, ', fn:' ,cb[n]);
                    cb[n](property, oldValue, value);
                }
            }
        } catch (e) {}
        // Return true if successful. In strict mode, returning false will throw a TypeError exception.
        return true;
    }
};

export const Model = new Proxy(__data__, handler);
window.Model = Model;

/**
 * @param String property 감지할 데이터이름 
 * @param String name 콜백이름(중복방지용)
 * @param Function listener 콜백함수
 */
export const addChangeListener = (property, name, listener) => {
    const key = sha1(name);
    // 단일 콜백.
    // __listener__[property] = listener; 
    // 다중 콜백.
    if (__listener__[property]) {
        __listener__[property][key] = listener;
    } else {
        __listener__[property] = {};
        __listener__[property][key] = listener;
    }
    // console.log('__listener__:', __listener__);
    // console.log('property:', property);
}

// FaqCategoryData
export const convertFaqCategoryData = (faqcategory_data) => {
  if(!faqcategory_data) { return []; }
  let r = [];
  for (let i = 0; i < faqcategory_data.length; i++) {
    let t = {},
    faqs = faqcategory_data[i];
    t.no = faqcategory_data.length - i; // 매일초기화할수있음.

    t.faqcode = faqs.faqcode;
    t.title = faqs.title;
    t.ranking = faqs.ranking;

    r.push(t);
  }
  return r;
}

// FaqData
export const convertFaqData = (faq_data) => {
  if(!faq_data) { return []; }
  let r = [];
  for (let i = 0; i < faq_data.length; i++) {
    let t = {},
    faqs = faq_data[i];
    t.no = faq_data.length - i; // 매일초기화할수있음.

    t.idx = faqs.idx;
    t.faqcode = faqs.faqcode;
    t.subject = faqs.subject;
    t.contents = faqs.contents;

    r.push(t);
  }
  return r;
}

// MessageData
export const convertMessageData = (message_data) => {

  if(!message_data) { return []; }
  let r = [];
  for (let i = 0; i < message_data.length; i++) {
    let t = {},
    message = message_data[i];

    t.idx = message.idx; //메세지 idx
    t.message_idx = message.message_idx; //메세지 아이디
    t.message_userno = message.message_userno; //회원번호
    t.target_userno = message.target_userno; //상대번호

    t.company_name = message.company_name;
    t.user_name = message.user_name;

    t.target_type = message.target_type; //대상 :  aap: anyapply, x: 이력서, recruit: 채용공고, company: 회사, member: 회원, bbs: 게시판, ...
    //aap: anyapply, resume: 이력서, self: 셀프추천, recruit: 채용공고, company: 회사, member: 회원, bbs: 게시판

    switch (t.target_type) {
      case 'aap':
      t.target_type_str = 'AnyApply';
      t.target_title = message.aap_title;
      break;
      case 'resume':
        t.target_type_str = '인재관리';
        t.target_title = message.user_name;
      break; 
      case 'self':
        t.target_type_str = '풀이력서요청';
        t.target_title = message.company_name;
      break; 
      case 'recruit':
        t.target_type_str = '채용공고';
        t.target_title = message.recruit_title;
        break; 
      case 'apply':
        t.target_type_str = '채용공고신청';
        t.target_title = message.recruit_title;
      break; 
      case 'suggest':
        t.target_type_str = '제안신청';
        t.target_title = message.company_name;
      break; 
      case 'company':
        t.target_type_str = '회사';
        t.target_title = message.company_name;
      break; 
      case 'member':
        t.target_type_str = '회원';
        t.target_title = message.member_msg;
      break; 
      case 'bbs':
        t.target_type_str = '게시판';
        t.target_title = message.subject;
      break; 
      default:
        t.target_type_str = '기타';
        t.target_title = '-';
    }

    t.target_idx = message.target_idx; //target_idx : resume_idx. recruit_idx, company_idx, userno, bbs_idx
    t.msg = message.msg; //메세지
    t.status = message.status; //상태
    t.result_msg = message.result_msg; //확인메세지
    t.read_date = message.read_date; //읽은날짜
    t.reg_date = message.reg_date; //등록일
    t.moddate = message.moddate; //등록일
    t.date_refuse = message.date_refuse; // 거절날짜

    r.push(t);  
  }
  return r;
}

// BbsData
export const convertBbsData = (bbs_data) => {
  if(!bbs_data) { return []; }
  let r = [];
  for (let i = 0; i < bbs_data.length; i++) {
    let t = {},
    bbs = bbs_data[i];
    t.bbs_idx = bbs.idx;
    t.idx = bbs.idx;
    t.no = bbs_data.length - i; // 매일초기화할수있음.

    t.category = bbs.category;
    t.bbscode = bbs.bbscode;
    t.userid = bbs.userid;
    t.division = bbs.division;
    t.author = bbs.author;

    if(bbs.subject.length > 52) {
      t.subject = bbs.subject.substring(0,52)+'...';
    } else {
      t.subject = bbs.subject;
    }

    t.email = bbs.email;
    t.contents = bbs.contents;
    t.thread = bbs.thread;
    t.pos = bbs.pos;
    t.depth = bbs.depth;
    t.bool_secret = bbs.bool_secret;
    t.website = bbs.website;
    t.file = bbs.file;
    t.file_src = bbs.file_src;
    t.passwd = bbs.passwd;
    t.ipaddr = bbs.ipaddr;
    t.hit = bbs.hit;
    t.recomment = bbs.recomment;
    t.cnt_comment = bbs.cnt_comment;
    t.regdate = bbs.regdate;

    t.date = bbs.regtime;
    t.reg_date = moment(t.date).format("YYYY-MM-DD HH:mm"); // "13:16(19-11-07)";

    t.golink = bbs.golink;
    t.occupation = bbs.occupation;

    r.push(t);
  }
  return r;
}

// BBS Detail
export const convertBbsDetailData = (bbs_data) => {
  if(!bbs_data) { return []; }
  let r = [];
  for (let i = 0; i < bbs_data.length; i++) {
    let t = {},
    bbs = bbs_data[i];
    t.bbs_idx = bbs.idx;
    t.idx = bbs.idx;
    t.no = bbs_data.length - i; // 매일초기화할수있음.

    t.category = bbs.category;
    t.bbscode = bbs.bbscode;
    t.userid = bbs.userid;
    t.division = bbs.division;
    t.author = bbs.author;

    if(bbs.subject.length > 60) {
      t.subject = bbs.subject.substring(0,60)+'...';
    } else {
      t.subject = bbs.subject;
    }

    t.email = bbs.email;
    t.contents = bbs.contents;
    t.thread = bbs.thread;
    t.pos = bbs.pos;
    t.depth = bbs.depth;
    t.bool_secret = bbs.bool_secret;
    t.website = bbs.website;
    t.file = bbs.file;
    t.file_src = bbs.file_src;
    t.passwd = bbs.passwd;
    t.ipaddr = bbs.ipaddr;
    t.hit = bbs.hit;
    t.recomment = bbs.recomment;
    t.cnt_comment = bbs.cnt_comment;
    t.regdate = bbs.regdate;

    t.date = bbs.regtime;
    t.reg_date = moment(t.date).format("YYYY-MM-DD HH:mm"); // "13:16(19-11-07)";

    t.golink = bbs.golink;
    t.occupation = bbs.occupation;

    t.comments = [];
    let comments = bbs.comments;
    if(comments) {
        for (let g = 0; g < comments.length; g++) {
            let bbs_comment = comments[g];
          t.comments.push({
            'idx':bbs_comment.idx, 
            'link_idx':bbs_comment.link_idx,
            'userno':bbs_comment.userno,
            'userid':bbs_comment.userid,
            'bbscode':bbs_comment.bbscode,
            'author':bbs_comment.author,
            'contents':bbs_comment.contents,
            'passwd':bbs_comment.passwd,
            'ipaddr':bbs_comment.ipaddr,
            'thread':bbs_comment.thread,
            'pos':bbs_comment.pos,
            'depth':bbs_comment.depth,
            'regdate':moment(bbs_comment.regdate).format("YYYY-MM-DD HH:mm:ss"), // "13:16(19-11-07)";
            'like_cnt':bbs_comment.like_cnt,
            'warning_date':bbs_comment.warning_date,
            'user_photo':bbs.comment.user_photo,
          });
        }
    }
    t.comment_list = t.comments;

    t.prev_idx = bbs.prev_idx;
    t.next_idx = bbs.next_idx;
    
    t.popular = [];
    let popular = bbs.popular;
    if(popular) {
        for (let j = 0; j < popular.length; j++) {
            let bbs_popular = popular[j];
          t.popular.push({
            'idx':bbs_popular.idx, 
            'bbscode':bbs_popular.bbscode,
            'userid':bbs_popular.userid,
            'author':bbs_popular.author,
            'subject':bbs_popular.subject,
            'file':bbs_popular.file,
            'hit':bbs_popular.hit,
            'regdate':bbs_popular.regdate,
          });
        }
    }
    t.popular_list = t.popular;

    
    r.push(t);
  }
  return r;
}

// MyProfile
export const convertMyProfileData = (myprofile_data) => {
  if(!myprofile_data) { return []; }
  let r = [];
  // console.log('myprofile_data.length', myprofile_data.length);
  for (let i = 0; i < myprofile_data.length; i++) {
    let t = {},
    myinfo = myprofile_data[i];
    t.regdate = myinfo.regdate;
    t.id = myinfo.regdate;
    t.no = myinfo.regdate; // 매일초기화할수있음.
    t.date = myinfo.regdate;
    t.reg_date = moment(t.date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";

    // membner info
    t.userno = myinfo.userno;
    t.userid = myinfo.userid;
    t.usertype = myinfo.usertype;
    t.join_method = myinfo.join_method;
    t.name = myinfo.name;
    t.nickname = myinfo.nickname;
    
    t.sid = myinfo.sid;
    t.passportno = myinfo.passportno;
    t.phone = myinfo.phone;
    t.mobile = myinfo.mobile;
    t.email = myinfo.email;
    t.company_sales = myinfo.company_sales;
    t.zipcode = myinfo.zipcode;
    t.address_a = myinfo.address_a;
    t.address_b = myinfo.address_b;
    t.birthday = myinfo.birthday;
    t.birthyear = myinfo.birthyear;

    t.level_code = myinfo.level_code;
    t.recomid = myinfo.recomid;
    t.gender = myinfo.gender;
    t.image_identify_url = myinfo.image_identify_url;
    t.modify_date = moment(myinfo.modify_date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";

    // resume
    t.resume_idx = myinfo.resume_idx; // resume

    t.show_status = myinfo.show_status; // 이력서 상태 0:비공개(비활성), 1:공개
    t.resume_status = myinfo.resume_status; //이력서 상태 0:구직의사 없음.(비활성), 1: 즉시입사가능, 2: 이직희망고려중, 3. 제안검토가능
    
    if(t.show_status === '1') {
      if (t.resume_status === '0') {
        t.resume_status_str = '구직의사 없음';
      } else if (t.resume_status === '1') {
        t.resume_status_str = '즉시입사가능';
      } else if (t.resume_status === '2') {
        t.resume_status_str = '이직고려중';
      } else if (t.resume_status === '3') {
        t.resume_status_str = '제안검토가능';
      }
    } else {
      t.resume_status_str = '구직의사 없음';
    }

    t.title = myinfo.title;
    t.photo_url = myinfo.photo_url;
    t.user_name = myinfo.user_name;
    t.user_birth = myinfo.user_birth;
    t.user_birth_year = myinfo.user_birth_year;
    t.user_gender = myinfo.user_gender;
    t.user_email = myinfo.user_email;
    t.user_phone = myinfo.user_phone;
    t.user_mobile = myinfo.user_mobile;
    t.user_address = myinfo.user_address;

    t.user_address_array = t.user_address.split(' ');
    t.user_address_s = t.user_address_array[0]+" "+t.user_address_array[1];

    t.user_last_school = myinfo.user_last_school;
    t.user_final_education = myinfo.user_final_education;


    switch (t.user_final_education) {
      case '1':
      t.final_education_str = '고등학교졸업';
      break;
      case '2':
      t.final_education_str = '대햑교(2,3)졸업';
      break; 
      case '3':
      t.final_education_str = '대학교(4)졸업';
      break; 
      case '4':
      t.final_education_str = '석사학위';
      break; 
      case '5':
      t.final_education_str = '박사학위';
      break; 
      default:
      t.final_education_str = '기타';
    }
    
    t.user_total_exp = myinfo.user_total_exp;
    switch (t.user_total_exp) {
      case '1':
      t.user_total_exp_str = '신입';
      break;
      case '2':
        t.user_total_exp_str = '1년 ~ 3년 이하';
      break; 
      case '3':
        t.user_total_exp_str = '3년 ~ 6년 이하';
      break; 
      case '4':
        t.user_total_exp_str = '6년 ~ 10년 이하';
      break; 
      case '5':
        t.user_total_exp_str = '10년 ~ 15년 이하';
      break; 
      case '6':
        t.user_total_exp_str = '15년 ~ 20년 이하';
      break; 
      case '7':
        t.user_total_exp_str = '20년 ~ 25년 이하';
      break; 
      case '8':
        t.user_total_exp_str = '25년 ~ 30년 이하';
      break; 
      case '9':
        t.user_total_exp_str = '30년 이상';
      break; 
      default:
        t.user_total_exp_str = '신입';
    }

    t.tot_exp_month = myinfo.tot_exp_month; // 실제 이력서상 경력
    t.real_exp_year = parseInt(t.tot_exp_month / 12);
    t.real_exp_month =  t.tot_exp_month % 12;
    t.real_exp_tot = (t.real_exp_year>0?t.real_exp_year+'년':'...')+(t.real_exp_month>0?' '+t.real_exp_month+'개월':'');

    t.user_job_roll = myinfo.user_job_roll;
    switch (t.user_job_roll) {
      case '1':
      t.user_job_roll_str = '생산 (Production)';
      break;
      case '2':
        t.user_job_roll_str = '재무 (Financial)';
      break; 
      case '3':
        t.user_job_roll_str = '총무 (Administrator)';
      break; 
      case '4':
        t.user_job_roll_str = '품질관리 (Quality Control)';
      break; 
      case '5':
        t.user_job_roll_str = '사무 (Clerical work)';
      break; 
      case '6':
        t.user_job_roll_str = '정보보호 (Information Security)';
      break; 
      case '7':
        t.user_job_roll_str = 'IT·인터넷 (Information Technology)';
      break; 
      case '8':
        t.user_job_roll_str = '홍보 (Public Relation)';
      break; 
      case '9':
        t.user_job_roll_str = '마케팅 (Marketing)';
      break; 
      case '10':
        t.user_job_roll_str = '법무 (Judicial affairs)';
      break; 
      case '11':
        t.user_job_roll_str = '생산관리 (Production management)';
      break; 
      case '12':
        t.user_job_roll_str = '고객서비스 (Customer service)';
      break; 
      case '13':
        t.user_job_roll_str = '보안 (Security)';
      break; 
      case '14':
        t.user_job_roll_str = '비서 (Secretaray)';
      break; 
      case '15':
        t.user_job_roll_str = '영업 (Sales)';
      break; 
      case '16':
        t.user_job_roll_str = '노무 (Labor)';
      break; 
      case '17':
        t.user_job_roll_str = '시설관리 (Facility management)';
      break; 
      case '18':
        t.user_job_roll_str = '공정설계 (Process engineering)';
      break; 
      case '19':
        t.user_job_roll_str = '연구개발 (Research and Development)';
      break; 
      case '20':
        t.user_job_roll_str = '리스크 (Risk)';
      break; 
      case '21':
        t.user_job_roll_str = '구매 (Sourcing)';
      break; 
      case '22':
        t.user_job_roll_str = '인사 (Human resources)';
      break; 
      case '23':
        t.user_job_roll_str = '전략기획 (Strategy)';
      break; 
      case '24':
        t.user_job_roll_str = '환경안전 (Environmental Safety)';
      break; 
      case '25':
        t.user_job_roll_str = '물류 (Logistics)';
      break; 
      case '26':
        t.user_job_roll_str = '기타';
      break; 
      default:
        t.user_job_roll_str = '신기타입';
    }

    t.wish_employment_type = myinfo.wish_employment_type;
    t.now_pay_amount = myinfo.now_pay_amount;
    t.merit_pay_amount = myinfo.merit_pay_amount;
    t.wish_pay_amount = myinfo.wish_pay_amount;
    t.wish_company_location = myinfo.wish_company_location;
    t.wish_job = myinfo.wish_job;
    t.regdate = myinfo.regdate;
    // t.moddate = myinfo.moddate;
    t.match_date = myinfo.match_date;
    
    t.profile_title = myinfo.profile_title;
    t.profile_contents = myinfo.profile_contents;

    // cnt

// (select cnt_wish_com + cnt_wish_headhunter + cnt_apply_com + cnt_apply_searchfirm+ cnt_apply_headhunter) AS cnt_status,
// (select cnt_suggest_c_resume + cnt_suggest_s_resume + cnt_suggest_h_resume) AS cnt_get_suggest

    // 추천
    t.cnt_wish_com = myinfo.cnt_wish_com;
    t.cnt_wish_headhunter = myinfo.cnt_wish_headhunter;

    // 지원
    t.cnt_apply_com = myinfo.cnt_apply_com;
    t.cnt_apply_searchfirm = myinfo.cnt_apply_searchfirm;
    t.cnt_apply_headhunter = myinfo.cnt_apply_headhunter;

    // 열람
    t.cnt_view_c_resume = myinfo.cnt_view_c_resume;
    t.cnt_view_s_resume = myinfo.cnt_view_s_resume;
    t.cnt_view_h_resume = myinfo.cnt_view_h_resume;

    // 받은제안
    t.cnt_suggest_c_resume = myinfo.cnt_suggest_c_resume;
    t.cnt_suggest_s_resume = myinfo.cnt_suggest_s_resume;
    t.cnt_suggest_h_resume = myinfo.cnt_suggest_h_resume;

    t.cnt_status = myinfo.cnt_status; // 추천 + 지원
    t.cnt_get_suggest = myinfo.cnt_get_suggest; // 받은제안
    t.cnt_view_resume = myinfo.cnt_view_resume; // 열람

    // \"career\":[{\"resume_idx\":\"GDVBG80790\",\"sort_no\":\"1\",\"status\":\"2\",\"company_name\":\"\\ubc95\\ubb34\\ubc95\\uc778OO\",
    // \"department_name\":\"\\uc778\\uc0ac\\ucd1d\\ubb34\\ud300\",\"date_join\":\"2000.01\",\"date_retire\":\"2022.08\",
    // \"job_title\":\"\\ucc28\\uc7a5\",\"pay_amount\":\"9000\",\"job_in_charge\":\"\"}],

    t.career = [];
    let career = myinfo.career;
    if(career) {
        for (let g = 0; g < career.length; g++) {
            let my_career = career[g];

            my_career.exp_year = parseInt(my_career.exp_mon / 12);
            my_career.exp_month = my_career.exp_mon % 12;
            my_career.exp_mon = (my_career.exp_year>0?my_career.exp_year+'년':'...')+(my_career.exp_month>0?' '+my_career.exp_month+'개월':'');

          t.career.push({
            'idx':my_career.idx,
            'resume_idx':my_career.resume_idx, 
            'status':my_career.status, 
            'company_name':my_career.company_name, 
            // 'company_name_logo':my_career.company_name.substring(0,2),
            'department_name':my_career.department_name, 
            'date_join':my_career.date_join, 
            'date_retire':my_career.date_retire, 
            'job_title':my_career.job_title, 
            'pay_amount':my_career.pay_amount, 
            'job_in_charge':my_career.job_in_charge, 
            'exp_mon':my_career.exp_mon, 
          });
        }
    }
    t.career_list = t.career;

    t.certificate = [];
    let certificate = myinfo.certificate;
    if(certificate) {
      for(let j=0; j<certificate.length; j++) {
        let my_cert = certificate[j];
        t.certificate.push({
          'idx':my_cert.idx,
          'sort_no':my_cert.sort_no,
          'certificate_name':my_cert.certificate_name, // 자격증명
          'certificate_issuer':my_cert.certificate_issuer, // 벌행처
          'date_receive':my_cert.date_receive, // 최득일
        });
      }
    }
    t.certificate_list = t.certificate;

    t.coreability = [];
    let coreability = myinfo.coreability;
    if(coreability) {
      for(let m=0; m<coreability.length; m++) {
        let my_ability = coreability[m];
        t.coreability.push({
          'idx':my_ability.idx,
          'sort_no':my_ability.sort_no,
          'ability_title':my_ability.ability_title, // 핵심역량 제목
          'ability_contents':my_ability.ability_contents, // 핵심역량 내용
        });
      }
    }
    t.coreability_list = t.coreability;

    t.education = [];
    let education = myinfo.education;
    if(education) {
      for(let l=0; l<education.length; l++) {
        let my_edu = education[l];
        t.education.push({
          'idx':my_edu.idx,
          'sort_no':my_edu.sort_no,
          'school_location':my_edu.school_location, // 1:국내, 2:해외
          'school_type':my_edu.school_type, // 학교구분(1:고등학교, 2:대학(2,3년), 3:대학교(4년), 4:대학원석사, 5:대학원박사)
          'school_name':my_edu.school_name, // 학교이름
          'major':my_edu.major, //학과(전공)
          'date_admission':my_edu.date_admission, //입학날짜
          'date_graduation':my_edu.date_graduation, // 졸업날짜
          'status':my_edu.status, //상태(1:졸업, 2:입학, 3:재학중, 4;휴학중, 5:자퇴, 6:재적)
          'grade':my_edu.grade, // 학점(4.14)
          'grade_total':my_edu.grade_total, // 총점(4.5, 4.3, 4.0, 100)
        });
      }
    }
    t.education_list = t.education;

    t.language = [];
    let language = myinfo.language;
    if(language) {
      for(let i=0; i<language.length; i++) {
        let my_lang = language[i];
        t.language.push({
          'idx':my_lang.idx,
          'sort_no':my_lang.sort_no,
          'code':my_lang.code,
          'language':my_lang.language,
          'language_name':my_lang.language_name,
          'level':my_lang.level,
        });
      }
    }
    t.language_list = t.language;
    
    t.portfolio = [];
    let portfolio = myinfo.portfolio;
    if(portfolio) {
      for(let k=0; k<portfolio.length; k++) {
        let my_pf = portfolio[k];
        t.portfolio.push({
          'idx':my_pf.idx,
          'sort_no':my_pf.sort_no,
          'portfolio_url':my_pf.portfolio_url,
          'portfolio_link':my_pf.portfolio_link,
          'portfolio_title':my_pf.portfolio_title,
          'portfolio_contents':my_pf.portfolio_contents,
        });
      }
    }
    t.portfolio_list = t.portfolio;
    
    t.profile = [];
    let profile = myinfo.profile;
    if(profile) {
      for(let h=0; h<profile.length; h++) {
        let my_profile = profile[h];
        t.profile.push({
          'idx':my_profile.idx,
          'sort_no':my_profile.sort_no,
          'profile_title':my_profile.profile_title,
          'profile_contents':my_profile.profile_contents,
        });
      }
    }
    t.profile_list = t.profile;
    
    t.wish_company = [];
    let wish_company = myinfo.wish_company;
    if(wish_company) {
      for(let h=0; h<wish_company.length; h++) {
        let my_wishcompany = wish_company[h];
        t.wish_company.push({
          'idx':my_wishcompany.idx,
          'sort_no':my_wishcompany.sort_no,
          'resume_idx':my_wishcompany.resume_idx,
          'company_idx':my_wishcompany.company_idx,
          'company_name':my_wishcompany.company_name,
        });
      }
    }
    t.wish_company_list = t.wish_company;

    
    t.wish_headhunter = [];
    let wish_headhunter = myinfo.wish_headhunter;
    if(wish_headhunter) {
      for(let h=0; h<wish_headhunter.length; h++) {
        let my_wishheadhunter = wish_headhunter[h];
        t.wish_headhunter.push({
          'idx':my_wishheadhunter.idx,
          'sort_no':my_wishheadhunter.sort_no,
          'resume_idx':my_wishheadhunter.resume_idx,
          'headhunter_idx':my_wishheadhunter.headhunter_idx,
          'headhunter_name':my_wishheadhunter.headhunter_name,
        });
      }
    }
    t.wish_headhunter_list = t.wish_headhunter;

    r.push(t);
  }
  return r;
}

// Career
export const convertCareerData = (career_data) => {
  /**
   * \"career\":[{
   * \"resume_idx\":\"GDVBG80790\",
   * \"sort_no\":\"1\",
   * \"status\":\"2\",
   * \"company_name\":\"\\ubc95\\ubb34\\ubc95\\uc778OO\",
   * \"department_name\":\"\\uc778\\uc0ac\\ucd1d\\ubb34\\ud300\",
   * \"date_join\":\"2000.01\",
   * \"date_retire\":\"2022.08\",
   * \"job_title\":\"\\ucc28\\uc7a5\",
   * \"pay_amount\":\"9000\",
   * \"job_in_charge\":\"\"
   * }],
   * 
  */
  if(!career_data) { return []; }
  let r = [];
  for (let i = 0; i < career_data.length; i++) {
    let t = {},
    career = career_data[i];
    t.sort_no = career.sort_no;
    t.status = career.status;
    t.company_name = career.company_name;
    t.department_name = career.department_name; 
    t.date_join = career.date_join;
    t.date_retire = career.date_retire;
    t.datjob_titlee_join = career.job_title;
    t.pay_amount = career.pay_amount;
    t.job_in_charge = career.job_in_charge;
    
    r.push(t);
  }
  return r;
}

// Company Detail
export const convertCompanyDetailData = (company_data) => {
  if(!company_data) { return []; }
  let r = [];
  // console.log('company_data.length', company_data.length);

  // {"success":true,"id":"078fefd0c5e95282e4cc9af8568adb5b2752c679",
  // "payload":{"cnt_total":1,
  // "data":[[{"company_idx":"FR567SAR60","userno":"3656","active":"Y",
  // "company_name":"1001 (\uc8fc)\ucc9c\uc77c","ceo_name":"\uae40\ucc9c\uc77c","date_establish":"1994",
  // "cnt_employees":"3500","company_capital":"320\uc5b5\uc6d0","company_sales":"2\uc870 8000\uc5b5",
  // "company_address":"\uacbd\uae30 \uc131\ub0a8\uc2dc \ubd84\ub2f9\uad6c \ud310\uad50\ub85c256\ubc88\uae38 7 (\uc0bc\ud3c9\ub3d9) \ub125\uc2a8\ucf54\ub9ac\uc544",
  // "company_homepage_url":"http:\/\/company.nexon.com\/",
  // "company_logo_url":"https:\/\/img.jobkorea.co.kr\/Images\/Logo\/160\/n\/e\/nexonhr_160.gif",
  // "company_image1_url":"https:\/\/img.jobkorea.co.kr\/images\/ContentsManagement\/SmartEditor\/2021\/6\/2021614162636_SEU.png",
  // "company_image2_url":"https:\/\/img.jobkorea.co.kr\/images\/ContentsManagement\/SmartEditor\/2021\/6\/2021614162724_SEU.png",
  // "company_image3_url":"https:\/\/img.jobkorea.co.kr\/images\/ContentsManagement\/SmartEditor\/2021\/6\/2021614162733_SEU.png",
  // "company_image4_url":"https:\/\/img.jobkorea.co.kr\/images\/ContentsManagement\/SmartEditor\/2020\/12\/20201217101636_SEU.png",
  
  // "company_welfare":"\uad6d\ubbfc\uc5f0\uae08, \uace0\uc6a9\ubcf4\ud5d8, \uc0b0\uc7ac\ubcf4\ud5d8, \uac74\uac15\ubcf4\ud5d8",
  // "company_industry":"\ucef4\ud4e8\ud130\u00b7\ud558\ub4dc\uc6e8\uc5b4\u00b7\uc7a5\ube44",
  // "company_size":"3","company_explain":"1994\ub144 \uc124\ub9bd, \uc62c\ud574\ub85c \ucc3d\ub9bd 20\uc8fc\ub144\uc744 \ub9de\uc774\ud55c (\uc8fc)\ub125\uc2a8\uc740 \uc5f0 \ub9e4\ucd9c 2\uc870 8\ucc9c\uc5b5\uc6d0,(2013\ub144 \ub9d0 \ub125\uc2a8 \uc77c\ubcf8\ubc95\uc778 \uc5f0\uacb0\ub9e4\ucd9c \uae30\uc900) \uc0ac\uc6d0\uc218 3,500\uba85 \uaddc\ubaa8\uc758 \uae00\ub85c\ubc8c \uac8c\uc784\ud68c\uc0ac \uc785\ub2c8\ub2e4.\n2002\ub144 \uc77c\ubcf8, 2005\ub144 \ubbf8\uad6d, 2007\ub144 \uc720\ub7fd\uc5d0 \ud604\uc9c0\ubc95\uc778\uc744 \uc124\ub9bd\ud558\uba70 \uc77c\ucc0c\uac10\uce58 \ud574\uc678 \uc2dc\uc7a5 \uac1c\ucc99\uc5d0 \uc55e\uc7a5\uc11c\uc628 \ub125\uc2a8\uc740 \ud604\uc7ac \uc77c\ubcf8,\uc911\uad6d,\ub300\ub9cc \ub4f1 \ub3d9\uc544\uc2dc\uc544 3\uad6d\uc744 \ube44\ub86f\ud574 \ubd81\ubbf8\uc9c0\uc5ed\uacfc \uc720\ub7fd, \ub0a8\ubbf8 \ub4f1 \uc804 \uc138\uacc4 110\uc5ec\uac1c\uad6d\uc5d0 \uc9c4\ucd9c\ud574 150\uc5ec \uac1c\uc758 \uac8c\uc784\uc744 \uc11c\ube44\uc2a4 \uc911\uc774\uba70, \ud604\uc7ac 14\uc5b5 \uba85\uc758 \uc0ac\uc6a9\uc790(\uacc4\uc815)\uc744 \ud655\ubcf4\ud558\uace0 \uc788\uc2b5\ub2c8\ub2e4. \uacbd\uae30\ub3c4 \uc131\ub0a8\uc2dc \ubd84\ub2f9\uad6c \ud310\uad50\ub85c\uc5d0 \uc704\uce58\ud558\uace0 \uc788\uc73c\uba70, \uc628\ub77c\uc778, \ubaa8\ubc14\uc77c, \uc18c\uc15c \uac8c\uc784\uc758 \uac1c\ubc1c \ubc0f \uc11c\ube44\uc2a4 \uc0ac\uc5c5\uc744 \ud558\uace0 \uc788\uc2b5\ub2c8\ub2e4.",
  // "hrmanager_name":"\ucc44\uc6a9\ub2f4\ub2f9\uc790",
  // "hrmanager_department":"\uc804\ub7b5",
  // "hrmanager_phone":"02-538-1501",
  // "hrmanager_mobile":"010-9789-9547",
  // "hrmanager_email":"itsddan@gmail.com",
  // "check_hrmanager_email":"N",
  // "regdate":"2022-10-02 19:45:20",
  // "moddate":"2022-10-02 19:49:02"}]]}}

  for (let i = 0; i < company_data.length; i++) {
    let t = {},
    company = company_data[i];
    t.regdate = company.regdate;
    t.id = company.regdate;
    t.no = company.regdate; // 매일초기화할수있음.
    t.date = company.regdate;
    t.reg_date = moment(t.date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";

    t.userno = company.userno;
    t.active = company.active;
    t.company_idx = company.company_idx;
    t.company_name = company.company_name;
    t.business_num = company.business_num;
    t.ceo_name = company.ceo_name;
    t.date_establish = company.date_establish;
    t.cnt_employees = company.cnt_employees;
    t.company_capital = company.company_capital;
    t.company_sales = company.company_sales;
    t.company_address = company.company_address;
    t.company_logo_url = company.company_logo_url;
    t.company_homepage_url = company.company_homepage_url;
    t.company_image1_url = company.company_image1_url;
    t.company_image2_url = company.company_image2_url;
    t.company_image3_url = company.company_image3_url;
    t.company_image4_url = company.company_image4_url;
    t.company_welfare = company.company_welfare;
    t.company_industry = company.company_industry;
    t.company_size = company.company_size;

    switch (t.company_size) {
      case '1':
      t.company_type = '대기업';
      break;
      case '2':
      t.company_type = '중견기업';
      break; 
      case '3':
      t.company_type = '중소기업';
      break; 
      case '4':
      t.company_type = '스타트업';
      break; 
      case '5':
      t.company_type = '외국계기업';
      break; 
      default:
      t.company_type = '-';
    }
    
    t.company_explain = company.company_explain;
    t.hrmanager_name = company.hrmanager_name;
    t.hrmanager_department = company.hrmanager_department;
    t.hrmanager_phone = company.hrmanager_phone;
    t.hrmanager_mobile = company.hrmanager_mobile;
    t.hrmanager_email = company.hrmanager_email;
    t.check_hrmanager_email = company.check_hrmanager_email
    // t.moddate = company.moddate;

    t.recruits = [];
    let recruits = company.recruits;
    if(recruits) {
        for (let g = 0; g < recruits.length; g++) {
            let company_recruits = recruits[g];
          t.recruits.push({
            'recruit_idx':company_recruits.recruit_idx, 
            // 'goods_idx':order_bids.goods_idx,
            // 'userid':order_bids.userid,
            // 'bid_price':order_bids.bid_price,
            // 'auction_price_usd':order_bids.auction_price_usd,
            // 'bid_date':order_bids.bid_date,
            // 'bider':order_bids.bider,
            // 'apply_type':order_bids.apply_type
          });
        }
    }
    t.list = t.recruits;

    t.name = company.name;
    t.email = company.email;
    t.image_identify_url = company.image_identify_url;
    t.modify_date = moment(company.modify_date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";
    
    t.cnt_recruit = company.cnt_recruit;
    t.cnt_suggest_unread = company.cnt_suggest_unread;
    t.cnt_suggest_read = company.cnt_suggest_read;
    t.cnt_suggest = company.cnt_suggest;
    t.cnt_apply_unread = company.cnt_apply_unread;
    t.cnt_apply_read = company.cnt_apply_read;
    t.cnt_apply = company.cnt_apply;
    t.cnt_match = company.cnt_match;

    r.push(t);
  }
  return r;
}

// Company List
export const convertCompanyData = (company_data) => {
  if(!company_data) { return []; }
  let r = [];
  // console.log('company_data.length', company_data.length);
  for (let i = 0; i < company_data.length; i++) {
    let t = {},
    company = company_data[i];
    t.regdate = company.regdate;
    t.id = company.regdate;
    t.no = company.regdate; // 매일초기화할수있음.
    t.date = company.regdate;
    t.reg_date = moment(t.date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";

    t.userno = company.userno;
    t.active = company.active;
    t.company_idx = company.company_idx;
    t.company_name = company.company_name;
    t.business_num = company.business_num;
    t.ceo_name = company.ceo_name;
    t.company_logo_url = company.company_logo_url;

    t.date_establish = company.date_establish;
    t.cnt_employees = company.cnt_employees;
    t.company_capital = company.company_capital;
    t.company_industry = company.company_industry;
    t.company_explain = company.company_explain;
    t.company_explain = t.company_explain.substring(0,180);

    t.applies = [];
    let applies = company.applies;
    if(applies) {
        for (let g = 0; g < applies.length; g++) {
            let client_applies = applies[g];
          t.applies.push({
            'recruit_idx':client_applies.recruit_idx, 
            // 'goods_idx':order_bids.goods_idx,
            // 'userid':order_bids.userid,
            // 'bid_price':order_bids.bid_price,
            // 'auction_price_usd':order_bids.auction_price_usd,
            // 'bid_date':order_bids.bid_date,
            // 'bider':order_bids.bider,
            // 'apply_type':order_bids.apply_type
          });
        }
    }
    t.list = t.applies;

    
    t.suggest_type = company.suggest_type;
    t.suggest_title = company.suggest_title;
    t.suggest_message = company.suggest_message;
    t.date_suggest = company.date_suggest;

    t.date_view = company.date_view;
    t.date_view = t.date_view?moment(t.date_view).format("YYYY-MM-DD"):'-'; // 열람날짜
    t.date_agree = company.date_agree;
    t.date_agree = t.date_agree?moment(t.date_agree).format("YYYY-MM-DD"):'-'; 
    t.date_refuse = company.date_refuse;
    t.date_refuse = t.date_refuse?moment(t.date_refuse).format("YYYY-MM-DD"):'-'; 

    t.show_status = company.show_status;

    t.cnt_recruit = company.cnt_recruit;
    t.cnt_suggest_unread = company.cnt_suggest_unread;
    t.cnt_suggest_read = company.cnt_suggest_read;
    t.cnt_suggest = company.cnt_suggest;
    t.cnt_apply_unread = company.cnt_apply_unread;
    t.cnt_apply_read = company.cnt_apply_read;
    t.cnt_apply = company.cnt_apply;
    t.cnt_match = company.cnt_match;
    

    r.push(t);
  }
  return r;
}

// HeadHunter Detail
export const convertSearchFirmDetailData = (headhunter_data) => {
  /**
  `userno` int(11) NOT NULL COMMENT '회사 등록 회원',
  `active` char(1) DEFAULT 'Y' COMMENT '노출여부',
  `headhunter_idx` char(11) NOT NULL COMMENT '회사번호',
  `headhunter_name` varchar(50) DEFAULT '' COMMENT '회사이름',
  `ceo_name` varchar(50) DEFAULT '' COMMENT '대표자 이름',
  `date_establish` char(10) DEFAULT '' COMMENT '설립일',
  `cnt_employees` int(11) DEFAULT '1' COMMENT '사원수',
  `headhunter_capital` varchar(20) DEFAULT '' COMMENT '회사 자본금',
  `headhunter_sales` varchar(20) DEFAULT '' COMMENT '회사 매출액',
  `headhunter_address` varchar(100) DEFAULT '' COMMENT '회사 주소',
  `headhunter_homepage_url` varchar(200) DEFAULT '' COMMENT '회사 홈페이지',
  `headhunter_logo_url` varchar(200) DEFAULT '' COMMENT '회사 로고 이미지 URL',
  `headhunter_image1_url` varchar(200) DEFAULT '' COMMENT '회사 이미지1 url',
  `headhunter_image2_url` varchar(200) DEFAULT '' COMMENT '회사 이미지2 url',
  `headhunter_image3_url` varchar(200) DEFAULT '' COMMENT '회사 이미지3 url',
  `headhunter_image4_url` varchar(200) DEFAULT '' COMMENT '회사 이미지4 url',
  `headhunter_welfare` varchar(100) DEFAULT '' COMMENT '회사정보 복리후생',
  `headhunter_industry` varchar(100) DEFAULT '' COMMENT '회사정보 산업',
  `headhunter_size` char(1) DEFAULT '' COMMENT '회사정보 기업유형. 1:대기업, 2:중견기업, 3:중소기업, 4:스타트업, 5:외국계기업',
  `headhunter_explain` text COMMENT '회사 설명(HTML)',
  `hrmanager_name` varchar(50) DEFAULT '' COMMENT '기본 인사담당자 이름',
  `hrmanager_department` varchar(50) DEFAULT '' COMMENT '기본 인사담당자 부서',
  `hrmanager_phone` varchar(50) DEFAULT '' COMMENT '기본 인사담당자 전화번호',
  `hrmanager_mobile` varchar(50) DEFAULT '' COMMENT '기본 인사담당자 휴대폰',
  `hrmanager_email` varchar(50) DEFAULT '' COMMENT '기본 인사담당자 이메일',
  `check_hrmanager_email` char(1) DEFAULT 'N' COMMENT '기본 인사담당자 이메일 인증여부. 회사도메인사용. Y: 인증됨. N: 비인증',
  `regdate` datetime DEFAULT NULL COMMENT '등록날짜',
  `moddate` datetime DEFAULT NULL COMMENT '수정날짜',
  */
  if(!headhunter_data) { return []; }
  let r = [];
  // console.log('headhunter_data.length', headhunter_data.length);
  for (let i = 0; i < headhunter_data.length; i++) {
    let t = {},
    headhunter = headhunter_data[i];
    t.regdate = headhunter.regdate;
    t.id = headhunter.regdate;
    t.no = headhunter.regdate; // 매일초기화할수있음.
    t.date = headhunter.regdate;
    t.reg_date = moment(t.date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";

    t.userno = headhunter.userno;
    t.active = headhunter.active;
    t.headhunter_idx = headhunter.headhunter_idx;
    t.headhunter_name = headhunter.headhunter_name;
    t.ceo_name = headhunter.ceo_name;
    t.headhunter_logo_url = headhunter.headhunter_logo_url;

    t.date_establish = headhunter.date_establish;
    t.cnt_employees = headhunter.cnt_employees;
    t.headhunter_capital = headhunter.headhunter_capital;
    t.headhunter_sales = headhunter.headhunter_sales;
    t.headhunter_welfare = headhunter.headhunter_welfare;
    t.headhunter_industry = headhunter.headhunter_industry;
    t.headhunter_size = headhunter.headhunter_size;
    t.headhunter_explain = headhunter.headhunter_explain;
    t.headhunter_explain = t.headhunter_explain.substring(0,180);
    t.headhunter_homepage_url = headhunter.headhunter_homepage_url;
    t.headhunter_address = headhunter.headhunter_address;
    t.headhunter_image1_url = headhunter.headhunter_image1_url;
    t.headhunter_image2_url = headhunter.headhunter_image2_url;
    t.headhunter_image3_url = headhunter.headhunter_image3_url;
    t.headhunter_image4_url = headhunter.headhunter_image4_url;

    t.hrmanager_name = headhunter.hrmanager_name;
    t.hrmanager_department = headhunter.hrmanager_department;
    t.hrmanager_phone = headhunter.hrmanager_phone;
    t.hrmanager_mobile = headhunter.hrmanager_mobile;
    t.hrmanager_email = headhunter.hrmanager_email;
    t.check_hrmanager_email = headhunter.check_hrmanager_email
    // t.moddate = headhunter.moddate;



    t.recruits = [];
    let recruits = headhunter.recruits;
    if(recruits) {
        for (let g = 0; g < recruits.length; g++) {
            let headhunter_recruits = recruits[g];
          t.recruits.push({
            'recruit_idx':headhunter_recruits.recruit_idx, 
            // 'goods_idx':order_bids.goods_idx,
            // 'userid':order_bids.userid,
            // 'bid_price':order_bids.bid_price,
            // 'auction_price_usd':order_bids.auction_price_usd,
            // 'bid_date':order_bids.bid_date,
            // 'bider':order_bids.bider,
            // 'apply_type':order_bids.apply_type
          });
        }
    }
    t.list = t.recruits;
    
    t.name = headhunter.name;
    t.email = headhunter.email;
    t.image_identify_url = headhunter.image_identify_url;

    // t.modify_date = new Date(headhunter.modify_date * 1000);
    // t.modify_date = t.modify_date.toLocaleString();

    t.modify_date = moment(headhunter.modify_date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";
    
    r.push(t);
  }
  return r;
}

// HeadHunter List
export const convertSearchFirmData = (headhunter_data) => {
  /**
  `userno` int(11) NOT NULL COMMENT '회사 등록 회원',
  `active` char(1) DEFAULT 'Y' COMMENT '노출여부',
  `headhunter_idx` char(11) NOT NULL COMMENT '회사번호',
  `headhunter_name` varchar(50) DEFAULT '' COMMENT '회사이름',
  `ceo_name` varchar(50) DEFAULT '' COMMENT '대표자 이름',
  `date_establish` char(10) DEFAULT '' COMMENT '설립일',
  `cnt_employees` int(11) DEFAULT '1' COMMENT '사원수',
  `headhunter_capital` varchar(20) DEFAULT '' COMMENT '회사 자본금',
  `headhunter_sales` varchar(20) DEFAULT '' COMMENT '회사 매출액',
  `headhunter_address` varchar(100) DEFAULT '' COMMENT '회사 주소',
  `headhunter_homepage_url` varchar(200) DEFAULT '' COMMENT '회사 홈페이지',
  `headhunter_logo_url` varchar(200) DEFAULT '' COMMENT '회사 로고 이미지 URL',
  `headhunter_image1_url` varchar(200) DEFAULT '' COMMENT '회사 이미지1 url',
  `headhunter_image2_url` varchar(200) DEFAULT '' COMMENT '회사 이미지2 url',
  `headhunter_image3_url` varchar(200) DEFAULT '' COMMENT '회사 이미지3 url',
  `headhunter_image4_url` varchar(200) DEFAULT '' COMMENT '회사 이미지4 url',
  `headhunter_welfare` varchar(100) DEFAULT '' COMMENT '회사정보 복리후생',
  `headhunter_industry` varchar(100) DEFAULT '' COMMENT '회사정보 산업',
  `headhunter_size` char(1) DEFAULT '' COMMENT '회사정보 기업유형. 1:대기업, 2:중견기업, 3:중소기업, 4:스타트업, 5:외국계기업',
  `headhunter_explain` text COMMENT '회사 설명(HTML)',
  `hrmanager_name` varchar(50) DEFAULT '' COMMENT '기본 인사담당자 이름',
  `hrmanager_department` varchar(50) DEFAULT '' COMMENT '기본 인사담당자 부서',
  `hrmanager_phone` varchar(50) DEFAULT '' COMMENT '기본 인사담당자 전화번호',
  `hrmanager_mobile` varchar(50) DEFAULT '' COMMENT '기본 인사담당자 휴대폰',
  `hrmanager_email` varchar(50) DEFAULT '' COMMENT '기본 인사담당자 이메일',
  `check_hrmanager_email` char(1) DEFAULT 'N' COMMENT '기본 인사담당자 이메일 인증여부. 회사도메인사용. Y: 인증됨. N: 비인증',
  `regdate` datetime DEFAULT NULL COMMENT '등록날짜',
  `moddate` datetime DEFAULT NULL COMMENT '수정날짜',
  */
  if(!headhunter_data) { return []; }
  let r = [];
  // console.log('headhunter_data.length', headhunter_data.length);
  for (let i = 0; i < headhunter_data.length; i++) {
    let t = {},
    headhunter = headhunter_data[i];
    t.regdate = headhunter.regdate;
    t.id = headhunter.regdate;
    t.no = headhunter.regdate; // 매일초기화할수있음.
    t.date = headhunter.regdate;
    t.reg_date = moment(t.date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";

    t.userno = headhunter.userno;
    t.active = headhunter.active;
    t.headhunter_idx = headhunter.headhunter_idx;
    t.headhunter_name = headhunter.headhunter_name;
    t.ceo_name = headhunter.ceo_name;
    t.headhunter_logo_url = headhunter.headhunter_logo_url;

    t.date_establish = headhunter.date_establish;
    t.cnt_employees = headhunter.cnt_employees;
    t.headhunter_capital = headhunter.headhunter_capital;
    t.headhunter_industry = headhunter.headhunter_industry;
    t.headhunter_explain = headhunter.headhunter_explain;
    t.headhunter_explain = t.headhunter_explain.substring(0,180);

    t.hrmanager_name = headhunter.hrmanager_name;
    t.hrmanager_department = headhunter.hrmanager_department;
    t.hrmanager_phone = headhunter.hrmanager_phone;
    t.hrmanager_mobile = headhunter.hrmanager_mobile;
    t.hrmanager_email = headhunter.hrmanager_email;
    t.check_hrmanager_email = headhunter.check_hrmanager_email
    t.moddate = headhunter.moddate;



    t.applies = [];
    let applies = headhunter.applies;
    if(applies) {
        for (let g = 0; g < applies.length; g++) {
            let client_applies = applies[g];
          t.applies.push({
            'recruit_idx':client_applies.recruit_idx, 
            // 'goods_idx':order_bids.goods_idx,
            // 'userid':order_bids.userid,
            // 'bid_price':order_bids.bid_price,
            // 'auction_price_usd':order_bids.auction_price_usd,
            // 'bid_date':order_bids.bid_date,
            // 'bider':order_bids.bider,
            // 'apply_type':order_bids.apply_type
          });
        }
    }
    t.list = t.applies;
    
    r.push(t);
  }
  return r;
}

// HeadHunter Detail
export const convertHeadHunterDetailData = (headhunter_data) => {
  if(!headhunter_data) { return []; }
  let r = [];
  // console.log('headhunter_data.length', headhunter_data.length);
  for (let i = 0; i < headhunter_data.length; i++) {
    let t = {},
    headhunter = headhunter_data[i];
    t.regdate = headhunter.regdate;
    t.id = headhunter.regdate;
    t.no = headhunter.regdate; // 매일초기화할수있음.
    t.date = headhunter.regdate;
    t.reg_date = moment(t.date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";

    t.userno = headhunter.userno;
    t.active = headhunter.active;
    t.headhunter_idx = headhunter.headhunter_idx;
    t.headhunter_name = headhunter.headhunter_name;
    t.ceo_name = headhunter.ceo_name;
    t.headhunter_logo_url = headhunter.headhunter_logo_url;

    t.date_establish = headhunter.date_establish;
    t.cnt_employees = headhunter.cnt_employees;
    t.headhunter_capital = headhunter.headhunter_capital;
    t.headhunter_sales = headhunter.headhunter_sales;
    t.headhunter_industry = headhunter.headhunter_industry;
    t.headhunter_explain = headhunter.headhunter_explain;
    t.headhunter_explain = t.headhunter_explain.substring(0,180);
    t.headhunter_homepage_url = headhunter.headhunter_homepage_url;
    t.headhunter_address = headhunter.headhunter_address;
    t.headhunter_image1_url = headhunter.headhunter_image1_url;
    t.headhunter_image2_url = headhunter.headhunter_image2_url;
    t.headhunter_image3_url = headhunter.headhunter_image3_url;
    t.headhunter_image4_url = headhunter.headhunter_image4_url;


    t.hrmanager_name = headhunter.hrmanager_name;
    t.hrmanager_department = headhunter.hrmanager_department;
    t.hrmanager_phone = headhunter.hrmanager_phone;
    t.hrmanager_mobile = headhunter.hrmanager_mobile;
    t.hrmanager_email = headhunter.hrmanager_email;
    t.check_hrmanager_email = headhunter.check_hrmanager_email
    t.moddate = headhunter.moddate;


    t.recruits = [];
    let recruits = headhunter.recruits;
    if(recruits) {
        for (let g = 0; g < recruits.length; g++) {
            let headhunter_recruits = recruits[g];
          t.recruits.push({
            'recruit_idx':headhunter_recruits.recruit_idx, 
            // 'goods_idx':order_bids.goods_idx,
            // 'userid':order_bids.userid,
            // 'bid_price':order_bids.bid_price,
            // 'auction_price_usd':order_bids.auction_price_usd,
            // 'bid_date':order_bids.bid_date,
            // 'bider':order_bids.bider,
            // 'apply_type':order_bids.apply_type
          });
        }
    }
    t.list = t.recruits;
    
    t.name = headhunter.name;
    t.image_identify_url = headhunter.image_identify_url;
    t.modify_date = moment(headhunter.modify_date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";

    r.push(t);
  }
  return r;
}

// HeadHunter List
export const convertHeadHunterData = (headhunter_data) => {
  if(!headhunter_data) { return []; }
  let r = [];
  // console.log('headhunter_data.length', headhunter_data.length);
  for (let i = 0; i < headhunter_data.length; i++) {
    let t = {},
    headhunter = headhunter_data[i];
    t.regdate = headhunter.regdate;
    t.id = headhunter.regdate;
    t.no = headhunter.regdate; // 매일초기화할수있음.
    t.date = headhunter.regdate;
    t.reg_date = moment(t.date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";

    t.userno = headhunter.userno;
    t.active = headhunter.active;
    t.headhunter_idx = headhunter.headhunter_idx;
    t.headhunter_name = headhunter.headhunter_name;
    t.ceo_name = headhunter.ceo_name;
    t.headhunter_logo_url = headhunter.headhunter_logo_url;

    t.date_establish = headhunter.date_establish;
    t.cnt_employees = headhunter.cnt_employees;
    t.headhunter_capital = headhunter.headhunter_capital;
    t.headhunter_industry = headhunter.headhunter_industry;
    t.headhunter_explain = headhunter.headhunter_explain;
    t.headhunter_explain = t.headhunter_explain.substring(0,180);


    t.hrmanager_name = headhunter.hrmanager_name;
    t.hrmanager_department = headhunter.hrmanager_department;
    t.hrmanager_phone = headhunter.hrmanager_phone;
    t.hrmanager_mobile = headhunter.hrmanager_mobile;
    t.hrmanager_email = headhunter.hrmanager_email;
    t.check_hrmanager_email = headhunter.check_hrmanager_email
    t.moddate = headhunter.moddate;


    t.applies = [];
    let applies = headhunter.applies;
    if(applies) {
        for (let g = 0; g < applies.length; g++) {
            let client_applies = applies[g];
          t.applies.push({
            'recruit_idx':client_applies.recruit_idx, 
            // 'goods_idx':order_bids.goods_idx,
            // 'userid':order_bids.userid,
            // 'bid_price':order_bids.bid_price,
            // 'auction_price_usd':order_bids.auction_price_usd,
            // 'bid_date':order_bids.bid_date,
            // 'bider':order_bids.bider,
            // 'apply_type':order_bids.apply_type
          });
        }
    }
    t.list = t.applies;
   
    t.suggest_type = headhunter.suggest_type;
    t.suggest_title = headhunter.suggest_title;
    t.suggest_message = headhunter.suggest_message;
    t.date_suggest = headhunter.date_suggest;

    t.date_view = headhunter.date_view;
    t.date_view = t.date_view?moment(t.date_view).format("YYYY-MM-DD"):'-'; // 열람날짜
    t.date_agree = headhunter.date_agree;
    t.date_agree = t.date_agree?moment(t.date_agree).format("YYYY-MM-DD"):'-'; 
    t.date_refuse = headhunter.date_refuse;
    t.date_refuse = t.date_refuse?moment(t.date_refuse).format("YYYY-MM-DD"):'-'; 

    t.show_status = headhunter.show_status;

    r.push(t);
  }
  return r;
}

// Banner List
export const convertBannerData = (banner_data) => {
  if(!banner_data) { return []; }
  let r = [];
  // console.log('banner_data.length', banner_data.length);
  for (let i = 0; i < banner_data.length; i++) {
    let t = {},
    banner = banner_data[i];
    t.company_idx = banner.company_idx;
    t.company_name = banner.company_name;
    t.company_logo_url = banner.company_logo_url;

    r.push(t);
  }
  return r;
}

// Review
export const convertReviewData = (review_data) => {
  /**
  */
  if(!review_data) { return []; }
  let r = [];
  // console.log('review_data.length', review_data.length);
  for (let i = 0; i < review_data.length; i++) {
    let t = {},
    review = review_data[i];
    t.regdate = review.regdate;
    t.id = review.regdate;
    t.no = review.regdate; // 매일초기화할수있음.
    t.date = review.regdate;
    t.reg_date = moment(t.date).format("YYYY-MM-DD HH:mm:ss"); // "13:16(19-11-07)";

    t.idx = review.idx;
    t.userid = review.userid;
    t.author = review.author;
    t.subject = review.subject;
    t.contents = review.contents;
    t.file = review.file;
    t.file_src = review.file_src;
    t.hit = review.hit;
    t.recommand = review.recommand;
    t.cnt_comment = review.cnt_comment;
    t.golink = review.golink;
    t.occupation = review.occupation;
    
    r.push(t);
  }
  return r;
}

// Wish Company List
export const convertWishCompanyData = (wishcompany_data) => {
  if(!wishcompany_data) { return []; }
  let r = [];
  // console.log('wishcompany_data.length', wishcompany_data.length);
  for (let i = 0; i < wishcompany_data.length; i++) {
    let t = {},
    wishcompany = wishcompany_data[i];

    t.company_idx = wishcompany.company_idx;
    t.userno = wishcompany.userno;
    t.active = wishcompany.active;
    t.company_name = wishcompany.company_name;
    t.ceo_name = wishcompany.ceo_name;
    t.cnt_employees = wishcompany.cnt_employees;
    t.company_logo_url = wishcompany.company_logo_url;

    t.date_establish = wishcompany.date_establish;
    t.cnt_employees = wishcompany.cnt_employees;
    t.company_capital = wishcompany.company_capital;
    t.company_industry = wishcompany.company_industry;
    t.company_explain = wishcompany.company_explain;
    t.company_explain = t.company_explain.substring(0,180);

    t.applies = [];
    let applies = wishcompany.applies;
    if(applies) {
        for (let g = 0; g < applies.length; g++) {
            let client_applies = applies[g];
          t.applies.push({
            'recruit_idx':client_applies.recruit_idx, 
            // 'goods_idx':order_bids.goods_idx,
            // 'userid':order_bids.userid,
            // 'bid_price':order_bids.bid_price,
            // 'auction_price_usd':order_bids.auction_price_usd,
            // 'bid_date':order_bids.bid_date,
            // 'bider':order_bids.bider,
            // 'apply_type':order_bids.apply_type
          });
        }
    }
    t.list = t.applies;
    
    r.push(t);
  }
  return r;
}

// Wish HeadHunter List
export const convertWishHeadHunterData = (wishheadhunter_data) => {
  if(!wishheadhunter_data) { return []; }
  let r = [];
  // console.log('wishheadhunter_data.length', wishheadhunter_data.length);
  for (let i = 0; i < wishheadhunter_data.length; i++) {
    let t = {},
    wishheadhunter = wishheadhunter_data[i];

    t.headhunter_idx = wishheadhunter.headhunter_idx;
    t.userno = wishheadhunter.userno;
    t.active = wishheadhunter.active;
    t.headhunter_name = wishheadhunter.headhunter_name;
    t.ceo_name = wishheadhunter.ceo_name;
    t.cnt_employees = wishheadhunter.cnt_employees;
    t.headhunter_logo_url = wishheadhunter.headhunter_logo_url;

    t.date_establish = wishheadhunter.date_establish;
    t.cnt_employees = wishheadhunter.cnt_employees;
    t.headhunter_capital = wishheadhunter.headhunter_capital;
    t.headhunter_industry = wishheadhunter.headhunter_industry;
    t.headhunter_explain = wishheadhunter.headhunter_explain;
    t.headhunter_explain = t.headhunter_explain.substring(0,180);

    t.applies = [];
    let applies = wishheadhunter.applies;
    if(applies) {
        for (let g = 0; g < applies.length; g++) {
            let client_applies = applies[g];
          t.applies.push({
            'recruit_idx':client_applies.recruit_idx, 
            // 'goods_idx':order_bids.goods_idx,
            // 'userid':order_bids.userid,
            // 'bid_price':order_bids.bid_price,
            // 'auction_price_usd':order_bids.auction_price_usd,
            // 'bid_date':order_bids.bid_date,
            // 'bider':order_bids.bider,
            // 'apply_type':order_bids.apply_type
          });
        }
    }
    t.list = t.applies;
    
    r.push(t);
  }
  return r;
}

// Recruit List
export const convertRecruitData = (recruit_data) => {
  if(!recruit_data) { return []; }
  let r = [];
  for (let i = 0; i < recruit_data.length; i++) {
    let t = {},
    recruit = recruit_data[i];

    t.recruit_idx = recruit.recruit_idx;
    t.recruit_active = recruit.recruit_active;
    t.userno = recruit.userno;
    t.usertype = recruit.usertype;
    if(recruit.company_logo_url) {
      t.company_logo_url = recruit.company_logo_url; // logo
    } else {
      let s = i%5 + 1;
      t.company_logo_url = "/assets/images/companies/logo-"+s+".webp"; // logo
    }
    if(recruit.logo_url) {
      t.logo_url = recruit.logo_url; // logo
    } else {
      let s = i%5 + 1;
      t.logo_url = "/assets/images/companies/logo-"+s+".webp"; // logo
    }
    t.recruit_title = recruit.recruit_title; // 공고 제목
    t.condition_pay_type = recruit.condition_pay_type; // 급여조건
    t.condition_pay_amount = recruit.condition_pay_amount; // 급여조건
    if(recruit.condition_pay_amount==="0") {
      t.condition_pay_amount_str = "연봉업계최고";
    } else {
      t.condition_pay_amount_str = recruit.condition_pay_amount+"만원"; // 근무조건 급여 금액
    }
    t.condition_work_address = recruit.condition_work_address; //근무조건 근무지역
    t.condition_work_area = recruit.condition_work_area; //근무조건 근무지역

    switch (t.condition_work_area) {
      case '1':
      t.condition_work_area_str = '서울';
      break;
      case '2':
      t.condition_work_area_str = '경기';
      break; 
      case '3':
        t.condition_work_area_str = '인천';
      break; 
      case '4':
        t.condition_work_area_str = '대전';
      break; 
      case '5':
        t.condition_work_area_str = '대구';
      break; 
      case '6':
        t.condition_work_area_str = '부산';
      break; 
      case '7':
        t.condition_work_area_str = '포항';
      break; 
      case '8':
        t.condition_work_area_str = '전주';
      break; 
      case '9':
        t.condition_work_area_str = '광주';
      break;
      case '10':
        t.condition_work_area_str = '제주';
      break; 
      case '11':
        t.condition_work_area_str = '충북';
      break; 
      case '12':
        t.condition_work_area_str = '충남';
      break; 
      case '13':
        t.condition_work_area_str = '경북';
      break; 
      case '14':
        t.condition_work_area_str = '경남';
      break; 
      case '15':
        t.condition_work_area_str = '전북';
      break; 
      case '16':
        t.condition_work_area_str = '전남';
      break;
      default:
        t.condition_work_area_str = '기타';
    }

    t.zipcode = recruit.zipcode;
    t.condition_station = recruit.condition_station;
    t.condition_station_name = recruit.condition_station_name;
    t.condition_work_day = recruit.condition_work_day;
    t.condition_work_start_time = recruit.condition_work_start_time;
    t.condition_work_stop_time = recruit.condition_work_stop_time;

    t.reception_period = recruit.reception_period; // 접수기간
    t.apply_method = recruit.apply_method;

    t.recruit_area = recruit.recruit_area; //모집분야
    t.recruit_cnt = recruit.recruit_cnt; //모집인원
    t.recruit_price = recruit.recruit_price; //모집금액
    t.job_title = recruit.job_title; // 직급/직책

    t.career_type_new = recruit.career_type_new; // 경력종류 신입 Y/N
    t.career_type_exp = recruit.career_type_exp; // 경력종류 경력 Y/N
    t.career_type_all = recruit.career_type_all; // 경력종류 경력무관 Y/N
    t.career_type_exp_year = recruit.career_type_exp_year; // 경력종류 경력 년수

    switch (t.career_type_exp_year) {
      case '1':
      t.career_type_exp_year_str = '신입';
      break;
      case '2':
      t.career_type_exp_year_str = '1년 ~ 3년 이하';
      break; 
      case '3':
        t.career_type_exp_year_str = '3년 ~ 6년 이하';
      break; 
      case '4':
        t.career_type_exp_year_str = '6년 ~ 10년 이하';
      break; 
      case '5':
        t.career_type_exp_year_str = '10년 ~ 15년 이하';
      break; 
      case '6':
        t.career_type_exp_year_str = '15년 ~ 20년 이하';
      break; 
      case '7':
        t.career_type_exp_year_str = '20년 ~ 25년 이하';
      break; 
      case '8':
        t.career_type_exp_year_str = '25년 ~ 30년 이하';
      break; 
      case '9':
        t.career_type_exp_year_str = '30년 이상';
      break; 
      default:
        t.career_type_exp_year_str = '1년 ~ 3년 이하';
    }
    
    if(recruit.career_type_new === 'Y') {
      t.career_type = "신입";
    } else if(recruit.career_type_exp === 'Y') {
      t.career_type = t.career_type_exp_year_str+ " 경력";
    } else if(recruit.career_type_all === 'Y') {
      t.career_type = "경력무관";
    }

    t.recruit_start_date = recruit.recruit_start_date; // 시작일
    t.recruit_start = moment(t.recruit_start_date).format("YYYY-MM-DD"); // "13:16(19-11-07)";

    t.recruit_stop_date = recruit.recruit_stop_date; // 마감일
    t.recruit_stop = moment(t.recruit_stop_date).format("YYYY-MM-DD"); // "13:16(19-11-07)";

    t.employ_type_regular = recruit.employ_type_regular;
    t.employ_type_contract = recruit.employ_type_contract;
    t.employ_type_intern = recruit.employ_type_intern;
    t.employ_type_dispatched = recruit.employ_type_dispatched;
    t.employ_type_freelancer = recruit.employ_type_freelancer;
    t.employ_type_parttime = recruit.employ_type_parttime;
    t.employ_type_trainee = recruit.employ_type_trainee;
    t.employ_type_military = recruit.employ_type_military;
    t.employ_type_commissioned = recruit.employ_type_commissioned;

    if (t.employ_type_regular === 'Y') {
      t.employ_type = '정규직';
    } else if (t.employ_type_contract === 'Y') {
      t.employ_type = '계약직';
    } else {
      t.employ_type = '정규직';
    }

    t.limits_education = recruit.limits_education; // 학력

    if (t.limits_education === '0') {
      t.education_str = '학력무관';
    } else if (t.limits_education === '1') {
      t.education_str = '고등학교졸';
    } else if (t.limits_education === '2') {
      t.education_str = '대학졸업(2,3년)';
    } else if (t.limits_education === '3') {
      t.education_str = '대학졸업(4년)';
    } else if (t.limits_education === '4') {
      t.education_str = '대학원 석사';
    } else if (t.limits_education === '5') {
      t.education_str = '대학원 박사';
    } else {
      t.education_str = '학력무관';
    }

    t.limits_preference = recruit.limits_preference; //우대
    t.job_tags = recruit.job_tags; // job_tags
    t.job_group = recruit.job_group; // job_group

    switch (t.job_group) {
      case '1':
      t.job_group_str = '경영·사무·관리';
      break;
      case '2':
      t.job_group_str = '마케팅·홍보·PR·광고';
      break; 
      case '3':
        t.job_group_str = 'IT·인터넷';
      break; 
      case '4':
        t.job_group_str = '디자인';
      break; 
      case '5':
        t.job_group_str = '영업·고객상담';
      break; 
      case '6':
        t.job_group_str = '유통·무역';
      break; 
      case '7':
        t.job_group_str = '연구·개발·설계';
      break; 
      case '8':
        t.job_group_str = '생산·제조';
      break; 
      case '9':
        t.job_group_str = '교육';
      break;
      case '10':
        t.job_group_str = '건설';
      break; 
      case '11':
        t.job_group_str = '금융';
      break; 
      case '12':
        t.job_group_str = '의료';
      break; 
      case '13':
        t.job_group_str = '조사·분석';
      break; 
      case '14':
        t.job_group_str = '서비스';
      break; 
      case '15':
        t.job_group_str = '문화·예술';
      break; 
      case '16':
        t.job_group_str = '전문·특수직';
      break;
      case '17':
        t.job_group_str = '기타';
      break;  
      default:
        t.job_group_str = '기타';
    }

    t.job_roll = recruit.job_roll; // job_roll
    switch (t.job_roll) {
      case '1':
      t.job_roll_str = '생산 (Production)';
      break;
      case '2':
        t.job_roll_str = '재무 (Financial)';
      break; 
      case '3':
        t.job_roll_str = '총무 (Administrator)';
      break; 
      case '4':
        t.job_roll_str = '품질관리 (Quality Control)';
      break; 
      case '5':
        t.job_roll_str = '사무 (Clerical work)';
      break; 
      case '6':
        t.job_roll_str = '정보보호 (Information Security)';
      break; 
      case '7':
        t.job_roll_str = 'IT·인터넷 (Information Technology)';
      break; 
      case '8':
        t.job_roll_str = '홍보 (Public Relation)';
      break; 
      case '9':
        t.job_roll_str = '마케팅 (Marketing)';
      break; 
      case '10':
        t.job_roll_str = '법무 (Judicial affairs)';
      break; 
      case '11':
        t.job_roll_str = '생산관리 (Production management)';
      break; 
      case '12':
        t.job_roll_str = '고객서비스 (Customer service)';
      break; 
      case '13':
        t.job_roll_str = '보안 (Security)';
      break; 
      case '14':
        t.job_roll_str = '비서 (Secretaray)';
      break; 
      case '15':
        t.job_roll_str = '영업 (Sales)';
      break; 
      case '16':
        t.job_roll_str = '노무 (Labor)';
      break; 
      case '17':
        t.job_roll_str = '시설관리 (Facility management)';
      break; 
      case '18':
        t.job_roll_str = '공정설계 (Process engineering)';
      break; 
      case '19':
        t.job_roll_str = '연구개발 (Research and Development)';
      break; 
      case '20':
        t.job_roll_str = '리스크 (Risk)';
      break; 
      case '21':
        t.job_roll_str = '구매 (Sourcing)';
      break; 
      case '22':
        t.job_roll_str = '인사 (Human resources)';
      break; 
      case '23':
        t.job_roll_str = '전략기획 (Strategy)';
      break; 
      case '24':
        t.job_roll_str = '환경안전 (Environmental Safety)';
      break; 
      case '25':
        t.job_roll_str = '물류 (Logistics)';
      break; 
      case '26':
        t.job_roll_str = '기타';
      break; 
      default:
        t.job_roll_str = '신기타입';
    }


    t.jobdescription = recruit.jobdescription; // 모집요강
    t.jobmainroll = recruit.jobmainroll; // 주요업무
    t.qualifications = recruit.qualifications; // 자격요건
    t.preferential_treatment = recruit.preferential_treatment; // 우대사항
    t.recruit_etc = recruit.recruit_etc; // 기타

    t.hrmanager_name = recruit.hrmanager_name;
    t.hrmanager_department = recruit.hrmanager_department;
    t.hrmanager_phone = recruit.hrmanager_phone;
    t.hrmanager_mobile = recruit.hrmanager_mobile;
    t.hrmanager_email = recruit.hrmanager_email;

    t.company_idx = recruit.company_idx; // 회사아이디
    t.company_name = recruit.company_name; // company_name
    t.company_welfare = recruit.company_welfare; // company_welfare
    t.company_explain = recruit.company_explain; // company_explain
    t.company_industry = recruit.company_industry; // company_industry
    t.company_size = recruit.company_size; // company_size
    t.company_website = recruit.company_website; // 회사홈페이지
    t.company_phone = recruit.company_phone; // 회사전화
    t.company_email = recruit.company_email; // 회사이메일

    t.recruit_status_code = recruit.recruit_status_code; // 
    t.recruit_status = recruit.recruit_status; // 

    t.cnt_apply = recruit.cnt_apply;

    t.notification = recruit.notification; // notification
    t.subscribe = recruit.subscribe; // subscribe
    t.like = recruit.like; // like

    t.read_date = recruit.read_date;
    t.first_read_date = recruit.reg_date;
    
    t.resume_userno = recruit.resume_userno;
    // t.my_resume_userno = recruit.my_resume_userno;
    
    r.push(t);  
  }
  return r;
}

// Resume List
export const convertResumeData = (resume_data) => {

  if(!resume_data) { return []; }
  let r = [];
  console.log('resume_data.length', resume_data.length);
  for (let i = 0; i < resume_data.length; i++) {
    let t = {},
    resume = resume_data[i];

    t.resume_idx = resume.resume_idx; //이력서 아이디
    t.status = resume.status; // 이력서 상태 0:비공개(비활성), 1:공개
    t.resume_status = resume.resume_status; //이력서 상태 0:구직의사 없음.(비활성), 1: 즉시입사가능, 2: 이직희망고려중, 3. 제안검토가능
    
    if(t.status === '1') {
      if (t.resume_status === '0') {
        t.resume_status_str = '구직의사 없음';
      } else if (t.resume_status === '1') {
        t.resume_status_str = '즉시입사가능';
      } else if (t.resume_status === '2') {
        t.resume_status_str = '이직고려중';
      } else if (t.resume_status === '3') {
        t.resume_status_str = '제안검토가능';
      }
    } else {
      t.resume_status_str = '구직의사 없음';
    }

    t.userno = resume.userno; //회원번호
    t.title = resume.title; //이력서 제목

    t.recruit_idx = resume.recruit_idx; //매칭된 공고 idx
    t.recruit_title = resume.recruit_title; //매칭된 공고제목
    t.date_match = resume.date_match; //매칭날짜

    t.photo_url = resume.photo_url; //사진 url
    t.name = resume.name; //이름
    t.birthyear = resume.birthyear; //출생년도
    t.gender = resume.gender; //성별
    
    t.user_first_name = resume.name.substring(0,1) + 'OO'; // 성
    // t.user_first_name = resume.name;
    t.user_birth = resume.user_birth; //생년월일
    t.user_birth_year = resume.user_birth_year; // 출생년도
    t.user_gender = resume.user_gender; //성별
    
    if (t.user_gender === 'M') {
      t.user_gender_str = '남';
    } else if (t.user_gender === 'F') {
      t.user_gender_str = '여';
    }

    t.user_email = resume.user_email; //이메일
    t.user_phone = resume.user_phone; //전화번호
    t.user_mobile = resume.user_mobile; //휴대전화
    t.user_address = resume.user_address; //주소

    t.user_address_array = t.user_address.split(' ');
    t.user_address_s = t.user_address_array[0]+" "+t.user_address_array[1];

    t.user_last_school = resume.user_last_school; //최종학교
    t.user_final_education = resume.user_final_education; // 최종학력

    switch (t.user_final_education) {
      case '1':
      t.final_education_str = '고등학교졸업';
      break;
      case '2':
      t.final_education_str = '대햑교(2,3)졸업';
      break; 
      case '3':
      t.final_education_str = '대학교(4)졸업';
      break; 
      case '4':
      t.final_education_str = '석사학위';
      break; 
      case '5':
      t.final_education_str = '박사학위';
      break; 
      default:
      t.final_education_str = '기타';
    }

    t.user_total_exp = resume.user_total_exp; // 총경력
    switch (t.user_total_exp) {
      case '1':
      t.user_total_exp_str = '신입';
      break;
      case '2':
        t.user_total_exp_str = '1년 ~ 3년 이하';
      break; 
      case '3':
        t.user_total_exp_str = '3년 ~ 6년 이하';
      break; 
      case '4':
        t.user_total_exp_str = '6년 ~ 10년 이하';
      break; 
      case '5':
        t.user_total_exp_str = '10년 ~ 15년 이하';
      break; 
      case '6':
        t.user_total_exp_str = '15년 ~ 20년 이하';
      break; 
      case '7':
        t.user_total_exp_str = '20년 ~ 25년 이하';
      break; 
      case '8':
        t.user_total_exp_str = '25년 ~ 30년 이하';
      break; 
      case '9':
        t.user_total_exp_str = '30년 이상';
      break; 
      default:
        t.user_total_exp_str = '신입';
    }

    t.user_job_roll = resume.user_job_roll; // 직무t.user_job_roll = myinfo.user_job_roll;
    switch (t.user_job_roll) {
      case '1':
      t.user_job_roll_str = '생산 (Production)';
      break;
      case '2':
        t.user_job_roll_str = '재무 (Financial)';
      break; 
      case '3':
        t.user_job_roll_str = '총무 (Administrator)';
      break; 
      case '4':
        t.user_job_roll_str = '품질관리 (Quality Control)';
      break; 
      case '5':
        t.user_job_roll_str = '사무 (Clerical work)';
      break; 
      case '6':
        t.user_job_roll_str = '정보보호 (Information Security)';
      break; 
      case '7':
        t.user_job_roll_str = 'IT·인터넷 (Information Technology)';
      break; 
      case '8':
        t.user_job_roll_str = '홍보 (Public Relation)';
      break; 
      case '9':
        t.user_job_roll_str = '마케팅 (Marketing)';
      break; 
      case '10':
        t.user_job_roll_str = '법무 (Judicial affairs)';
      break; 
      case '11':
        t.user_job_roll_str = '생산관리 (Production management)';
      break; 
      case '12':
        t.user_job_roll_str = '고객서비스 (Customer service)';
      break; 
      case '13':
        t.user_job_roll_str = '보안 (Security)';
      break; 
      case '14':
        t.user_job_roll_str = '비서 (Secretaray)';
      break; 
      case '15':
        t.user_job_roll_str = '영업 (Sales)';
      break; 
      case '16':
        t.user_job_roll_str = '노무 (Labor)';
      break; 
      case '17':
        t.user_job_roll_str = '시설관리 (Facility management)';
      break; 
      case '18':
        t.user_job_roll_str = '공정설계 (Process engineering)';
      break; 
      case '19':
        t.user_job_roll_str = '연구개발 (Research and Development)';
      break; 
      case '20':
        t.user_job_roll_str = '리스크 (Risk)';
      break; 
      case '21':
        t.user_job_roll_str = '구매 (Sourcing)';
      break; 
      case '22':
        t.user_job_roll_str = '인사 (Human resources)';
      break; 
      case '23':
        t.user_job_roll_str = '전략기획 (Strategy)';
      break; 
      case '24':
        t.user_job_roll_str = '환경안전 (Environmental Safety)';
      break; 
      case '25':
        t.user_job_roll_str = '물류 (Logistics)';
      break; 
      case '26':
        t.user_job_roll_str = '기타';
      break; 
      default:
        t.user_job_roll_str = '신기타입';
    }

    t.wish_employment_type = resume.wish_employment_type; // 고용형태. 콤마로 구분 1:정규직, 2:계약직, 3.병역특례, 4:프리랜서, 5:헤드헌팅, 6.파견대행, 7:인턴직

    switch (t.wish_employment_type) {
      case '1':
      t.wish_employment_str = '정규직';
      break;
      case '2':
      t.wish_employment_str = '계약직';
      break; 
      case '3':
      t.wish_employment_str = '병역특례';
      break; 
      case '4':
      t.wish_employment_str = '프리랜서';
      break; 
      case '5':
      t.wish_employment_str = '헤드헌팅';
      break; 
      case '6':
      t.wish_employment_str = '파견대행';
      break; 
      case '7':
      t.wish_employment_str = '인턴직';
      break; 
      default:
      t.wish_employment_str = '정규직';
    }

    t.now_pay_amount = resume.now_pay_amount; //현재연봉
    t.merit_pay_amount = resume.merit_pay_amount; //성과급
    t.wish_pay_amount = resume.wish_pay_amount; //희망연봉
    t.wish_company_location = resume.wish_company_location; //희망근무지. 콤마로 구분
    t.wish_job = resume.wish_job; //희망직무. 콤마로 구분
    t.website = resume.website; //홈페이지
    t.cnt_view = resume.cnt_view; //cnt_view
    t.rating = resume.rating; // rating
    t.max_rating = resume.max_rating; // max rating
    t.percentage = (resume.rating / resume.max_rating) * 100; //%
    
    t.star = Math.round((t.rating*5)/t.max_rating);
    
    t.regdate = resume.regdate; //등록일
    t.moddate = resume.moddate; //수정일
    t.match_date = resume.match_date; //마지막 매칭 날짜
    t.company_name = resume.company_name; //company_name
    t.department_name = resume.department_name; //department_name
    t.job_title = resume.job_title; //job_title

    t.show_type = resume.show_type; // show_type
    t.show_status = resume.show_status; // show_status - 풀이력서

    // js.company_idx, js.resume_idx as suggest_resume_idx, js.resume_userno as suggest_resume_userno, js.suggest_type, js.status 
    t.company_idx_ = resume.company_idx; // company_idx

    t.suggest_resume_idx = resume.suggest_resume_idx; // suggest_resume_idx
    t.suggest_resume_userno = resume.suggest_resume_userno; // suggest_resume_userno
    t.suggest_type = resume.suggest_type; // suggest_type
    t.suggest_title = resume.suggest_title; // suggest_title
    t.suggest_message = resume.suggest_message; // suggest_message

    t.date_suggest = resume.date_suggest; // date_suggest
    t.date_suggest = t.date_suggest?moment(t.date_suggest).format("YYYY-MM-DD"):'-'; // date_suggest

    t.date_view = resume.date_view; // date_view
    t.date_view = t.date_view?moment(t.date_view).format("YYYY-MM-DD"):'-'; // date_view

    t.date_agree = resume.date_agree; // date_agree
    t.date_agree = t.date_agree?moment(t.date_agree).format("YYYY-MM-DD"):'-'; // date_agree

    t.date_refuse = resume.date_refuse; // date_refuse
    t.date_refuse = t.date_refuse?moment(t.date_refuse).format("YYYY-MM-DD"):'-'; // date_agree

    t.status = resume.status; // status

    t.date_apply = resume.date_apply;
    t.date_apply = t.date_apply?moment(t.date_apply).format("YYYY-MM-DD"):'-'; // "13:16(19-11-07)";; // 지원날짜
    t.date_view = resume.date_view; // 열람날짜
    t.date_view = t.date_view?moment(resume.date_view).format("YYYY-MM-DD"):'-'; // 열람날짜
    t.date_spare_pass = resume.date_spare_pass; // 예비합격날짜
    t.date_spare_pass = t.date_spare_pass?moment(resume.date_spare_pass).format("YYYY-MM-DD"):'-'; // 예비합격날짜
    t.date_document_pass = resume.date_document_pass; // 서류합격날짜
    t.date_document_pass = t.date_document_pass?moment(resume.date_document_pass).format("YYYY-MM-DD"):'-'; // 서류합격날짜
    t.date_final_pass = resume.date_final_pass; // 최종합격날짜
    t.date_final_pass = t.date_final_pass?moment(resume.date_final_pass).format("YYYY-MM-DD"):'-'; // 최종합격날짜



    t.notification = resume.notification; // notification
    t.subscribe = resume.subscribe; // subscribe
    t.like = resume.like; // like

    t.tot_exp_month = resume.tot_exp_month; // 실제 이력서상 경력
    t.real_exp_year = parseInt(t.tot_exp_month / 12);
    t.real_exp_month =  t.tot_exp_month % 12;
    t.real_exp_tot = (t.real_exp_year>0?t.real_exp_year+'년':'...')+(t.real_exp_month>0?' '+t.real_exp_month+'개월':'');


    t.career = [];
    let career = resume.career;
    if(career) {
        for (let g = 0; g < career.length; g++) {
            let my_career = career[g];
            my_career.exp_year = parseInt(my_career.exp_mon / 12);
            my_career.exp_month = my_career.exp_mon % 12;
            my_career.exp_mon = (my_career.exp_year>0?my_career.exp_year+'년':'...')+(my_career.exp_month>0?' '+my_career.exp_month+'개월':'');

          t.career.push({
            'idx':my_career.idx,
            'resume_idx':my_career.resume_idx, 
            'status':my_career.status, 
            'company_name':my_career.company_name, 
            'company_name_logo':my_career.company_name.substring(0,2),
            'department_name':my_career.department_name, 
            'date_join':my_career.date_join, 
            'date_retire':my_career.date_retire, 
            'job_title':my_career.job_title, 
            'pay_amount':my_career.pay_amount, 
            'job_in_charge':my_career.job_in_charge, 
            'exp_mon':my_career.exp_mon, 
          });
        }
    }
    t.career_list = t.career;

    t.certificate = [];
    let certificate = resume.certificate;
    if(certificate) {
      for(let j=0; j<certificate.length; j++) {
        let my_cert = certificate[j];
        t.certificate.push({
          'idx':my_cert.idx,
          'sort_no':my_cert.sort_no,
          'certificate_name':my_cert.certificate_name, // 자격증명
          'certificate_issuer':my_cert.certificate_issuer, // 벌행처
          'date_receive':my_cert.date_receive, // 최득일
        });
      }
    }
    t.certificate_list = t.certificate;

    t.coreability = [];
    let coreability = resume.coreability;
    if(coreability) {
      for(let m=0; m<coreability.length; m++) {
        let my_ability = coreability[m];
        t.coreability.push({
          'idx':my_ability.idx,
          'sort_no':my_ability.sort_no,
          'ability_title':my_ability.ability_title, // 핵심역량 제목
          'ability_contents':my_ability.ability_contents, // 핵심역량 내용
        });
      }
    }
    t.coreability_list = t.coreability;

    t.education = [];
    let education = resume.education;
    if(education) {
      for(let l=0; l<education.length; l++) {
        let my_edu = education[l];
        t.education.push({
          'idx':my_edu.idx,
          'sort_no':my_edu.sort_no,
          'school_location':my_edu.school_location, // 1:국내, 2:해외
          'school_type':my_edu.school_type, // 학교구분(1:고등학교, 2:대학(2,3년), 3:대학교(4년), 4:대학원석사, 5:대학원박사)
          'school_name':my_edu.school_name, // 학교이름
          'major':my_edu.major, //학과(전공)
          'date_admission':my_edu.date_admission, //입학날짜
          'date_graduation':my_edu.date_graduation, // 졸업날짜
          'status':my_edu.status, //상태(1:졸업, 2:입학, 3:재학중, 4;휴학중, 5:자퇴, 6:재적)
          'grade':my_edu.grade, // 학점(4.14)
          'grade_total':my_edu.grade_total, // 총점(4.5, 4.3, 4.0, 100)
        });
      }
    }
    t.education_list = t.education;

    t.language = [];
    let language = resume.language;
    if(language) {
      for(let i=0; i<language.length; i++) {
        let my_lang = language[i];
        t.language.push({
          'idx':my_lang.idx,
          'sort_no':my_lang.sort_no,
          'code':my_lang.code,
          'language':my_lang.language,
          'language_name':my_lang.language_name,
          'level':my_lang.level,
        });
      }
    }
    t.language_list = t.language;
    
    t.portfolio = [];
    let portfolio = resume.portfolio;
    if(portfolio) {
      for(let k=0; k<portfolio.length; k++) {
        let my_pf = portfolio[k];
        t.portfolio.push({
          'idx':my_pf.idx,
          'sort_no':my_pf.sort_no,
          'portfolio_url':my_pf.portfolio_url,
          'portfolio_link':my_pf.portfolio_link,
          'portfolio_title':my_pf.portfolio_title,
          'portfolio_contents':my_pf.portfolio_contents,
        });
      }
    }
    t.portfolio_list = t.portfolio;
    
    t.profile = [];
    let profile = resume.profile;
    if(profile) {
      for(let h=0; h<profile.length; h++) {
        let my_profile = profile[h];
        t.profile.push({
          'idx':my_profile.idx,
          'sort_no':my_profile.sort_no,
          'profile_title':my_profile.profile_title,
          'profile_contents':my_profile.profile_contents,
        });
      }
    }
    t.profile_list = t.profile;
    
    t.wish_company = [];
    let wish_company = resume.wish_company;
    if(wish_company) {
      for(let h=0; h<wish_company.length; h++) {
        let my_wishcompany = wish_company[h];
        t.wish_company.push({
          'idx':my_wishcompany.idx,
          'sort_no':my_wishcompany.sort_no,
          'resume_idx':my_wishcompany.resume_idx,
          'company_idx':my_wishcompany.company_idx,
          'company_name':my_wishcompany.company_name,
        });
      }
    }
    t.wish_company_list = t.wish_company;


    t.wish_headhunter = [];
    let wish_headhunter = resume.wish_headhunter;
    if(wish_headhunter) {
      for(let h=0; h<wish_headhunter.length; h++) {
        let my_wishheadhunter = wish_headhunter[h];
        t.wish_headhunter.push({
          'idx':my_wishheadhunter.idx,
          'sort_no':my_wishheadhunter.sort_no,
          'resume_idx':my_wishheadhunter.resume_idx,
          'headhunter_idx':my_wishheadhunter.headhunter_idx,
          'headhunter_name':my_wishheadhunter.headhunter_name,
        });
      }
    }
    t.wish_headhunter_list = t.wish_headhunter;

    // t.suggest = [];
    // let suggest = resume.suggest;
    // if(suggest) {
    //   for(let h=0; h<suggest.length; h++) {
    //     let my_suggest = suggest[h];
    //     t.suggest.push({
    //       'company_idx':my_suggest.company_idx,
    //       'suggest_type':my_suggest.suggest_type,
    //       'suggest_title':my_suggest.suggest_title,
    //       'suggest_message':my_suggest.suggest_message,
    //       'date_suggest':my_suggest.date_suggest,
    //       'date_view':my_suggest.date_view,
    //       'date_agree':my_suggest.date_agree,
    //       'date_refuse':my_suggest.date_refuse,
    //       'status':my_suggest.status,
    //     });
    //   }
    // }
    // t.suggest_list = t.suggest;
    
    r.push(t);  
  }
  return r;
}

// Resume List
export const convertSuggestResumeData = (suggest_data) => {

  if(!suggest_data) { return []; }
  let r = [];
  console.log('suggest_data.length', suggest_data.length);
  for (let i = 0; i < suggest_data.length; i++) {
    let t = {},
    resume = suggest_data[i];

    t.resume_idx = resume.resume_idx; //이력서 아이디
    t.status = resume.status; // 이력서 상태 0:비공개(비활성), 1:공개
    t.resume_status = resume.resume_status; //이력서 상태 0:구직의사 없음.(비활성), 1: 즉시입사가능, 2: 이직희망고려중, 3. 제안검토가능
    
    if(t.status === '1') {
      if (t.resume_status === '0') {
        t.resume_status_str = '구직의사 없음';
      } else if (t.resume_status === '1') {
        t.resume_status_str = '즉시입사가능';
      } else if (t.resume_status === '2') {
        t.resume_status_str = '이직고려중';
      } else if (t.resume_status === '3') {
        t.resume_status_str = '제안검토가능';
      }
    } else {
      t.resume_status_str = '구직의사 없음';
    }

    t.userno = resume.userno; //회원번호
    t.title = resume.title; //이력서 제목

    t.recruit_idx = resume.recruit_idx; //매칭된 공고 idx
    t.recruit_title = resume.recruit_title; //매칭된 공고제목
    t.date_match = resume.date_match; //매칭날짜

    t.photo_url = resume.photo_url; //사진 url
    t.name = resume.name; //이름
    t.birthyear = resume.birthyear; //출생년도
    t.gender = resume.gender; //성별

    t.user_first_name = resume.name.substring(0,1) + 'OO'; // 성
    // t.user_first_name = resume.name;
    t.user_birth = resume.user_birth; //생년월일
    t.user_birth_year = resume.user_birth_year; // 출생년도
    t.user_gender = resume.user_gender; //성별
    
    if (t.user_gender === 'M') {
      t.user_gender_str = '남';
    } else if (t.user_gender === 'F') {
      t.user_gender_str = '여';
    }

    t.user_email = resume.user_email; //이메일
    t.user_phone = resume.user_phone; //전화번호
    t.user_mobile = resume.user_mobile; //휴대전화
    t.user_address = resume.user_address; //주소

    t.user_address_array = t.user_address.split(' ');
    t.user_address_s = t.user_address_array[0]+" "+t.user_address_array[1];

    t.user_last_school = resume.user_last_school; //최종학교
    t.user_final_education = resume.user_final_education; // 최종학력

    switch (t.user_final_education) {
      case '1':
      t.final_education_str = '고등학교졸업';
      break;
      case '2':
      t.final_education_str = '대햑교(2,3)졸업';
      break; 
      case '3':
      t.final_education_str = '대학교(4)졸업';
      break; 
      case '4':
      t.final_education_str = '석사학위';
      break; 
      case '5':
      t.final_education_str = '박사학위';
      break; 
      default:
      t.final_education_str = '기타';
    }

    t.user_total_exp = resume.user_total_exp; // 총경력
    switch (t.user_total_exp) {
      case '1':
      t.user_total_exp_str = '신입';
      break;
      case '2':
        t.user_total_exp_str = '1년 ~ 3년 이하';
      break; 
      case '3':
        t.user_total_exp_str = '3년 ~ 6년 이하';
      break; 
      case '4':
        t.user_total_exp_str = '6년 ~ 10년 이하';
      break; 
      case '5':
        t.user_total_exp_str = '10년 ~ 15년 이하';
      break; 
      case '6':
        t.user_total_exp_str = '15년 ~ 20년 이하';
      break; 
      case '7':
        t.user_total_exp_str = '20년 ~ 25년 이하';
      break; 
      case '8':
        t.user_total_exp_str = '25년 ~ 30년 이하';
      break; 
      case '9':
        t.user_total_exp_str = '30년 이상';
      break; 
      default:
        t.user_total_exp_str = '신입';
    }

    t.user_job_roll = resume.user_job_roll; // 직무t.user_job_roll = myinfo.user_job_roll;
    switch (t.user_job_roll) {
      case '1':
      t.user_job_roll_str = '생산 (Production)';
      break;
      case '2':
        t.user_job_roll_str = '재무 (Financial)';
      break; 
      case '3':
        t.user_job_roll_str = '총무 (Administrator)';
      break; 
      case '4':
        t.user_job_roll_str = '품질관리 (Quality Control)';
      break; 
      case '5':
        t.user_job_roll_str = '사무 (Clerical work)';
      break; 
      case '6':
        t.user_job_roll_str = '정보보호 (Information Security)';
      break; 
      case '7':
        t.user_job_roll_str = 'IT·인터넷 (Information Technology)';
      break; 
      case '8':
        t.user_job_roll_str = '홍보 (Public Relation)';
      break; 
      case '9':
        t.user_job_roll_str = '마케팅 (Marketing)';
      break; 
      case '10':
        t.user_job_roll_str = '법무 (Judicial affairs)';
      break; 
      case '11':
        t.user_job_roll_str = '생산관리 (Production management)';
      break; 
      case '12':
        t.user_job_roll_str = '고객서비스 (Customer service)';
      break; 
      case '13':
        t.user_job_roll_str = '보안 (Security)';
      break; 
      case '14':
        t.user_job_roll_str = '비서 (Secretaray)';
      break; 
      case '15':
        t.user_job_roll_str = '영업 (Sales)';
      break; 
      case '16':
        t.user_job_roll_str = '노무 (Labor)';
      break; 
      case '17':
        t.user_job_roll_str = '시설관리 (Facility management)';
      break; 
      case '18':
        t.user_job_roll_str = '공정설계 (Process engineering)';
      break; 
      case '19':
        t.user_job_roll_str = '연구개발 (Research and Development)';
      break; 
      case '20':
        t.user_job_roll_str = '리스크 (Risk)';
      break; 
      case '21':
        t.user_job_roll_str = '구매 (Sourcing)';
      break; 
      case '22':
        t.user_job_roll_str = '인사 (Human resources)';
      break; 
      case '23':
        t.user_job_roll_str = '전략기획 (Strategy)';
      break; 
      case '24':
        t.user_job_roll_str = '환경안전 (Environmental Safety)';
      break; 
      case '25':
        t.user_job_roll_str = '물류 (Logistics)';
      break; 
      case '26':
        t.user_job_roll_str = '기타';
      break; 
      default:
        t.user_job_roll_str = '신기타입';
    }

    t.wish_employment_type = resume.wish_employment_type; // 고용형태. 콤마로 구분 1:정규직, 2:계약직, 3.병역특례, 4:프리랜서, 5:헤드헌팅, 6.파견대행, 7:인턴직

    switch (t.wish_employment_type) {
      case '1':
      t.wish_employment_str = '정규직';
      break;
      case '2':
      t.wish_employment_str = '계약직';
      break; 
      case '3':
      t.wish_employment_str = '병역특례';
      break; 
      case '4':
      t.wish_employment_str = '프리랜서';
      break; 
      case '5':
      t.wish_employment_str = '헤드헌팅';
      break; 
      case '6':
      t.wish_employment_str = '파견대행';
      break; 
      case '7':
      t.wish_employment_str = '인턴직';
      break; 
      default:
      t.wish_employment_str = '정규직';
    }

    t.now_pay_amount = resume.now_pay_amount; //현재연봉
    t.merit_pay_amount = resume.merit_pay_amount; //성과급
    t.wish_pay_amount = resume.wish_pay_amount; //희망연봉
    t.wish_company_location = resume.wish_company_location; //희망근무지. 콤마로 구분
    t.wish_job = resume.wish_job; //희망직무. 콤마로 구분
    t.website = resume.website; //홈페이지
    t.cnt_view = resume.cnt_view; //cnt_view
    t.rating = resume.rating; // rating
    t.max_rating = resume.max_rating; // max rating
    t.percentage = (resume.rating / resume.max_rating) * 100; //%
    
    t.star = Math.round((t.rating*5)/t.max_rating);
    
    t.regdate = resume.regdate; //등록일
    t.moddate = resume.moddate; //수정일
    t.match_date = resume.match_date; //마지막 매칭 날짜
    t.company_name = resume.company_name; //company_name
    t.department_name = resume.department_name; //department_name
    t.job_title = resume.job_title; //job_title

    t.show_type = resume.show_type; // show_type
    t.show_status = resume.show_status; // show_status - 풀이력서

    // js.company_idx, js.resume_idx as suggest_resume_idx, js.resume_userno as suggest_resume_userno, js.suggest_type, js.status 
    t.company_idx_ = resume.company_idx; // company_idx

    t.suggest_resume_idx = resume.suggest_resume_idx; // suggest_resume_idx
    t.suggest_resume_userno = resume.suggest_resume_userno; // suggest_resume_userno
    t.suggest_type = resume.suggest_type; // suggest_type
    t.suggest_title = resume.suggest_title; // suggest_title
    t.suggest_message = resume.suggest_message; // suggest_message

    t.date_suggest = resume.date_suggest; // date_suggest
    t.date_suggest = t.date_suggest?moment(t.date_suggest).format("YYYY-MM-DD"):'-'; // date_suggest

    t.date_view = resume.date_view; // date_view
    t.date_view = t.date_view?moment(t.date_view).format("YYYY-MM-DD"):'-'; // date_view

    t.date_agree = resume.date_agree; // date_agree
    t.date_agree = t.date_agree?moment(t.date_agree).format("YYYY-MM-DD"):'-'; // date_agree

    t.date_refuse = resume.date_refuse; // date_refuse
    t.date_refuse = t.date_refuse?moment(t.date_refuse).format("YYYY-MM-DD"):'-'; // date_agree

    t.status = resume.status; // status


    t.notification = resume.notification; // notification
    t.subscribe = resume.subscribe; // subscribe
    t.like = resume.like; // like

    t.tot_exp_month = resume.tot_exp_month; // 실제 이력서상 경력
    t.real_exp_year = parseInt(t.tot_exp_month / 12);
    t.real_exp_month =  t.tot_exp_month % 12;
    t.real_exp_tot = (t.real_exp_year>0?t.real_exp_year+'년':'...')+(t.real_exp_month>0?' '+t.real_exp_month+'개월':'');


    t.career = [];
    let career = resume.career;
    if(career) {
        for (let g = 0; g < career.length; g++) {
            let my_career = career[g];
            my_career.exp_year = parseInt(my_career.exp_mon / 12);
            my_career.exp_month = my_career.exp_mon % 12;
            my_career.exp_mon = (my_career.exp_year>0?my_career.exp_year+'년':'...')+(my_career.exp_month>0?' '+my_career.exp_month+'개월':'');

          t.career.push({
            'idx':my_career.idx,
            'resume_idx':my_career.resume_idx, 
            'status':my_career.status, 
            'company_name':my_career.company_name, 
            // 'company_name_logo':my_career.company_name.substring(0,2),
            'department_name':my_career.department_name, 
            'date_join':my_career.date_join, 
            'date_retire':my_career.date_retire, 
            'job_title':my_career.job_title, 
            'pay_amount':my_career.pay_amount, 
            'job_in_charge':my_career.job_in_charge, 
            'exp_mon':my_career.exp_mon, 
          });
        }
    }
    t.career_list = t.career;

    t.certificate = [];
    let certificate = resume.certificate;
    if(certificate) {
      for(let j=0; j<certificate.length; j++) {
        let my_cert = certificate[j];
        t.certificate.push({
          'idx':my_cert.idx,
          'sort_no':my_cert.sort_no,
          'certificate_name':my_cert.certificate_name, // 자격증명
          'certificate_issuer':my_cert.certificate_issuer, // 벌행처
          'date_receive':my_cert.date_receive, // 최득일
        });
      }
    }
    t.certificate_list = t.certificate;

    t.coreability = [];
    let coreability = resume.coreability;
    if(coreability) {
      for(let m=0; m<coreability.length; m++) {
        let my_ability = coreability[m];
        t.coreability.push({
          'idx':my_ability.idx,
          'sort_no':my_ability.sort_no,
          'ability_title':my_ability.ability_title, // 핵심역량 제목
          'ability_contents':my_ability.ability_contents, // 핵심역량 내용
        });
      }
    }
    t.coreability_list = t.coreability;

    t.education = [];
    let education = resume.education;
    if(education) {
      for(let l=0; l<education.length; l++) {
        let my_edu = education[l];
        t.education.push({
          'idx':my_edu.idx,
          'sort_no':my_edu.sort_no,
          'school_location':my_edu.school_location, // 1:국내, 2:해외
          'school_type':my_edu.school_type, // 학교구분(1:고등학교, 2:대학(2,3년), 3:대학교(4년), 4:대학원석사, 5:대학원박사)
          'school_name':my_edu.school_name, // 학교이름
          'major':my_edu.major, //학과(전공)
          'date_admission':my_edu.date_admission, //입학날짜
          'date_graduation':my_edu.date_graduation, // 졸업날짜
          'status':my_edu.status, //상태(1:졸업, 2:입학, 3:재학중, 4;휴학중, 5:자퇴, 6:재적)
          'grade':my_edu.grade, // 학점(4.14)
          'grade_total':my_edu.grade_total, // 총점(4.5, 4.3, 4.0, 100)
        });
      }
    }
    t.education_list = t.education;

    t.language = [];
    let language = resume.language;
    if(language) {
      for(let i=0; i<language.length; i++) {
        let my_lang = language[i];
        t.language.push({
          'idx':my_lang.idx,
          'sort_no':my_lang.sort_no,
          'code':my_lang.code,
          'language':my_lang.language,
          'language_name':my_lang.language_name,
          'level':my_lang.level,
        });
      }
    }
    t.language_list = t.language;
    
    t.portfolio = [];
    let portfolio = resume.portfolio;
    if(portfolio) {
      for(let k=0; k<portfolio.length; k++) {
        let my_pf = portfolio[k];
        t.portfolio.push({
          'idx':my_pf.idx,
          'sort_no':my_pf.sort_no,
          'portfolio_url':my_pf.portfolio_url,
          'portfolio_link':my_pf.portfolio_link,
          'portfolio_title':my_pf.portfolio_title,
          'portfolio_contents':my_pf.portfolio_contents,
        });
      }
    }
    t.portfolio_list = t.portfolio;
    
    t.profile = [];
    let profile = resume.profile;
    if(profile) {
      for(let h=0; h<profile.length; h++) {
        let my_profile = profile[h];
        t.profile.push({
          'idx':my_profile.idx,
          'sort_no':my_profile.sort_no,
          'profile_title':my_profile.profile_title,
          'profile_contents':my_profile.profile_contents,
        });
      }
    }
    t.profile_list = t.profile;
    
    t.wish_company = [];
    let wish_company = resume.wish_company;
    if(wish_company) {
      for(let h=0; h<wish_company.length; h++) {
        let my_wishcompany = wish_company[h];
        t.wish_company.push({
          'idx':my_wishcompany.idx,
          'sort_no':my_wishcompany.sort_no,
          'resume_idx':my_wishcompany.resume_idx,
          'company_idx':my_wishcompany.company_idx,
          'company_name':my_wishcompany.company_name,
        });
      }
    }
    t.wish_company_list = t.wish_company;


    t.wish_headhunter = [];
    let wish_headhunter = resume.wish_headhunter;
    if(wish_headhunter) {
      for(let h=0; h<wish_headhunter.length; h++) {
        let my_wishheadhunter = wish_headhunter[h];
        t.wish_headhunter.push({
          'idx':my_wishheadhunter.idx,
          'sort_no':my_wishheadhunter.sort_no,
          'resume_idx':my_wishheadhunter.resume_idx,
          'headhunter_idx':my_wishheadhunter.headhunter_idx,
          'headhunter_name':my_wishheadhunter.headhunter_name,
        });
      }
    }
    t.wish_headhunter_list = t.wish_headhunter;

    // t.suggest = [];
    // let suggest = resume.suggest;
    // if(suggest) {
    //   for(let h=0; h<suggest.length; h++) {
    //     let my_suggest = suggest[h];
    //     t.suggest.push({
    //       'company_idx':my_suggest.company_idx,
    //       'suggest_type':my_suggest.suggest_type,
    //       'suggest_title':my_suggest.suggest_title,
    //       'suggest_message':my_suggest.suggest_message,
    //       'date_suggest':my_suggest.date_suggest,
    //       'date_view':my_suggest.date_view,
    //       'date_agree':my_suggest.date_agree,
    //       'date_refuse':my_suggest.date_refuse,
    //       'status':my_suggest.status,
    //     });
    //   }
    // }
    // t.suggest_list = t.suggest;
    
    r.push(t);  
  }
  return r;
}

// Resume List
export const convertResumeBriefData = (resume_brief) => {

  if(!resume_brief) { return []; }
  let r = [];
  for (let i = 0; i < resume_brief.length; i++) {
    let t = {},
    resume = resume_brief[i];

    t.resume_idx = resume.resume_idx; //이력서 아이디
    t.status = resume.status; // 이력서 상태 0:비공개(비활성), 1:공개
    t.resume_status = resume.resume_status; //이력서 상태 0:구직의사 없음.(비활성), 1: 즉시입사가능, 2: 이직희망고려중, 3. 제안검토가능
    
    if(t.status === '1') {
      if (t.resume_status === '0') {
        t.resume_status_str = '구직의사 없음';
      } else if (t.resume_status === '1') {
        t.resume_status_str = '즉시입사가능';
      } else if (t.resume_status === '2') {
        t.resume_status_str = '이직고려중';
      } else if (t.resume_status === '3') {
        t.resume_status_str = '제안검토가능';
      }
    } else {
      t.resume_status_str = '구직의사 없음';
    }

    t.userno = resume.userno; //회원번호
    t.title = resume.title; //이력서 제목

    t.recruit_idx = resume.recruit_idx; //매칭된 공고 idx
    t.recruit_title = resume.recruit_title; //매칭된 공고제목
    t.date_match = resume.date_match; //매칭날짜

    t.photo_url = resume.photo_url; //사진 url
    t.name = resume.name; //이름
    t.birthyear = resume.birthyear; //출생년도
    t.gender = resume.gender; //성별

    t.user_first_name = resume.name.substring(0,1) + 'OO'; // 성
    // t.user_first_name = resume.name;
    // t.user_birth = resume.user_birth; //생년월일
    // t.user_birth_year = resume.user_birth_year; // 출생년도
    t.user_gender = resume.user_gender; //성별
    
    if (t.user_gender === 'M') {
      t.user_gender_str = '남';
    } else if (t.user_gender === 'F') {
      t.user_gender_str = '여';
    }

    t.user_email = resume.user_email; //이메일
    t.user_phone = resume.user_phone; //전화번호
    t.user_mobile = resume.user_mobile; //휴대전화
    t.user_address = resume.user_address; //주소

    t.user_address_array = t.user_address.split(' ');
    t.user_address_s = t.user_address_array[0]+" "+t.user_address_array[1];

    t.user_last_school = resume.user_last_school; //최종학교
    t.user_final_education = resume.user_final_education; // 최종학력

    switch (t.user_final_education) {
      case '1':
      t.final_education_str = '고등학교졸업';
      break;
      case '2':
      t.final_education_str = '대햑교(2,3)졸업';
      break; 
      case '3':
      t.final_education_str = '대학교(4)졸업';
      break; 
      case '4':
      t.final_education_str = '석사학위';
      break; 
      case '5':
      t.final_education_str = '박사학위';
      break; 
      default:
      t.final_education_str = '기타';
    }

    t.user_total_exp = resume.user_total_exp; // 총경력
    switch (t.user_total_exp) {
      case '1':
      t.user_total_exp_str = '신입';
      break;
      case '2':
        t.user_total_exp_str = '1년 ~ 3년 이하';
      break; 
      case '3':
        t.user_total_exp_str = '3년 ~ 6년 이하';
      break; 
      case '4':
        t.user_total_exp_str = '6년 ~ 10년 이하';
      break; 
      case '5':
        t.user_total_exp_str = '10년 ~ 15년 이하';
      break; 
      case '6':
        t.user_total_exp_str = '15년 ~ 20년 이하';
      break; 
      case '7':
        t.user_total_exp_str = '20년 ~ 25년 이하';
      break; 
      case '8':
        t.user_total_exp_str = '25년 ~ 30년 이하';
      break; 
      case '9':
        t.user_total_exp_str = '30년 이상';
      break; 
      default:
        t.user_total_exp_str = '신입';
    }

    t.user_job_roll = resume.user_job_roll; // 직무t.user_job_roll = myinfo.user_job_roll;
    switch (t.user_job_roll) {
      case '1':
      t.user_job_roll_str = '생산 (Production)';
      break;
      case '2':
        t.user_job_roll_str = '재무 (Financial)';
      break; 
      case '3':
        t.user_job_roll_str = '총무 (Administrator)';
      break; 
      case '4':
        t.user_job_roll_str = '품질관리 (Quality Control)';
      break; 
      case '5':
        t.user_job_roll_str = '사무 (Clerical work)';
      break; 
      case '6':
        t.user_job_roll_str = '정보보호 (Information Security)';
      break; 
      case '7':
        t.user_job_roll_str = 'IT·인터넷 (Information Technology)';
      break; 
      case '8':
        t.user_job_roll_str = '홍보 (Public Relation)';
      break; 
      case '9':
        t.user_job_roll_str = '마케팅 (Marketing)';
      break; 
      case '10':
        t.user_job_roll_str = '법무 (Judicial affairs)';
      break; 
      case '11':
        t.user_job_roll_str = '생산관리 (Production management)';
      break; 
      case '12':
        t.user_job_roll_str = '고객서비스 (Customer service)';
      break; 
      case '13':
        t.user_job_roll_str = '보안 (Security)';
      break; 
      case '14':
        t.user_job_roll_str = '비서 (Secretaray)';
      break; 
      case '15':
        t.user_job_roll_str = '영업 (Sales)';
      break; 
      case '16':
        t.user_job_roll_str = '노무 (Labor)';
      break; 
      case '17':
        t.user_job_roll_str = '시설관리 (Facility management)';
      break; 
      case '18':
        t.user_job_roll_str = '공정설계 (Process engineering)';
      break; 
      case '19':
        t.user_job_roll_str = '연구개발 (Research and Development)';
      break; 
      case '20':
        t.user_job_roll_str = '리스크 (Risk)';
      break; 
      case '21':
        t.user_job_roll_str = '구매 (Sourcing)';
      break; 
      case '22':
        t.user_job_roll_str = '인사 (Human resources)';
      break; 
      case '23':
        t.user_job_roll_str = '전략기획 (Strategy)';
      break; 
      case '24':
        t.user_job_roll_str = '환경안전 (Environmental Safety)';
      break; 
      case '25':
        t.user_job_roll_str = '물류 (Logistics)';
      break; 
      case '26':
        t.user_job_roll_str = '기타';
      break; 
      default:
        t.user_job_roll_str = '신기타입';
    }

    t.wish_employment_type = resume.wish_employment_type; // 고용형태. 콤마로 구분 1:정규직, 2:계약직, 3.병역특례, 4:프리랜서, 5:헤드헌팅, 6.파견대행, 7:인턴직

    switch (t.wish_employment_type) {
      case '1':
      t.wish_employment_str = '정규직';
      break;
      case '2':
      t.wish_employment_str = '계약직';
      break; 
      case '3':
      t.wish_employment_str = '병역특례';
      break; 
      case '4':
      t.wish_employment_str = '프리랜서';
      break; 
      case '5':
      t.wish_employment_str = '헤드헌팅';
      break; 
      case '6':
      t.wish_employment_str = '파견대행';
      break; 
      case '7':
      t.wish_employment_str = '인턴직';
      break; 
      default:
      t.wish_employment_str = '정규직';
    }

    t.now_pay_amount = resume.now_pay_amount; //현재연봉
    t.merit_pay_amount = resume.merit_pay_amount; //성과급
    t.wish_pay_amount = resume.wish_pay_amount; //희망연봉
    t.wish_company_location = resume.wish_company_location; //희망근무지. 콤마로 구분
    t.wish_job = resume.wish_job; //희망직무. 콤마로 구분
    t.website = resume.website; //홈페이지
    t.cnt_view = resume.cnt_view; //cnt_view
    t.rating = resume.rating; // rating
    t.max_rating = resume.max_rating; // max rating
    t.percentage = (resume.rating / resume.max_rating) * 100; //%
    
    t.star = Math.round((t.rating*5)/t.max_rating);
    
    t.regdate = resume.regdate; //등록일
    t.moddate = resume.moddate; //수정일
    t.update_date = resume.update_date; // 이력서 수정일 from js_member
    t.match_date = resume.match_date; //마지막 매칭 날짜
    t.company_name = resume.company_name; //company_name
    t.department_name = resume.department_name; //department_name
    t.job_title = resume.job_title; //job_title

    t.show_type = resume.show_type; // show_type
    t.show_status = resume.show_status; // show_status - 풀이력서

    t.company_idx_ = resume.company_idx; // company_idx
    t.suggest_type = resume.suggest_type; // suggest_type
    t.suggest_title = resume.suggest_title; // suggest_title
    t.suggest_message = resume.suggest_message; // suggest_message

    t.date_suggest = resume.date_suggest; // date_suggest
    t.date_suggest = t.date_suggest?moment(t.date_suggest).format("YYYY-MM-DD"):'-'; // date_suggest

    t.date_view = resume.date_view; // date_view
    t.date_view = t.date_view?moment(t.date_view).format("YYYY-MM-DD"):'-'; // date_view

    t.date_agree = resume.date_agree; // date_agree
    t.date_agree = t.date_agree?moment(t.date_agree).format("YYYY-MM-DD"):'-'; // date_agree

    t.date_refuse = resume.date_refuse; // date_refuse
    t.date_refuse = t.date_refuse?moment(t.date_refuse).format("YYYY-MM-DD"):'-'; // date_agree

    t.status = resume.status; // status


    t.notification = resume.notification; // notification
    t.subscribe = resume.subscribe; // subscribe
    t.like = resume.like; // like

    t.tot_exp_month = resume.tot_exp_month; // 실제 이력서상 경력
    t.real_exp_year = parseInt(t.tot_exp_month / 12);
    t.real_exp_month =  t.tot_exp_month % 12;
    t.real_exp_tot = (t.real_exp_year>0?t.real_exp_year+'년':'...')+(t.real_exp_month>0?' '+t.real_exp_month+'개월':'');


    t.career = [];
    let career = resume.career;
    if(career) {
        for (let g = 0; g < career.length; g++) {
            let my_career = career[g];
            my_career.exp_year = parseInt(my_career.exp_mon / 12);
            my_career.exp_month = my_career.exp_mon % 12;
            my_career.exp_mon = (my_career.exp_year>0?my_career.exp_year+'년':'...')+(my_career.exp_month>0?' '+my_career.exp_month+'개월':'');

          t.career.push({
            'idx':my_career.idx,
            'resume_idx':my_career.resume_idx, 
            'status':my_career.status, 
            'company_name':my_career.company_name, 
            // 'company_name_logo':my_career.company_name.substring(0,2),
            'department_name':my_career.department_name, 
            'date_join':my_career.date_join, 
            'date_retire':my_career.date_retire, 
            'job_title':my_career.job_title, 
            'pay_amount':my_career.pay_amount, 
            'job_in_charge':my_career.job_in_charge, 
            'exp_mon':my_career.exp_mon, 
          });
        }
    }
    t.career_list = t.career;

    t.certificate = [];
    let certificate = resume.certificate;
    if(certificate) {
      for(let j=0; j<certificate.length; j++) {
        let my_cert = certificate[j];
        t.certificate.push({
          'idx':my_cert.idx,
          'sort_no':my_cert.sort_no,
          'certificate_name':my_cert.certificate_name, // 자격증명
          'certificate_issuer':my_cert.certificate_issuer, // 벌행처
          'date_receive':my_cert.date_receive, // 최득일
        });
      }
    }
    t.certificate_list = t.certificate;

    t.coreability = [];
    let coreability = resume.coreability;
    if(coreability) {
      for(let m=0; m<coreability.length; m++) {
        let my_ability = coreability[m];
        t.coreability.push({
          'idx':my_ability.idx,
          'sort_no':my_ability.sort_no,
          'ability_title':my_ability.ability_title, // 핵심역량 제목
          'ability_contents':my_ability.ability_contents, // 핵심역량 내용
        });
      }
    }
    t.coreability_list = t.coreability;

    t.education = [];
    let education = resume.education;
    if(education) {
      for(let l=0; l<education.length; l++) {
        let my_edu = education[l];
        t.education.push({
          'idx':my_edu.idx,
          'sort_no':my_edu.sort_no,
          'school_location':my_edu.school_location, // 1:국내, 2:해외
          'school_type':my_edu.school_type, // 학교구분(1:고등학교, 2:대학(2,3년), 3:대학교(4년), 4:대학원석사, 5:대학원박사)
          'school_name':my_edu.school_name, // 학교이름
          'major':my_edu.major, //학과(전공)
          'date_admission':my_edu.date_admission, //입학날짜
          'date_graduation':my_edu.date_graduation, // 졸업날짜
          'status':my_edu.status, //상태(1:졸업, 2:입학, 3:재학중, 4;휴학중, 5:자퇴, 6:재적)
          'grade':my_edu.grade, // 학점(4.14)
          'grade_total':my_edu.grade_total, // 총점(4.5, 4.3, 4.0, 100)
        });
      }
    }
    t.education_list = t.education;

    t.language = [];
    let language = resume.language;
    if(language) {
      for(let i=0; i<language.length; i++) {
        let my_lang = language[i];
        t.language.push({
          'idx':my_lang.idx,
          'sort_no':my_lang.sort_no,
          'code':my_lang.code,
          'language':my_lang.language,
          'language_name':my_lang.language_name,
          'level':my_lang.level,
        });
      }
    }
    t.language_list = t.language;
    
    t.portfolio = [];
    let portfolio = resume.portfolio;
    if(portfolio) {
      for(let k=0; k<portfolio.length; k++) {
        let my_pf = portfolio[k];
        t.portfolio.push({
          'idx':my_pf.idx,
          'sort_no':my_pf.sort_no,
          'portfolio_url':my_pf.portfolio_url,
          'portfolio_link':my_pf.portfolio_link,
          'portfolio_title':my_pf.portfolio_title,
          'portfolio_contents':my_pf.portfolio_contents,
        });
      }
    }
    t.portfolio_list = t.portfolio;
    
    t.profile = [];
    let profile = resume.profile;
    if(profile) {
      for(let h=0; h<profile.length; h++) {
        let my_profile = profile[h];
        t.profile.push({
          'idx':my_profile.idx,
          'sort_no':my_profile.sort_no,
          'profile_title':my_profile.profile_title,
          'profile_contents':my_profile.profile_contents,
        });
      }
    }
    t.profile_list = t.profile;
    
    t.wish_company = [];
    let wish_company = resume.wish_company;
    if(wish_company) {
      for(let h=0; h<wish_company.length; h++) {
        let my_wishcompany = wish_company[h];
        t.wish_company.push({
          'idx':my_wishcompany.idx,
          'sort_no':my_wishcompany.sort_no,
          'resume_idx':my_wishcompany.resume_idx,
          'company_idx':my_wishcompany.company_idx,
          'company_name':my_wishcompany.company_name,
        });
      }
    }
    t.wish_company_list = t.wish_company;


    t.wish_headhunter = [];
    let wish_headhunter = resume.wish_headhunter;
    if(wish_headhunter) {
      for(let h=0; h<wish_headhunter.length; h++) {
        let my_wishheadhunter = wish_headhunter[h];
        t.wish_headhunter.push({
          'idx':my_wishheadhunter.idx,
          'sort_no':my_wishheadhunter.sort_no,
          'resume_idx':my_wishheadhunter.resume_idx,
          'headhunter_idx':my_wishheadhunter.headhunter_idx,
          'headhunter_name':my_wishheadhunter.headhunter_name,
        });
      }
    }
    t.wish_headhunter_list = t.wish_headhunter;

    // t.suggest = [];
    // let suggest = resume.suggest;
    // if(suggest) {
    //   for(let h=0; h<suggest.length; h++) {
    //     let my_suggest = suggest[h];
    //     t.suggest.push({
    //       'company_idx':my_suggest.company_idx,
    //       'suggest_type':my_suggest.suggest_type,
    //       'suggest_title':my_suggest.suggest_title,
    //       'suggest_message':my_suggest.suggest_message,
    //       'date_suggest':my_suggest.date_suggest,
    //       'date_view':my_suggest.date_view,
    //       'date_agree':my_suggest.date_agree,
    //       'date_refuse':my_suggest.date_refuse,
    //       'status':my_suggest.status,
    //     });
    //   }
    // }
    // t.suggest_list = t.suggest;
    
    r.push(t);  
  }
  return r;
}

// Resume List
export const convertResumeFullData = (resume_full) => {

  if(!resume_full) { return []; }
  let r = [];
  console.log('resume_data.length', resume_full.length);
  for (let i = 0; i < resume_full.length; i++) {
    let t = {},
    resume = resume_full[i];

    t.resume_idx = resume.resume_idx; //이력서 아이디
    t.status = resume.status; // 이력서 상태 0:비공개(비활성), 1:공개
    t.resume_status = resume.resume_status; //이력서 상태 0:구직의사 없음.(비활성), 1: 즉시입사가능, 2: 이직희망고려중, 3. 제안검토가능
    
    if(t.status === '1') {
      if (t.resume_status === '0') {
        t.resume_status_str = '구직의사 없음';
      } else if (t.resume_status === '1') {
        t.resume_status_str = '즉시입사가능';
      } else if (t.resume_status === '2') {
        t.resume_status_str = '이직고려중';
      } else if (t.resume_status === '3') {
        t.resume_status_str = '제안검토가능';
      }
    } else {
      t.resume_status_str = '구직의사 없음';
    }

    t.userno = resume.userno; //회원번호
    t.title = resume.title; //이력서 제목

    t.recruit_idx = resume.recruit_idx; //매칭된 공고 idx
    t.recruit_title = resume.recruit_title; //매칭된 공고제목
    t.date_match = resume.date_match; //매칭날짜

    t.photo_url = resume.photo_url; //사진 url
    t.name = resume.name; //이름
    t.birthyear = resume.birthyear; //출생년도
    t.gender = resume.gender; //성별
    
    t.user_first_name = resume.name.substring(0,1) + 'OO'; // 성
    // t.user_first_name = resume.name;
    t.user_birth = resume.user_birth; //생년월일
    t.user_birth_year = resume.user_birth_year; // 출생년도
    t.user_gender = resume.user_gender; //성별
    
    if (t.user_gender === 'M') {
      t.user_gender_str = '남';
    } else if (t.user_gender === 'F') {
      t.user_gender_str = '여';
    }

    t.user_email = resume.user_email; //이메일
    t.user_phone = resume.user_phone; //전화번호
    t.user_mobile = resume.user_mobile; //휴대전화
    t.user_address = resume.user_address; //주소

    t.user_address_array = t.user_address.split(' ');
    t.user_address_s = t.user_address_array[0]+" "+t.user_address_array[1];

    t.user_last_school = resume.user_last_school; //최종학교
    t.user_final_education = resume.user_final_education; // 최종학력

    switch (t.user_final_education) {
      case '1':
      t.final_education_str = '고등학교졸업';
      break;
      case '2':
      t.final_education_str = '대햑교(2,3)졸업';
      break; 
      case '3':
      t.final_education_str = '대학교(4)졸업';
      break; 
      case '4':
      t.final_education_str = '석사학위';
      break; 
      case '5':
      t.final_education_str = '박사학위';
      break; 
      default:
      t.final_education_str = '기타';
    }

    t.user_total_exp = resume.user_total_exp; // 총경력
    switch (t.user_total_exp) {
      case '1':
      t.user_total_exp_str = '신입';
      break;
      case '2':
        t.user_total_exp_str = '1년 ~ 3년 이하';
      break; 
      case '3':
        t.user_total_exp_str = '3년 ~ 6년 이하';
      break; 
      case '4':
        t.user_total_exp_str = '6년 ~ 10년 이하';
      break; 
      case '5':
        t.user_total_exp_str = '10년 ~ 15년 이하';
      break; 
      case '6':
        t.user_total_exp_str = '15년 ~ 20년 이하';
      break; 
      case '7':
        t.user_total_exp_str = '20년 ~ 25년 이하';
      break; 
      case '8':
        t.user_total_exp_str = '25년 ~ 30년 이하';
      break; 
      case '9':
        t.user_total_exp_str = '30년 이상';
      break; 
      default:
        t.user_total_exp_str = '신입';
    }

    t.user_job_roll = resume.user_job_roll; // 직무t.user_job_roll = myinfo.user_job_roll;
    switch (t.user_job_roll) {
      case '1':
      t.user_job_roll_str = '생산 (Production)';
      break;
      case '2':
        t.user_job_roll_str = '재무 (Financial)';
      break; 
      case '3':
        t.user_job_roll_str = '총무 (Administrator)';
      break; 
      case '4':
        t.user_job_roll_str = '품질관리 (Quality Control)';
      break; 
      case '5':
        t.user_job_roll_str = '사무 (Clerical work)';
      break; 
      case '6':
        t.user_job_roll_str = '정보보호 (Information Security)';
      break; 
      case '7':
        t.user_job_roll_str = 'IT·인터넷 (Information Technology)';
      break; 
      case '8':
        t.user_job_roll_str = '홍보 (Public Relation)';
      break; 
      case '9':
        t.user_job_roll_str = '마케팅 (Marketing)';
      break; 
      case '10':
        t.user_job_roll_str = '법무 (Judicial affairs)';
      break; 
      case '11':
        t.user_job_roll_str = '생산관리 (Production management)';
      break; 
      case '12':
        t.user_job_roll_str = '고객서비스 (Customer service)';
      break; 
      case '13':
        t.user_job_roll_str = '보안 (Security)';
      break; 
      case '14':
        t.user_job_roll_str = '비서 (Secretaray)';
      break; 
      case '15':
        t.user_job_roll_str = '영업 (Sales)';
      break; 
      case '16':
        t.user_job_roll_str = '노무 (Labor)';
      break; 
      case '17':
        t.user_job_roll_str = '시설관리 (Facility management)';
      break; 
      case '18':
        t.user_job_roll_str = '공정설계 (Process engineering)';
      break; 
      case '19':
        t.user_job_roll_str = '연구개발 (Research and Development)';
      break; 
      case '20':
        t.user_job_roll_str = '리스크 (Risk)';
      break; 
      case '21':
        t.user_job_roll_str = '구매 (Sourcing)';
      break; 
      case '22':
        t.user_job_roll_str = '인사 (Human resources)';
      break; 
      case '23':
        t.user_job_roll_str = '전략기획 (Strategy)';
      break; 
      case '24':
        t.user_job_roll_str = '환경안전 (Environmental Safety)';
      break; 
      case '25':
        t.user_job_roll_str = '물류 (Logistics)';
      break; 
      case '26':
        t.user_job_roll_str = '기타';
      break; 
      default:
        t.user_job_roll_str = '신기타입';
    }

    t.wish_employment_type = resume.wish_employment_type; // 고용형태. 콤마로 구분 1:정규직, 2:계약직, 3.병역특례, 4:프리랜서, 5:헤드헌팅, 6.파견대행, 7:인턴직

    switch (t.wish_employment_type) {
      case '1':
      t.wish_employment_str = '정규직';
      break;
      case '2':
      t.wish_employment_str = '계약직';
      break; 
      case '3':
      t.wish_employment_str = '병역특례';
      break; 
      case '4':
      t.wish_employment_str = '프리랜서';
      break; 
      case '5':
      t.wish_employment_str = '헤드헌팅';
      break; 
      case '6':
      t.wish_employment_str = '파견대행';
      break; 
      case '7':
      t.wish_employment_str = '인턴직';
      break; 
      default:
      t.wish_employment_str = '정규직';
    }

    t.now_pay_amount = resume.now_pay_amount; //현재연봉
    t.merit_pay_amount = resume.merit_pay_amount; //성과급
    t.wish_pay_amount = resume.wish_pay_amount; //희망연봉
    t.wish_company_location = resume.wish_company_location; //희망근무지. 콤마로 구분
    t.wish_job = resume.wish_job; //희망직무. 콤마로 구분
    t.website = resume.website; //홈페이지
    t.cnt_view = resume.cnt_view; //cnt_view
    t.rating = resume.rating; // rating
    t.max_rating = resume.max_rating; // max rating
    t.percentage = (resume.rating / resume.max_rating) * 100; //%
    
    t.star = Math.round((t.rating*5)/t.max_rating);
    
    t.regdate = resume.regdate; //등록일
    t.moddate = resume.moddate; //수정일
    t.update_date = resume.update_date; // 이력서 수정일 from js_member
    t.match_date = resume.match_date; //마지막 매칭 날짜
    t.company_name = resume.company_name; //company_name
    t.department_name = resume.department_name; //department_name
    t.job_title = resume.job_title; //job_title

    t.show_type = resume.show_type; // show_type
    t.show_status = resume.show_status; // show_status - 풀이력서

    t.company_idx_ = resume.company_idx; // company_idx
    t.suggest_type = resume.suggest_type; // suggest_type
    t.suggest_title = resume.suggest_title; // suggest_title
    t.suggest_message = resume.suggest_message; // suggest_message

    t.date_suggest = resume.date_suggest; // date_suggest
    t.date_suggest = t.date_suggest?moment(t.date_suggest).format("YYYY-MM-DD"):'-'; // date_suggest

    t.date_view = resume.date_view; // date_view
    t.date_view = t.date_view?moment(t.date_view).format("YYYY-MM-DD"):'-'; // date_view

    t.date_agree = resume.date_agree; // date_agree
    t.date_agree = t.date_agree?moment(t.date_agree).format("YYYY-MM-DD"):'-'; // date_agree

    t.date_refuse = resume.date_refuse; // date_refuse
    t.date_refuse = t.date_refuse?moment(t.date_refuse).format("YYYY-MM-DD"):'-'; // date_agree

    t.status = resume.status; // status


    t.notification = resume.notification; // notification
    t.subscribe = resume.subscribe; // subscribe
    t.like = resume.like; // like

    t.tot_exp_month = resume.tot_exp_month; // 실제 이력서상 경력
    t.real_exp_year = parseInt(t.tot_exp_month / 12);
    t.real_exp_month =  t.tot_exp_month % 12;
    t.real_exp_tot = (t.real_exp_year>0?t.real_exp_year+'년':'...')+(t.real_exp_month>0?' '+t.real_exp_month+'개월':'');


    t.career = [];
    let career = resume.career;
    if(career) {
        for (let g = 0; g < career.length; g++) {
            let my_career = career[g];
            my_career.exp_year = parseInt(my_career.exp_mon / 12);
            my_career.exp_month = my_career.exp_mon % 12;
            my_career.exp_mon = (my_career.exp_year>0?my_career.exp_year+'년':'...')+(my_career.exp_month>0?' '+my_career.exp_month+'개월':'');

          t.career.push({
            'idx':my_career.idx,
            'resume_idx':my_career.resume_idx, 
            'status':my_career.status, 
            'company_name':my_career.company_name, 
            // 'company_name_logo':my_career.company_name.substring(0,2),
            'department_name':my_career.department_name, 
            'date_join':my_career.date_join, 
            'date_retire':my_career.date_retire, 
            'job_title':my_career.job_title, 
            'pay_amount':my_career.pay_amount, 
            'job_in_charge':my_career.job_in_charge, 
            'exp_mon':my_career.exp_mon, 
          });
        }
    }
    t.career_list = t.career;

    t.certificate = [];
    let certificate = resume.certificate;
    if(certificate) {
      for(let j=0; j<certificate.length; j++) {
        let my_cert = certificate[j];
        t.certificate.push({
          'idx':my_cert.idx,
          'sort_no':my_cert.sort_no,
          'certificate_name':my_cert.certificate_name, // 자격증명
          'certificate_issuer':my_cert.certificate_issuer, // 벌행처
          'date_receive':my_cert.date_receive, // 최득일
        });
      }
    }
    t.certificate_list = t.certificate;

    t.coreability = [];
    let coreability = resume.coreability;
    if(coreability) {
      for(let m=0; m<coreability.length; m++) {
        let my_ability = coreability[m];
        t.coreability.push({
          'idx':my_ability.idx,
          'sort_no':my_ability.sort_no,
          'ability_title':my_ability.ability_title, // 핵심역량 제목
          'ability_contents':my_ability.ability_contents, // 핵심역량 내용
        });
      }
    }
    t.coreability_list = t.coreability;

    t.education = [];
    let education = resume.education;
    if(education) {
      for(let l=0; l<education.length; l++) {
        let my_edu = education[l];
        t.education.push({
          'idx':my_edu.idx,
          'sort_no':my_edu.sort_no,
          'school_location':my_edu.school_location, // 1:국내, 2:해외
          'school_type':my_edu.school_type, // 학교구분(1:고등학교, 2:대학(2,3년), 3:대학교(4년), 4:대학원석사, 5:대학원박사)
          'school_name':my_edu.school_name, // 학교이름
          'major':my_edu.major, //학과(전공)
          'date_admission':my_edu.date_admission, //입학날짜
          'date_graduation':my_edu.date_graduation, // 졸업날짜
          'status':my_edu.status, //상태(1:졸업, 2:입학, 3:재학중, 4;휴학중, 5:자퇴, 6:재적)
          'grade':my_edu.grade, // 학점(4.14)
          'grade_total':my_edu.grade_total, // 총점(4.5, 4.3, 4.0, 100)
        });
      }
    }
    t.education_list = t.education;

    t.language = [];
    let language = resume.language;
    if(language) {
      for(let i=0; i<language.length; i++) {
        let my_lang = language[i];
        t.language.push({
          'idx':my_lang.idx,
          'sort_no':my_lang.sort_no,
          'code':my_lang.code,
          'language':my_lang.language,
          'language_name':my_lang.language_name,
          'level':my_lang.level,
        });
      }
    }
    t.language_list = t.language;
    
    t.portfolio = [];
    let portfolio = resume.portfolio;
    if(portfolio) {
      for(let k=0; k<portfolio.length; k++) {
        let my_pf = portfolio[k];
        t.portfolio.push({
          'idx':my_pf.idx,
          'sort_no':my_pf.sort_no,
          'portfolio_url':my_pf.portfolio_url,
          'portfolio_link':my_pf.portfolio_link,
          'portfolio_title':my_pf.portfolio_title,
          'portfolio_contents':my_pf.portfolio_contents,
        });
      }
    }
    t.portfolio_list = t.portfolio;
    
    t.profile = [];
    let profile = resume.profile;
    if(profile) {
      for(let h=0; h<profile.length; h++) {
        let my_profile = profile[h];
        t.profile.push({
          'idx':my_profile.idx,
          'sort_no':my_profile.sort_no,
          'profile_title':my_profile.profile_title,
          'profile_contents':my_profile.profile_contents,
        });
      }
    }
    t.profile_list = t.profile;
    
    t.wish_company = [];
    let wish_company = resume.wish_company;
    if(wish_company) {
      for(let h=0; h<wish_company.length; h++) {
        let my_wishcompany = wish_company[h];
        t.wish_company.push({
          'idx':my_wishcompany.idx,
          'sort_no':my_wishcompany.sort_no,
          'resume_idx':my_wishcompany.resume_idx,
          'company_idx':my_wishcompany.company_idx,
          'company_name':my_wishcompany.company_name,
        });
      }
    }
    t.wish_company_list = t.wish_company;


    t.wish_headhunter = [];
    let wish_headhunter = resume.wish_headhunter;
    if(wish_headhunter) {
      for(let h=0; h<wish_headhunter.length; h++) {
        let my_wishheadhunter = wish_headhunter[h];
        t.wish_headhunter.push({
          'idx':my_wishheadhunter.idx,
          'sort_no':my_wishheadhunter.sort_no,
          'resume_idx':my_wishheadhunter.resume_idx,
          'headhunter_idx':my_wishheadhunter.headhunter_idx,
          'headhunter_name':my_wishheadhunter.headhunter_name,
        });
      }
    }
    t.wish_headhunter_list = t.wish_headhunter;

    // t.suggest = [];
    // let suggest = resume.suggest;
    // if(suggest) {
    //   for(let h=0; h<suggest.length; h++) {
    //     let my_suggest = suggest[h];
    //     t.suggest.push({
    //       'company_idx':my_suggest.company_idx,
    //       'suggest_type':my_suggest.suggest_type,
    //       'suggest_title':my_suggest.suggest_title,
    //       'suggest_message':my_suggest.suggest_message,
    //       'date_suggest':my_suggest.date_suggest,
    //       'date_view':my_suggest.date_view,
    //       'date_agree':my_suggest.date_agree,
    //       'date_refuse':my_suggest.date_refuse,
    //       'status':my_suggest.status,
    //     });
    //   }
    // }
    // t.suggest_list = t.suggest;
    
    r.push(t);  
  }
  return r;
}

// Resume List
export const convertCalldibsData = (resume_data) => {

  if(!resume_data) { return []; }
  let r = [];
  for (let i = 0; i < resume_data.length; i++) {
    let t = {},
    resume = resume_data[i];
 
    t.resume_idx = resume.target_idx; //이력서 아이디

    t.status = resume.status; // 이력서 상태 0:비공개(비활성), 1:공개
    t.resume_status = resume.resume_status; //이력서 상태 0:구직의사 없음.(비활성), 1: 즉시입사가능, 2: 이직희망고려중, 3. 제안검토가능
    
    if(t.status === '1') {
      if (t.resume_status === '0') {
        t.resume_status_str = '구직의사 없음';
      } else if (t.resume_status === '1') {
        t.resume_status_str = '즉시입사가능';
      } else if (t.resume_status === '2') {
        t.resume_status_str = '이직고려중';
      } else if (t.resume_status === '3') {
        t.resume_status_str = '제안검토가능';
      }
    } else {
      t.resume_status_str = '구직의사 없음';
    }

    t.userno = resume.userno; //회원번호
    t.title = resume.title; //이력서 제목
    t.photo_url = resume.photo_url; //사진 url
    t.user_name = resume.user_name; //이름

    t.name = resume.name; //이름

    t.user_first_name = resume.name.substring(0,1) + 'OO'; // 성
    // t.user_first_name = resume.name;
    t.user_birth = resume.user_birth; //생년월일
    t.user_birth_year = moment(t.user_birth).format("YYYY");
    t.user_gender = resume.user_gender; //성별
    
    if (t.user_gender === 'M') {
      t.user_gender_str = '남';
    } else if (t.user_gender === 'F') {
      t.user_gender_str = '여';
    }

    t.user_email = resume.user_email; //이메일
    t.user_phone = resume.user_phone; //전화번호
    t.user_mobile = resume.user_mobile; //휴대전화
    t.user_address = resume.user_address; //주소

    t.user_address_array = t.user_address.split(' ');
    t.user_address_s = t.user_address_array[0]+" "+t.user_address_array[1];

    t.user_last_school = resume.user_last_school; //최종학교
    t.user_final_education = resume.user_final_education; // 최종학력

    switch (t.user_final_education) {
      case '1':
      t.final_education_str = '고등학교졸업';
      break;
      case '2':
      t.final_education_str = '대햑교(2,3)졸업';
      break; 
      case '3':
      t.final_education_str = '대학교(4)졸업';
      break; 
      case '4':
      t.final_education_str = '석사학위';
      break; 
      case '5':
      t.final_education_str = '박사학위';
      break; 
      default:
      t.final_education_str = '기타';
    }

    t.user_total_exp = resume.user_total_exp; // 총경력
    switch (t.user_total_exp) {
      case '1':
      t.user_total_exp_str = '신입';
      break;
      case '2':
        t.user_total_exp_str = '1년 ~ 3년 이하';
      break; 
      case '3':
        t.user_total_exp_str = '3년 ~ 6년 이하';
      break; 
      case '4':
        t.user_total_exp_str = '6년 ~ 10년 이하';
      break; 
      case '5':
        t.user_total_exp_str = '10년 ~ 15년 이하';
      break; 
      case '6':
        t.user_total_exp_str = '15년 ~ 20년 이하';
      break; 
      case '7':
        t.user_total_exp_str = '20년 ~ 25년 이하';
      break; 
      case '8':
        t.user_total_exp_str = '25년 ~ 30년 이하';
      break; 
      case '9':
        t.user_total_exp_str = '30년 이상';
      break; 
      default:
        t.user_total_exp_str = '신입';
    }

    t.user_job_roll = resume.user_job_roll; // 직무t.user_job_roll = myinfo.user_job_roll;
    switch (t.user_job_roll) {
      case '1':
      t.user_job_roll_str = '생산 (Production)';
      break;
      case '2':
        t.user_job_roll_str = '재무 (Financial)';
      break; 
      case '3':
        t.user_job_roll_str = '총무 (Administrator)';
      break; 
      case '4':
        t.user_job_roll_str = '품질관리 (Quality Control)';
      break; 
      case '5':
        t.user_job_roll_str = '사무 (Clerical work)';
      break; 
      case '6':
        t.user_job_roll_str = '정보보호 (Information Security)';
      break; 
      case '7':
        t.user_job_roll_str = 'IT·인터넷 (Information Technology)';
      break; 
      case '8':
        t.user_job_roll_str = '홍보 (Public Relation)';
      break; 
      case '9':
        t.user_job_roll_str = '마케팅 (Marketing)';
      break; 
      case '10':
        t.user_job_roll_str = '법무 (Judicial affairs)';
      break; 
      case '11':
        t.user_job_roll_str = '생산관리 (Production management)';
      break; 
      case '12':
        t.user_job_roll_str = '고객서비스 (Customer service)';
      break; 
      case '13':
        t.user_job_roll_str = '보안 (Security)';
      break; 
      case '14':
        t.user_job_roll_str = '비서 (Secretaray)';
      break; 
      case '15':
        t.user_job_roll_str = '영업 (Sales)';
      break; 
      case '16':
        t.user_job_roll_str = '노무 (Labor)';
      break; 
      case '17':
        t.user_job_roll_str = '시설관리 (Facility management)';
      break; 
      case '18':
        t.user_job_roll_str = '공정설계 (Process engineering)';
      break; 
      case '19':
        t.user_job_roll_str = '연구개발 (Research and Development)';
      break; 
      case '20':
        t.user_job_roll_str = '리스크 (Risk)';
      break; 
      case '21':
        t.user_job_roll_str = '구매 (Sourcing)';
      break; 
      case '22':
        t.user_job_roll_str = '인사 (Human resources)';
      break; 
      case '23':
        t.user_job_roll_str = '전략기획 (Strategy)';
      break; 
      case '24':
        t.user_job_roll_str = '환경안전 (Environmental Safety)';
      break; 
      case '25':
        t.user_job_roll_str = '물류 (Logistics)';
      break; 
      case '26':
        t.user_job_roll_str = '기타';
      break; 
      default:
        t.user_job_roll_str = '신기타입';
    }

    t.wish_employment_type = resume.wish_employment_type; // 고용형태. 콤마로 구분 1:정규직, 2:계약직, 3.병역특례, 4:프리랜서, 5:헤드헌팅, 6.파견대행, 7:인턴직

    switch (t.wish_employment_type) {
      case '1':
      t.wish_employment_str = '정규직';
      break;
      case '2':
      t.wish_employment_str = '계약직';
      break; 
      case '3':
      t.wish_employment_str = '병역특례';
      break; 
      case '4':
      t.wish_employment_str = '프리랜서';
      break; 
      case '5':
      t.wish_employment_str = '헤드헌팅';
      break; 
      case '6':
      t.wish_employment_str = '파견대행';
      break; 
      case '7':
      t.wish_employment_str = '인턴직';
      break; 
      default:
      t.wish_employment_str = '정규직';
    }

    t.now_pay_amount = resume.now_pay_amount; //현재연봉
    t.merit_pay_amount = resume.merit_pay_amount; //성과급
    t.wish_pay_amount = resume.wish_pay_amount; //희망연봉
    t.wish_company_location = resume.wish_company_location; //희망근무지. 콤마로 구분
    t.wish_job = resume.wish_job; //희망직무. 콤마로 구분
    t.website = resume.website; //홈페이지
    t.cnt_view = resume.cnt_view; //cnt_view
    t.rating = resume.rating; // rating
    t.max_rating = resume.max_rating; // max rating
    t.percentage = (resume.rating / resume.max_rating) * 100; //%
    
    t.star = Math.round((t.rating*5)/t.max_rating);
    
    t.regdate = resume.regdate; //등록일
    t.moddate = resume.moddate; //수정일
    t.match_date = resume.match_date; //마지막 매칭 날짜
    t.company_name = resume.company_name; //company_name
    t.department_name = resume.department_name; //department_name
    t.job_title = resume.job_title; //job_title

    t.show_type = resume.show_type; // show_type
    t.show_status = resume.show_status; // show_status - 풀이력서

    t.company_idx_ = resume.company_idx; // company_idx
    t.suggest_type = resume.suggest_type; // suggest_type
    t.status = resume.status; // status


    t.notification = resume.notification; // notification
    t.subscribe = resume.subscribe; // subscribe
    t.like = resume.like; // like

    t.tot_exp_month = resume.tot_exp_month; // 실제 이력서상 경력
    t.real_exp_year = parseInt(t.tot_exp_month / 12);
    t.real_exp_month =  t.tot_exp_month % 12;
    t.real_exp_tot = (t.real_exp_year>0?t.real_exp_year+'년':'...')+(t.real_exp_month>0?' '+t.real_exp_month+'개월':'');



    t.career = [];
    let career = resume.career;
    if(career) {
        for (let g = 0; g < career.length; g++) {
            let my_career = career[g];
          t.career.push({
            'idx':my_career.idx,
            'resume_idx':my_career.resume_idx, 
            'status':my_career.status, 
            'company_name':my_career.company_name, 
            // 'company_name_logo':my_career.company_name.substring(0,2),
            'department_name':my_career.department_name, 
            'date_join':my_career.date_join, 
            'date_retire':my_career.date_retire, 
            'job_title':my_career.job_title, 
            'pay_amount':my_career.pay_amount, 
            'job_in_charge':my_career.job_in_charge, 
          });
        }
    }
    t.career_list = t.career;

    t.certificate = [];
    let certificate = resume.certificate;
    if(certificate) {
      for(let j=0; j<certificate.length; j++) {
        let my_cert = certificate[j];
        t.certificate.push({
          'idx':my_cert.idx,
          'sort_no':my_cert.sort_no,
          'certificate_name':my_cert.certificate_name, // 자격증명
          'certificate_issuer':my_cert.certificate_issuer, // 벌행처
          'date_receive':my_cert.date_receive, // 최득일
        });
      }
    }
    t.certificate_list = t.certificate;

    t.coreability = [];
    let coreability = resume.coreability;
    if(coreability) {
      for(let m=0; m<coreability.length; m++) {
        let my_ability = coreability[m];
        t.coreability.push({
          'idx':my_ability.idx,
          'sort_no':my_ability.sort_no,
          'ability_title':my_ability.ability_title, // 핵심역량 제목
          'ability_contents':my_ability.ability_contents, // 핵심역량 내용
        });
      }
    }
    t.coreability_list = t.coreability;

    t.education = [];
    let education = resume.education;
    if(education) {
      for(let l=0; l<education.length; l++) {
        let my_edu = education[l];
        t.education.push({
          'idx':my_edu.idx,
          'sort_no':my_edu.sort_no,
          'school_location':my_edu.school_location, // 1:국내, 2:해외
          'school_type':my_edu.school_type, // 학교구분(1:고등학교, 2:대학(2,3년), 3:대학교(4년), 4:대학원석사, 5:대학원박사)
          'school_name':my_edu.school_name, // 학교이름
          'major':my_edu.major, //학과(전공)
          'date_admission':my_edu.date_admission, //입학날짜
          'date_graduation':my_edu.date_graduation, // 졸업날짜
          'status':my_edu.status, //상태(1:졸업, 2:입학, 3:재학중, 4;휴학중, 5:자퇴, 6:재적)
          'grade':my_edu.grade, // 학점(4.14)
          'grade_total':my_edu.grade_total, // 총점(4.5, 4.3, 4.0, 100)
        });
      }
    }
    t.education_list = t.education;

    t.language = [];
    let language = resume.language;
    if(language) {
      for(let i=0; i<language.length; i++) {
        let my_lang = language[i];
        t.language.push({
          'idx':my_lang.idx,
          'sort_no':my_lang.sort_no,
          'code':my_lang.code,
          'language':my_lang.language,
          'language_name':my_lang.language_name,
          'level':my_lang.level,
        });
      }
    }
    t.language_list = t.language;
    
    t.portfolio = [];
    let portfolio = resume.portfolio;
    if(portfolio) {
      for(let k=0; k<portfolio.length; k++) {
        let my_pf = portfolio[k];
        t.portfolio.push({
          'idx':my_pf.idx,
          'sort_no':my_pf.sort_no,
          'portfolio_url':my_pf.portfolio_url,
          'portfolio_link':my_pf.portfolio_link,
          'portfolio_title':my_pf.portfolio_title,
          'portfolio_contents':my_pf.portfolio_contents,
        });
      }
    }
    t.portfolio_list = t.portfolio;
    
    t.profile = [];
    let profile = resume.profile;
    if(profile) {
      for(let h=0; h<profile.length; h++) {
        let my_profile = profile[h];
        t.profile.push({
          'idx':my_profile.idx,
          'sort_no':my_profile.sort_no,
          'profile_title':my_profile.profile_title,
          'profile_contents':my_profile.profile_contents,
        });
      }
    }
    t.profile_list = t.profile;
    
    t.wish_company = [];
    let wish_company = resume.wish_company;
    if(wish_company) {
      for(let h=0; h<wish_company.length; h++) {
        let my_wishcompany = wish_company[h];
        t.wish_company.push({
          'idx':my_wishcompany.idx,
          'sort_no':my_wishcompany.sort_no,
          'resume_idx':my_wishcompany.resume_idx,
          'company_idx':my_wishcompany.company_idx,
          'company_name':my_wishcompany.company_name,
        });
      }
    }
    t.wish_company_list = t.wish_company;


    t.wish_headhunter = [];
    let wish_headhunter = resume.wish_headhunter;
    if(wish_headhunter) {
      for(let h=0; h<wish_headhunter.length; h++) {
        let my_wishheadhunter = wish_headhunter[h];
        t.wish_headhunter.push({
          'idx':my_wishheadhunter.idx,
          'sort_no':my_wishheadhunter.sort_no,
          'resume_idx':my_wishheadhunter.resume_idx,
          'headhunter_idx':my_wishheadhunter.headhunter_idx,
          'headhunter_name':my_wishheadhunter.headhunter_name,
        });
      }
    }
    t.wish_headhunter_list = t.wish_headhunter;

    t.suggest = [];
    let suggest = resume.suggest;
    if(suggest) {
      for(let h=0; h<suggest.length; h++) {
        let my_suggest = suggest[h];
        t.suggest.push({
          'company_idx':my_suggest.company_idx,
          'suggest_type':my_suggest.suggest_type,
          'status':my_suggest.status,
        });
      }
    }
    t.suggest_list = t.suggest;
    
    r.push(t);  
  }
  return r;
}

// Setting List
export const convertSettingData = (setting_data) => {
  if(!setting_data) { return []; }
  let r = [];
  // console.log('setting_data.length', setting_data.length);
  for (let i = 0; i < setting_data.length; i++) {
    let t = {},
    setting = setting_data[i];

    t.no = setting_data.length - i; // 매일초기화할수있음.
    t.settingcode = setting.settingcode;
    t.company_idx = setting.company_idx;
    t.active = setting.active;
    t.title = setting.title;
    t.cnt_setting = setting.cnt_setting;
    t.regdate = setting.regdate;

    r.push(t);
  }
  return r;
}

export const convertSettingInfo = (setting_info) => {
    if(!setting_info) { return []; }
    let r = [];
    // console.log('setting_info.length', setting_info.length);
    for (let i = 0; i < setting_info.length; i++) {
      let t = {},
      setting = setting_info[i];
  
      t.no = setting.length - i; // 매일초기화할수있음.
      t.settingcode = setting.settingcode;
      t.active = setting.active;
      t.title = setting.title;
      t.cnt_setting = setting.cnt_setting;
      t.regdate = setting.regdate;
        
      t.set = [];
      let set = setting.setting;
      if(set) {
        for(let k=0; k<set.length; k++) {
          let my_st = set[k];

          switch (my_st.search_key) {
            case 'age_range': // 
            my_st.range_array = my_st.search_value.split(',');
            my_st.age_rangemin = my_st.range_array[0];
            my_st.age_rangemax = my_st.range_array[1];
            my_st.title1 = "연령";
            break; 
            case 'exp_range': // 
            my_st.range_array = my_st.search_value.split(',');
            my_st.exp_rangemin = my_st.range_array[0];
            my_st.exp_rangemax = my_st.range_array[1];
            my_st.title2 = "총경력수";
            break; 
            case 'school': // 
            my_st.title3 = "학교";
            break; 
            case 'education': // 
            my_st.range_array = my_st.search_value.split(',');
            my_st.grade1 = my_st.range_array[0];
            my_st.grade2 = my_st.range_array[1];
            my_st.grade3 = my_st.range_array[2];
            my_st.grade4 = my_st.range_array[3];
            my_st.title4 = "학력";
            break; 
            default:
            my_st.bool_setting = '0';
          }
            
          t.set.push({
            'no': k + 1,
            'idx':my_st.idx,
            'active':my_st.active,
            'title':my_st.title,
            'bool_condition':my_st.bool_condition,
            'search_key':my_st.search_key,
            'search_value':my_st.search_value,
            'age_rangemin':my_st.age_rangemin,
            'age_rangemax':my_st.age_rangemax,
            'exp_rangemin':my_st.exp_rangemin,
            'exp_rangemax':my_st.exp_rangemax,
            'grade1':my_st.grade1,
            'grade2':my_st.grade2,
            'grade3':my_st.grade3,
            'grade4':my_st.grade4,
          });
        }
      }
      t.setting_list = t.set;
      
      r.push(t);
    }
    return r;
}
  
// Apply Data
export const convertJobApplyData = (apply_data) => {
  if(!apply_data) { return []; }
  let r = [];
  for (let i = 0; i < apply_data.length; i++) {
    let t = {},
    apply = apply_data[i];

    t.recruit_idx = apply.recruit_idx; // 공고 번호
    t.resume_idx = apply.resume_idx; // 이력서 번호
    t.recruit_userno = apply.recruit_userno; // 공고 회원번호
    t.resume_userno = apply.resume_userno; // 이력서 회원번호
    t.ja_status = apply.ja_status; // 0:미확인, 1:접수확인, 2:예비확정, 3:확정, 4:최종확정'

    switch (t.ja_status) {
      case '1':
      t.status_str = '접수확인';
      break;
      case '2':
      t.status_str = '예비확정';
      break; 
      case '3':
      t.status_str = '확정';
      break; 
      case '4':
      t.status_str = '최종확정';
      break; 
      default:
      t.status_str = '미확인';
    }

    t.date_apply = apply.date_apply;
    t.date_apply = t.date_apply?moment(t.date_apply).format("YYYY-MM-DD"):'-'; // "13:16(19-11-07)";; // 지원날짜
    t.date_view = apply.date_view; // 열람날짜
    t.date_view = t.date_view?moment(apply.date_view).format("YYYY-MM-DD"):'-'; // 열람날짜
    t.date_spare_pass = apply.date_spare_pass; // 예비합격날짜
    t.date_spare_pass = t.date_spare_pass?moment(apply.date_spare_pass).format("YYYY-MM-DD"):'-'; // 예비합격날짜
    t.date_document_pass = apply.date_document_pass; // 서류합격날짜
    t.date_document_pass = t.date_document_pass?moment(apply.date_document_pass).format("YYYY-MM-DD"):'-'; // 서류합격날짜
    t.date_final_pass = apply.date_final_pass; // 최종합격날짜
    t.date_final_pass = t.date_final_pass?moment(apply.date_final_pass).format("YYYY-MM-DD"):'-'; // 최종합격날짜

    // 채용공고 리스트

    t.recruit_idx = apply.recruit_idx;
    t.recruit_active = apply.recruit_active;
    t.userno = apply.userno;
    t.usertype = apply.usertype;
    if(apply.company_logo_url) {
      t.company_logo_url = apply.company_logo_url; // logo
    } else {
      let s = i%5 + 1;
      t.company_logo_url = "/assets/images/companies/logo-"+s+".webp"; // logo
    }
    if(apply.logo_url) {
      t.logo_url = apply.logo_url; // logo
    } else {
      let s = i%5 + 1;
      t.logo_url = "/assets/images/companies/logo-"+s+".webp"; // logo
    }
    t.recruit_title = apply.recruit_title; // 공고 제목
    if(apply.condition_pay_amount==="0") {
      t.condition_pay_amount = "연봉업계최고";
    } else {
      t.condition_pay_amount = apply.condition_pay_amount+"만원"; // 근무조건 급여 금액
    }
    t.condition_work_area = apply.condition_work_area; //근무조건 근무지역
    t.reception_period = apply.reception_period; // 접수기간
    t.recruit_area = apply.recruit_area; //모집분야
    t.recruit_cnt = apply.recruit_cnt; //모집인원
    t.recruit_price = apply.recruit_price; //금액
    t.job_title = apply.job_title; // 직급/직책

    t.career_type_new = apply.career_type_new; // 경력종류 신입 Y/N
    t.career_type_exp = apply.career_type_exp; // 경력종류 경력 Y/N
    t.career_type_all = apply.career_type_all; // 경력종류 경력무관 Y/N
    t.career_type_exp_year = apply.career_type_exp_year; // 경력종류 경력 년수

    switch (t.career_type_exp_year) {
      case '1':
      t.career_type_exp_year_str = '신입';
      break;
      case '2':
      t.career_type_exp_year_str = '1년 ~ 3년 이하';
      break; 
      case '3':
        t.career_type_exp_year_str = '3년 ~ 6년 이하';
      break; 
      case '4':
        t.career_type_exp_year_str = '6년 ~ 10년 이하';
      break; 
      case '5':
        t.career_type_exp_year_str = '10년 ~ 15년 이하';
      break; 
      case '6':
        t.career_type_exp_year_str = '15년 ~ 20년 이하';
      break; 
      case '7':
        t.career_type_exp_year_str = '20년 ~ 25년 이하';
      break; 
      case '8':
        t.career_type_exp_year_str = '25년 ~ 30년 이하';
      break; 
      case '9':
        t.career_type_exp_year_str = '30년 이상';
      break; 
      default:
        t.career_type_exp_year_str = '1년 ~ 3년 이하';
    }
    
    if(apply.career_type_new === 'Y') {
      t.career_type = "신입";
    } else if(apply.career_type_exp === 'Y') {
      t.career_type = t.career_type_exp_year_str+ " 경력";
    } else if(apply.career_type_all === 'Y') {
      t.career_type = "경력무관";
    }
    t.recruit_stop_date = apply.recruit_stop_date; // 마감일
    t.recruit_stop = moment(t.recruit_stop_date).format("YYYY-MM-DD"); // "13:16(19-11-07)";

    t.employ_type_regular = apply.employ_type_regular;
    t.employ_type_contract = apply.employ_type_contract;
    t.employ_type_intern = apply.employ_type_intern;
    t.employ_type_dispatched = apply.employ_type_dispatched;
    t.employ_type_freelancer = apply.employ_type_freelancer;
    t.employ_type_parttime = apply.employ_type_parttime;
    t.employ_type_trainee = apply.employ_type_trainee;
    t.employ_type_military = apply.employ_type_military;
    t.employ_type_commissioned = apply.employ_type_commissioned;

    if (t.employ_type_regular === 'Y') {
      t.employ_type = '정규직';
    } else if (t.employ_type_contract === 'Y') {
      t.employ_type = '계약직';
    } else {
      t.employ_type = '정규직';
    }

    t.limits_education = apply.limits_education; // 학력

    if (t.limits_education === '0') {
      t.education_str = '학력무관';
    } else if (t.limits_education === '1') {
      t.education_str = '고등학교졸';
    } else if (t.limits_education === '2') {
      t.education_str = '대학졸업(2,3년)';
    } else if (t.limits_education === '3') {
      t.education_str = '대학졸업(4년)';
    } else if (t.limits_education === '4') {
      t.education_str = '대학원 석사';
    } else if (t.limits_education === '5') {
      t.education_str = '대학원 박사';
    } else {
      t.education_str = '학력무관';
    }

    t.limits_preference = apply.limits_preference; //우대
    t.job_tags = apply.job_tags; // job_tags
    t.job_group = apply.job_group; // job_group
    switch (t.job_group) {
      case '1':
      t.job_group_str = '경영·사무·관리';
      break;
      case '2':
      t.job_group_str = '마케팅·홍보·PR·광고';
      break; 
      case '3':
        t.job_group_str = 'IT·인터넷';
      break; 
      case '4':
        t.job_group_str = '디자인';
      break; 
      case '5':
        t.job_group_str = '영업·고객상담';
      break; 
      case '6':
        t.job_group_str = '유통·무역';
      break; 
      case '7':
        t.job_group_str = '연구·개발·설계';
      break; 
      case '8':
        t.job_group_str = '생산·제조';
      break; 
      case '9':
        t.job_group_str = '교육';
      break;
      case '10':
        t.job_group_str = '건설';
      break; 
      case '11':
        t.job_group_str = '금융';
      break; 
      case '12':
        t.job_group_str = '의료';
      break; 
      case '13':
        t.job_group_str = '조사·분석';
      break; 
      case '14':
        t.job_group_str = '서비스';
      break; 
      case '15':
        t.job_group_str = '문화·예술';
      break; 
      case '16':
        t.job_group_str = '전문·특수직';
      break;
      case '17':
        t.job_group_str = '기타';
      break;  
      default:
        t.job_group_str = '기타';
    }

    t.job_roll = apply.job_roll; // job_roll
    switch (t.job_roll) {
      case '1':
      t.job_roll_str = '생산 (Production)';
      break;
      case '2':
        t.job_roll_str = '재무 (Financial)';
      break; 
      case '3':
        t.job_roll_str = '총무 (Administrator)';
      break; 
      case '4':
        t.job_roll_str = '품질관리 (Quality Control)';
      break; 
      case '5':
        t.job_roll_str = '사무 (Clerical work)';
      break; 
      case '6':
        t.job_roll_str = '정보보호 (Information Security)';
      break; 
      case '7':
        t.job_roll_str = 'IT·인터넷 (Information Technology)';
      break; 
      case '8':
        t.job_roll_str = '홍보 (Public Relation)';
      break; 
      case '9':
        t.job_roll_str = '마케팅 (Marketing)';
      break; 
      case '10':
        t.job_roll_str = '법무 (Judicial affairs)';
      break; 
      case '11':
        t.job_roll_str = '생산관리 (Production management)';
      break; 
      case '12':
        t.job_roll_str = '고객서비스 (Customer service)';
      break; 
      case '13':
        t.job_roll_str = '보안 (Security)';
      break; 
      case '14':
        t.job_roll_str = '비서 (Secretaray)';
      break; 
      case '15':
        t.job_roll_str = '영업 (Sales)';
      break; 
      case '16':
        t.job_roll_str = '노무 (Labor)';
      break; 
      case '17':
        t.job_roll_str = '시설관리 (Facility management)';
      break; 
      case '18':
        t.job_roll_str = '공정설계 (Process engineering)';
      break; 
      case '19':
        t.job_roll_str = '연구개발 (Research and Development)';
      break; 
      case '20':
        t.job_roll_str = '리스크 (Risk)';
      break; 
      case '21':
        t.job_roll_str = '구매 (Sourcing)';
      break; 
      case '22':
        t.job_roll_str = '인사 (Human resources)';
      break; 
      case '23':
        t.job_roll_str = '전략기획 (Strategy)';
      break; 
      case '24':
        t.job_roll_str = '환경안전 (Environmental Safety)';
      break; 
      case '25':
        t.job_roll_str = '물류 (Logistics)';
      break; 
      case '26':
        t.job_roll_str = '기타';
      break; 
      default:
        t.job_roll_str = '신기타입';
    }

    t.jobdescription = apply.jobdescription; // 모집요강
    t.jobmainroll = apply.jobmainroll; // 주요업무
    t.qualifications = apply.qualifications; // 자격요건
    t.preferential_treatment = apply.preferential_treatment; // 우대사항
    t.recruit_etc = apply.recruit_etc; // 기타

    t.hrmanager_phone = apply.hrmanager_phone;
    t.hrmanager_email = apply.hrmanager_email;

    t.company_name = apply.company_name; // 회사명
    t.company_idx = apply.company_idx; // 회사아이디
    t.company_website = apply.company_website; // 회사홈페이지

    t.recruit_status_code = apply.recruit_status_code; // 
    t.recruit_status = apply.recruit_status; // 

    t.resume_userno = apply.resume_userno;

    // 이력서리스트
    t.show_status = apply.show_status; // 이력서 상태 0:비공개(비활성), 1:공개
    t.resume_status = apply.resume_status; //이력서 상태 0:구직의사 없음.(비활성), 1: 즉시입사가능, 2: 이직희망고려중, 3. 제안검토가능
    
    if(t.show_status === '1') {
      if (t.resume_status === '0') {
        t.resume_status_str = '구직의사 없음';
      } else if (t.resume_status === '1') {
        t.resume_status_str = '즉시입사가능';
      } else if (t.resume_status === '2') {
        t.resume_status_str = '이직고려중';
      } else if (t.resume_status === '3') {
        t.resume_status_str = '제안검토가능';
      }
    } else {
      t.resume_status_str = '구직의사 없음';
    }

    t.userno = apply.userno; //회원번호
    t.title = apply.title; //이력서 제목
    t.photo_url = apply.photo_url; //사진 url
    t.name = apply.name; //이름
    t.user_name = apply.user_name; //이름

    t.user_first_name = apply.name.substring(0,1) + 'OO'; // 성
    // t.user_first_name = resume.name;
    t.user_birth = apply.user_birth; //생년월일
    t.user_birth_year = moment(t.user_birth).format("YYYY");
    t.user_gender = apply.user_gender; //성별
    
    if (t.user_gender === 'M') {
      t.user_gender_str = '남';
    } else if (t.user_gender === 'F') {
      t.user_gender_str = '여';
    }

    t.user_email = apply.user_email; //이메일
    t.user_phone = apply.user_phone; //전화번호
    t.user_mobile = apply.user_mobile; //휴대전화
    t.user_address = apply.user_address; //주소

    t.user_address_array = t.user_address.split(' ');
    t.user_address_s = t.user_address_array[0]+" "+t.user_address_array[1];

    t.user_last_school = apply.user_last_school; //최종학교
    t.user_final_education = apply.user_final_education; // 최종학력

    switch (t.user_final_education) {
      case '1':
      t.final_education_str = '고등학교졸업';
      break;
      case '2':
      t.final_education_str = '대햑교(2,3)졸업';
      break; 
      case '3':
      t.final_education_str = '대학교(4)졸업';
      break; 
      case '4':
      t.final_education_str = '석사학위';
      break; 
      case '5':
      t.final_education_str = '박사학위';
      break; 
      default:
      t.final_education_str = '기타';
    }

    t.user_total_exp = apply.user_total_exp; // 총경력
    switch (t.user_total_exp) {
      case '1':
      t.user_total_exp_str = '신입';
      break;
      case '2':
        t.user_total_exp_str = '1년 ~ 3년 이하';
      break; 
      case '3':
        t.user_total_exp_str = '3년 ~ 6년 이하';
      break; 
      case '4':
        t.user_total_exp_str = '6년 ~ 10년 이하';
      break; 
      case '5':
        t.user_total_exp_str = '10년 ~ 15년 이하';
      break; 
      case '6':
        t.user_total_exp_str = '15년 ~ 20년 이하';
      break; 
      case '7':
        t.user_total_exp_str = '20년 ~ 25년 이하';
      break; 
      case '8':
        t.user_total_exp_str = '25년 ~ 30년 이하';
      break; 
      case '9':
        t.user_total_exp_str = '30년 이상';
      break; 
      default:
        t.user_total_exp_str = '신입';
    }

    t.user_job_roll = apply.user_job_roll; // 직무t.user_job_roll = myinfo.user_job_roll;
    switch (t.user_job_roll) {
      case '1':
      t.user_job_roll_str = '생산 (Production)';
      break;
      case '2':
        t.user_job_roll_str = '재무 (Financial)';
      break; 
      case '3':
        t.user_job_roll_str = '총무 (Administrator)';
      break; 
      case '4':
        t.user_job_roll_str = '품질관리 (Quality Control)';
      break; 
      case '5':
        t.user_job_roll_str = '사무 (Clerical work)';
      break; 
      case '6':
        t.user_job_roll_str = '정보보호 (Information Security)';
      break; 
      case '7':
        t.user_job_roll_str = 'IT·인터넷 (Information Technology)';
      break; 
      case '8':
        t.user_job_roll_str = '홍보 (Public Relation)';
      break; 
      case '9':
        t.user_job_roll_str = '마케팅 (Marketing)';
      break; 
      case '10':
        t.user_job_roll_str = '법무 (Judicial affairs)';
      break; 
      case '11':
        t.user_job_roll_str = '생산관리 (Production management)';
      break; 
      case '12':
        t.user_job_roll_str = '고객서비스 (Customer service)';
      break; 
      case '13':
        t.user_job_roll_str = '보안 (Security)';
      break; 
      case '14':
        t.user_job_roll_str = '비서 (Secretaray)';
      break; 
      case '15':
        t.user_job_roll_str = '영업 (Sales)';
      break; 
      case '16':
        t.user_job_roll_str = '노무 (Labor)';
      break; 
      case '17':
        t.user_job_roll_str = '시설관리 (Facility management)';
      break; 
      case '18':
        t.user_job_roll_str = '공정설계 (Process engineering)';
      break; 
      case '19':
        t.user_job_roll_str = '연구개발 (Research and Development)';
      break; 
      case '20':
        t.user_job_roll_str = '리스크 (Risk)';
      break; 
      case '21':
        t.user_job_roll_str = '구매 (Sourcing)';
      break; 
      case '22':
        t.user_job_roll_str = '인사 (Human resources)';
      break; 
      case '23':
        t.user_job_roll_str = '전략기획 (Strategy)';
      break; 
      case '24':
        t.user_job_roll_str = '환경안전 (Environmental Safety)';
      break; 
      case '25':
        t.user_job_roll_str = '물류 (Logistics)';
      break; 
      case '26':
        t.user_job_roll_str = '기타';
      break; 
      default:
        t.user_job_roll_str = '신기타입';
    }

    t.wish_employment_type = apply.wish_employment_type; // 고용형태. 콤마로 구분 1:정규직, 2:계약직, 3.병역특례, 4:프리랜서, 5:헤드헌팅, 6.파견대행, 7:인턴직

    switch (t.wish_employment_type) {
      case '1':
      t.wish_employment_str = '정규직';
      break;
      case '2':
      t.wish_employment_str = '계약직';
      break; 
      case '3':
      t.wish_employment_str = '병역특례';
      break; 
      case '4':
      t.wish_employment_str = '프리랜서';
      break; 
      case '5':
      t.wish_employment_str = '헤드헌팅';
      break; 
      case '6':
      t.wish_employment_str = '파견대행';
      break; 
      case '7':
      t.wish_employment_str = '인턴직';
      break; 
      default:
      t.wish_employment_str = '정규직';
    }

    t.now_pay_amount = apply.now_pay_amount; //현재연봉
    t.merit_pay_amount = apply.merit_pay_amount; //성과급
    t.wish_pay_amount = apply.wish_pay_amount; //희망연봉
    t.wish_company_location = apply.wish_company_location; //희망근무지. 콤마로 구분
    t.wish_job = apply.wish_job; //희망직무. 콤마로 구분
    t.website = apply.website; //홈페이지
    t.cnt_view = apply.cnt_view; //cnt_view
    t.rating = apply.rating; // rating
    t.max_rating = apply.max_rating; // max rating
    t.percentage = (apply.rating / apply.max_rating) * 100; //%
    
    t.star = Math.round((t.rating*5)/t.max_rating);
    
    t.regdate = apply.regdate; //등록일
    t.moddate = apply.moddate; //수정일
    t.match_date = apply.match_date; //마지막 매칭 날짜
    t.company_name = apply.company_name; //company_name
    t.department_name = apply.department_name; //department_name
    t.job_title = apply.job_title; //job_title

    t.show_type = apply.show_type; // show_type
    t.show_status = apply.show_status; // show_status - 풀이력서

    t.company_idx_ = apply.company_idx; // company_idx
    t.suggest_type = apply.suggest_type; // suggest_type

    t.notification = apply.notification; // notification
    t.subscribe = apply.subscribe; // subscribe
    t.like = apply.like; // like

    r.push(t);  
  }
  return r;
}

export const convertSchoolData = (school_data) => {
  if(!school_data) { return []; }
  let r = [];
  console.log('school_data.length', school_data.length);
  for (let i = 0; i < school_data.length; i++) {
    let t = {},
    univ = school_data[i];

    t.idx = univ.idx;
    t.school_name = univ.school_name;
    // t.school_english_name = univ.school_english_name;
    // t.school_location = univ.school_location;
    t.school_division = univ.school_division;
    // t.school_kinds = univ.school_kinds;
    // t.school_type = univ.school_type;
    // t.school_city_code = univ.school_city_code;
    // t.city_name = univ.city_name;
    // t.address = univ.address;
    // t.postcode = univ.postcode;
    // t.website = univ.website;
    // t.phone = univ.phone;
    // t.fax = univ.fax;r
    // t.establish = univ.establish;
    // school_name	school_english_name	school_location	school_division	school_kinds	school_type	school_city_code	city_name	address	postcode	website	phone	fax	establish
    
    r.push(t);
  }
  return r;
}


// Recently List
export const convertRecentlyData = (recently_data) => {

  if(!recently_data) { return []; }
  let r = [];
  console.log('recently_data.length', recently_data.length);
  for (let i = 0; i < recently_data.length; i++) {
    let t = {},
    recently = recently_data[i];

    t.resume_idx = recently.resume_idx; //이력서 아이디
    
    t.recruit_idx = recently.recruit_idx; //매칭된 공고 idx
    t.recruit_title = recently.recruit_title; //매칭된 공고제목
    t.date_match = recently.date_match; //매칭날짜

    t.photo_url = recently.photo_url; //사진 url
    t.name = recently.name; //이름
    
    r.push(t);  
  }
  return r;
}

// Resume List
// selectcompanies - list
// selectheadhunters - list
// suggestioncompanies - list
// matchcompanies - list
// resume - list
// joblist - list
// suggestionjoblist - list
// applylist - list
// bookmarkjobs - list
// recentlyjoblist - list
// career - bbs list
// daily - bbs list
// hobby - bbs list

// myprofile - detail
// proposalcompanies - list
// proposalheadhunters - list
// wishcompany - list
// manageapply - list
// manageresume - list
// messages - list

// candidatelist - list
// suggestionlist - list
// selfsuggestion - list
// calldibslist - list
// recentlyresume - list
// joblist - list
// managejobpost - list
// jobapplicant - list
// matchresume - list
// headhunterlist - list